.addcard {
  margin-top: 1%;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px rgba(164, 164, 164, 0.5);

  text-decoration: none;
  color: white;
  margin: 4% 2%;
  background-size: 115% !important;

  transition: 1s;
 
  overflow: hidden;
  max-height: 200px ;
  border: 4px solid rgb(246, 108, 108, 0.5);
  text-decoration: none;
height:500px;
}
.headerwrapper {
  position: relative;
  padding: 1%;
  height:100%;
}
.extrawrapper {
  position: absolute;
  bottom: 0vw;
  text-shadow: 2px 2px 2px #777;
  height:100%;
}
.header {
  position:relative;
   text-decoration: none;
  color: white;
  text-align: left;
font-size:1.1em;
  padding: 1%;
  top:0%;
}
.image {
 
  height: 100%;
}

.addcontent {
  position:relative;
  top:20%;
  text-decoration: none;
  color: white;
  padding: 1% 3%;
  font-size:1em;
}
.addcard a {
  text-decoration: none;
}

.addcard:hover {
  background-position: left top !important;
  background-size: 130% !important;
  background-repeat: no-repeat !important;
}
.anzeigetext {
  position: absolute;
  z-index: 1;
  right: 10px;
bottom:0;
  color: var(--primary);
  font-weight: 600;
  text-decoration: none;
  
}
.anzeigetext a {
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {

.addcard {
    max-height: 140px !important;
}
.header {

  font-size:0.9em;
}
.addcontent {font-size:0.83em;}
}