.MyButton {
	box-shadow: 0px 0px 2px 0px #101335;
	
	background-color:#101335;
	border-radius:10px;
	border:1px solid #101335;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	
	text-decoration:none;
	width:100%;
    transition:1s;
    margin:0.2%;
    font-weight:bold;
    padding:5px;
    margin-left:0.4%;
    margin-right:0.6%;
    
}
.MyButton:hover {
	 transition:0.5s;
	background-color:#282c59;
    border:1px solid #282c59;
  box-shadow: 0px 0px 4px 0px #282c59;
}
.MyButton:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    
.MyButton {
    font-size:13px;
}
}