.aboinfo {
  margin: 10px 0px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  color:#000;
}
.aboinfodm {
  margin: 10px 0px;
  background-color: rgba(69, 65, 84, 0.4);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#fff;
}

.aboinfoheader img {
  width: 100%;
  object-fit: cover;
}
.flexbuttons{
  display: flex;
  flex-flow:row wrap;
  justify-content: space-evenly;
  width:100%;
}
.aboinfobody {
  padding: 10px 20px;
}
  
.aboinfobody h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color:var(--primary);
}
  
.aboinfobody h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.aboinfobody p {
  margin: 12px 0;
}
  .tiername{
    font-weight: 600;
  }
.aboinfobody ul {
  list-style:none;
  margin:10px 0px;
  padding:0px;
  }
  .addborder{
    border:1px solid black;
    padding:5px;
  }
.abopseudo {
  color: #11aa00;
  text-decoration:none;
  }
  .abopseudo a{
    text-decoration: none;
  }
  
.btn {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}
  
.btn:hover {
 background-color: #04003b;
 color: #eeeeee;
}
 
.abobtn {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  background-color: var(--primary);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}  
  
.abobtn:hover {
 background-color: #04003b;
 color: #eeeeee;
}  
  
.abobasic_off, .abostandard_off, .abopremium_off {
font-size:1.1rem;  
color: #999999;   
}
.abobasic_aktive, .abostandard_aktive, .abopremium_aktive {
font-size:1.1rem;  
font-weight:bold;
color: var(--primary);  
} 
.thebtns{
    margin:8px;
}