.size{
    width:100%;
    height:100%;
   
}
.textforGirl{

    min-height:100px;
    width:98%;
    padding:2%;
    margin:1% 0%;
   
    border-radius:20px;
}