.wrapper{
text-align:center;
width:80%;
margin:30px auto;
border:3px solid var(--primary);
border-radius:6px;
}
.header{
margin-top: 10px;
margin-bottom:20px;
}
.icon{
    width:25px;
    margin-left:5px;
    margin-right:5px;
}
.girltext{
    color:var(--primary);
    font-weight:bold;
}