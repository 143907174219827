.head{
    margin-top: 1em;
    text-align: center;
}
.bigbutton {
  font-size:1.8rem;
  font-weight:bold;
  color: #fff !important;
  width:100%;
  text-align:center;
  display:block;
  padding:20px;
  background:var(--primary);
  margin:10px 0;
  border-radius:50px;
  cursor: pointer;
  transition:0.25s;
  } 
 
  .bottombouncer{
    height:10vh;
   
  }
  .wrapme{
    margin:10px;
  }
  
.bigbutton:hover {
  background:#04003b;
  } 
  .centerme{
    margin:10px auto;
    width:100%;
    text-align:center;
  }
  .bottombouncer{
    height:10vh;
  }