.userinfo {
  margin: 10px;
  background-color: #fff;
  width: 95%;
  border-radius: 10px;
  border:1px solid #eeeeee;
  overflow:hidden;
  color:#000;

}
.userinfodm {
  margin: 10px;
  background-color: rgb(255,255,255,0.3);
  width: 95%;
  border-radius: 10px;
  border:1px solid #eeeeee;
  overflow:hidden;
  color:#000;
}


.userinfoheader img {
  width: 100%;
  object-fit: cover;
  object-position: center -10px;
  max-height:70vw;
  height:300px
}

.userinfobody {
  padding: 10px 20px;
}
  
.userinfobody h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color:var(--primary);
}
  
.userinfobody h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.userinfobody p {
  margin: 12px 0;
}
.userinfobodydm {
  padding: 10px 20px;
}
  
.userinfobodydm h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color:var(--primary);
}
  
.userinfobodydm h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.userinfobodydm p {
  margin: 12px 0;
}
.userinfobodydm btn{
  background-color: rgb(255,255,255,0.3)!important;
}
  
small { 
  font-size: 14px;
  font-weight: bold;
}
  
.online {
  color: #11aa00;
  }
.offline {
  color: #888888;
  }
  
.btn {
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
  margin:5px 0px;
}
.btndm {
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  background-color: rgb(255,255,255,0.5);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
  margin:5px 0px;
}
.btn:hover {
 background-color: #04003b;
 color: #eeeeee;
}
.btndm:hover {
 background-color: #04003b;
 color: #eeeeee;
}
  
.btnabo {
  text-decoration: none;
  margin:5px 0px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: #04003b;
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}

.btnabo:hover {
 background-color: var(--primary);
 color: #ffffff;
} 
.btnabodm {
  text-decoration: none;
  margin:5px 0px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: #04003b;
  background-color: rgb(255,255,255,0.5);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}

.btnabodm:hover {
 background-color: var(--primary);
 color: #ffffff;
}  

.btnblock {
  display: block;
  width: 100%;
  text-align: center;
}
.btnblock:hover {
 background-color: var(--primary);
 color: #ffffff !important;
} 
.nextline{
  margin-top:15px;
  text-align:center;
}
 .heart {
   z-index : 100;
   position : absolute;
   animation:heartfade 2s linear;
}
.heart:before,
.heart:after {
    margin-top:-30px;
   content : "";
   z-index : 100;
   background-color : var(--primaryBright);
   position : absolute;
   height : 22px;
   width : 35px;
   border-radius : 15px 0px 0px 15px;
}
.heart:before {
   transform : rotate(45deg);
}
.heart:after {
   left : 10.5px;
   transform : rotate(135deg);
}
  @keyframes heartfade {
     0% {
      opacity : 0;
     }
     50% {
      opacity : 1;
      transform: translateY(-30px);
     }
     70% {
      opacity : 0;
      transform: translateY(-50px);
     }
     100% {
      opacity : 0;
     }
  }
  .thankyoutext{
    color:black;
  }
  .thankyoudiv{
    width:50vw;
    height:100%;
    padding:5%;
  }
.thankyouimage{
  border-radius:50%;
  width:10vw;
  height:10vw;
  border:5px solid var(--primary);
  margin-right:5%;
}
.thankyouheader{
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom:1em;
}
.thankyouimagetext{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 1068px) {
  .thankyoudiv{
    width:75vw;}
.thankyouimage{
 
  width:30vw;
  height:30vw;
}
}

.girlimage{

  width: 100%;

}