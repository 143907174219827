.single{
    
    display: flex;
  flex-direction: row;
}
.singleimg{
    width:20%;
    padding:3px
}
.centerme{
    position: absolute;
        top: 50%;
        left: 50%;
          transform: translate(-50%,-50%);
          width:100%;
          height:100%;
}
.errormessage{
  font-size:0.9em;
  color: var(--primary);
  text-align: center;
  padding:0% 5%;
}
.bigimage{
   
    width:100%;
    margin: auto;
    cursor:pointer;
    padding:3px;
   
   
}
.videoPrev{
max-width:100%;

}.bigimageMobile{
    max-width:100%;
     max-height:100%;

    margin: auto;
    cursor:pointer;
    padding:3px;
     position: relative;
        top: 50%;
        left: 50%;
          transform: translate(-50%,0%);
         
}
/* .bigimageMobile{
    max-width:100%;
     max-height:100%;

    margin: auto;
    cursor:pointer;
    padding:3px;
     position: absolute;
        top: 50%;
        left: 50%;
          transform: translate(-50%,-60%);
         
} */
.playbtn{
  
   margin:auto;
   text-align:center;
   margin-top:-66%;
   margin-left:47%;
   width:40px;
   cursor:pointer;
   box-shadow: 0 0 40px rgba(0,0,0,0.5);
   opacity:80%;
  
}
.playbtn2{
  position:absolute;
    z-index:30;
    top: 50%;
        left: 50%;
          transform: translate(-50%,-50%);
    width:50px;
   
}
.wrapper{
    position:relative;
   width:100%;
   background-color:black;
}
.wrapvideoclass{
    height:100%;
    position:relative;
}