@font-face{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

:root{
  --primary: #ff7100;
  --secondary-color: #000;
  --nearBlack:#040222;
  --primary-dark: #b15f1b;
  --primaryBright: #ef944a;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --white-color: #fff;
  --black-color: #000;

}

body {
 max-width:100vw;

 overflow-x: hidden;

 

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-display: swap;
}

.color-change {
    -webkit-filter: hue-rotate(9deg) saturate(1.8);
    filter: hue-rotate(9deg) saturate(1.8);
}