.size {
    width: 90%;
    height: 100%;
    margin: auto;
}


.commentformstyle textarea {
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
    color: #24292e;
    vertical-align: middle;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: right 8px center;
    border: 1px solid #e1e4e8;
    border-radius: 6px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
}

.commentformstyle textarea:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: rgba(214, 3, 3, 0.3) 0px 0px 0px 3px;
}

.textforGirl {
    min-height: 100px;
    width: 98%;
    padding: 2%;
    margin: 1% 0%;
}

.commentbtn {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: 1px solid var(--primary)50;
    font-weight: 700;
    background: #fff;
    padding: 0 8px 1px;
    font-size: 13px;
    border-radius: 4px;
    color: #fff;
    height: 28px;
    min-width: 56px;
    transition: all 80ms linear;
    background: var(--primary);
}

.commentbtn:hover {
    border: 1px solid #00000050;
    color: #000;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
    background: rgba(248, 248, 248, 1);
}