.size{
    width:100%;
    height:100%;
   color:#333;
}
.textforGirl{

    min-height:100px;
    width:98%;
    padding:2%;
    margin:1% 0%;
   
    border-radius:20px;
}
.fixit{background-color: aqua;}
.errmsg {
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius:5px;
}

.buttonfield {
    margin: auto;
    padding: 2%;
}
.buttonfield > div {
    display: inline-block;
}
.buttonfield > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    white-space: nowrap;
}
.writeMessageAttachments {
    display: flex;
    width: 100%;
}
.writemessage{
    padding:10px;
}