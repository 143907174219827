.card{

    width:100%;
    cursor:pointer;
    background-color: aliceblue;
    color:black;
    border-radius:20px;
    box-shadow: 2px 2px 2px 2px rgb(98, 98, 98,0.3);
    margin-top: 2%;
}
.textfield{
  padding:2%;
}
.headerfield{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.headersmall{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.buttonfield{
    margin:auto;
    padding:2%;
}
.contentfield{
    transition:1s;
}
.msgimg {
    position: absolute;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    top: 18px;
    right: 10px;
    z-index: 11;
    outline: solid 2px white;
}

.mobileCard{
    font-size: smaller;
    padding:2%;
    
}
.headline{
    font-size: 12px;
    margin:3%;
    width:50%;
}
.msgimgmobile {
    position: absolute;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    top: 15%;
    right: 12%;
    z-index: 11;
    outline: solid 2px white;
}
.contentfieldmobile{
    background-color: rgb(223, 219, 214);
}