.wrapper {
  margin: auto;
  width: 90%;
  margin-bottom:25px;
}

.borderisDone {
  border: 2px solid rgb(0, 128, 0);
  text-decoration: line-through;
  background-color: rgb(0, 128, 0,0.3);
  text-align: center;
   width: 100%;
  padding:1%;
  border-radius: 8px;
  margin: 1% auto;
  font-weight:bold;
  
}
.border {
  border: 2px solid var(--primary);
  background-color: rgb(200, 50, 50,0.3);
  text-align: center;
  width: 100%;
  padding:1%;
  border-radius: 8px;
  margin: 1% auto;
   font-weight:bold;
 cursor: pointer;
}
.hostname{
  color: var(--primary);
  text-transform: uppercase;
}
.bouncebottom{
  margin-bottom:15px;
}
.higherup {
  margin-top: -50px;
}
.headline {
  text-align: center;
  margin-top: -8%;
}
.icon{
  max-width:20px;
  width:auto;
  height:auto;
}
.icon2{
  max-width:40px;
  width:auto;
  height:auto;
  margin: auto;
  display:flex;
  justify-content: center;
}