.pad{
    padding:1%;
    z-index:0;
   scroll-snap-type: none;
}
.padshort{
   padding:1%;
    margin:1%;
}
.userinfo {
  z-index:0;
  background-color: #fff;
  border:1px solid #eeeeee;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#000;
}
.container{
    z-index:0;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  color:#000; 
  margin:1%;
  padding:10px;
  width:98%;
 
}
.header{
    text-align:center;
    width:100%;
    font-weight:bold;
    font-size:1.3em;
    
}
.catoAc{
    margin-top:8px;
    margin-bottom:6px;
    width:100%;
    cursor:pointer;
    text-align:center;
    margin:auto;
    font-weight:bold;
    font-size:1.5em;
    color:white;
}
.innerWrapper p{
    margin-bottom:2px;
}
.wrapper{
    background-color: var(--primary);
    border:3px solid var(--primary);
    border-radius:10px;
   max-width:100%;
    margin:6px;
    padding:0;
     transition:0.5s;
      scroll-snap-align: start;
}
.wrapper:hover{
    background-color: #810202;
    border:3px solid #810202;
    transition:0.5s;
    box-shadow: 0px 0px 20px 10px rgba(200,50,50,0.19), 0 6px 6px rgba(200,50,50,0.23);  
}
.innerWrapper{
    background-color: rgb(255, 255, 255);
    border-radius:10px;
    width:100%;
    margin:0;
    padding:10px 6px;
    
}
.initialPrice{
    margin-top:10px;
   font-weight:bold;
    text-align: center;
    
}

.content {
   z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;

}
.header{
    width:100%;
    border-bottom:1px solid black;
    text-transform: uppercase;
    font-weight: 600;
    min-height:5em;
    display:flex;
   align-items: center;
   justify-content: center;
}

.thetiers{
margin-top:10px;
 width:100%;
 
}
.thelist{
  
    list-style: none;
    width:100%;
   
    border-radius:10px;
    margin: auto;
    }
    .thelistdm{
   background-color: #504E64;
    list-style: none;
    width:100%;
    
    border-radius:10px;
    margin: auto;
    }
.firstline{
    font-weight:bold;
   
   
}
.priceline{
    margin-top:20px;
    font-weight: bold;
}
.thebutton{
    margin-left:20px;
   
}
.mobilelist{
    list-style: none;
    margin:none;
    padding:2%;
    border:1px solid black;
    border-radius:10px;
    width:100%;
}
.mobilelist li{
    margin-left:4px;
    line-height: 0.8em;
}
.specialcontainer{
   
}
.specialofferheader{
    font-size:16px;
    font-weight:bold;
   
}
.specialoffer{
    font-size:16px;
}
.specialprice{
    text-align:center;
    font-weight:bold;
}
.smallprice{
    font-size:14px;
}
.kuendigung{
    font-size: 0.8em;
}
.containertrans{
    z-index:0;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  color:#000; 
  margin:1%;
  padding:0px;
  width:98%;
}
.extrahead{
    text-align:center;
}
.extraheaddm{
    color:white;
      text-align:center;
}