.wrapper{
    width:100%;
}
.flexit{display:flex;}
.view{cursor:pointer;}
.list{
   
    list-style: none;
    margin: none;
    padding:none;
    display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
    gap:10px;
margin:0;
padding:0;
    }
    .centercontent{
      text-align:center;
    }
  .trenner{
    color: var(--primary);
    border-bottom:3px solid var(--primary);
  }
  .mainhead{
    text-align: center;
    margin-bottom:1em;
    color:var(--primary);
  }
  .functionicons{
    width:60px;
    transition:0.5s;

  }
  .iconlist{
    margin-top:2rem;
    display:flex;
    flex-flow: row;
    justify-content: space-around;
  }
.functionicons:hover{
  transform:scale(0.9);
  transition:0.5s;
}
  
@media only screen and (max-width: 778px) {

 .functionicons{
    width:45px;
  }
}