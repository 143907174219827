.styles{
    height:100%;
    width:100%;
}
.myimageelement{
    margin-top:5px;
    border-radius:5px;
    margin:5px;
    overflow:hidden;
    max-width:100%;
    justify-content: center;
}
.errormessage{
    width:90%;
    font-size:0.9em;
    color:var(--primary);
    text-align:center;
}
.errorContainer{
    display:flex;
    flex-flow: column;
    justify-content: center;
}