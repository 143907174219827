.thelist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 1%;
}
.listbutton {
  display: inline-flex;
}
.listelement {
  margin: 5px 0;
   border: 2px solid rgb(18, 159, 14);
  padding: 5px;
  transition:0.5s;
}
.listelement:hover{
    background-color:rgb(18, 159, 14,0.1); transition:0.5s;
}
.addmore {
  margin: 15px 20px;
}
.boxit {
 
   border: 1px solid var(--primary);
  border-radius: 6px;
  margin: 2% 0;
  padding: 1%;
  background: transparent;
}
.deleteIcon {
  width: 25px;
filter:grayscale(1);
  float: right;
}
.thingieicon {
  height: 25px;
  margin-right: 10px;
}
