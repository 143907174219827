.MyButton {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:transparent;
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:var(--primaryBright);
		font-size:1em;
	padding:0.8% 6px;
	text-decoration:none;
    transition:1s;
    margin:0.3%;
    margin-bottom:7px;
    width:100%;
    text-align:center;
    font-weight:bold;
}
.MyButton:hover {
	 transition:0.5s;
	background-color:none;
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.MyButton:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    
.MyButton {
    font-size:13px;
}
}