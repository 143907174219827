.allthecomments {
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 100%);
    border-radius: 10px;
    margin: 5px;
    padding: 5px 0px;
}

.allthecommentsdm {
    background-color:#151424;
    border-radius: 10px;
    margin-top: 1%;
    border: 1px solid #c0c0c0;
    margin: 5px;
    padding: 5px 0px;
    color: white;

}

.allthecommentsdm a {
    color: white !important;
}

.wrapbuttondiv {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
}

.commentbtn {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: 1px solid var(--primary)50;
    font-weight: 700;
    background: #fff;
    padding: 0 8px 1px;
    font-size: 13px;
    border-radius: 4px;
    color: #fff;
    height: 28px;
    min-width: 56px;
    transition: all 80ms linear;
    background: var(--primary);

}

.commentbtn:hover {
    border: 1px solid #00000050;
    color: #000;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
    background: rgba(248, 248, 248, 1);
}

.thebutton {
    margin: auto;
}