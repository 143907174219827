.heads{
    font-weight:bold;
}
.buttons{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    gap:10%;

}
.buttons div{
    width:100%;
}
.text{
    font-size:0.9em;
}