.darkBG {
   
 position:fixed;
  z-index: 0;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
   
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 100%;
  height: 100%;
 
 
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:1%;
  overflow: hidden;
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #fff;
 background:transparent;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 50px;
  margin-right: 12px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: scale(0.9);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
.modal{
  padding:2%;
  color:black
}
.inactive{
  background-color: beige;
  color:#2c3e50;
}
.checkbox{
  margin-right:1%;
}

    .auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
  }
  
  .auth h1 {
    text-align: center;
    color: white;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .actions button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .actions .toggle:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .form{
    border-radius:20px;
    background-color: var(--primary);
    padding:5%;
  }
  .theheader{
    color:#fff;
    text-align: center;
  }