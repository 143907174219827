.dropdownelementwrapper{
  float:right;
}
.dropdownmenu{

position:absolute;
right:20px;
  padding-right:15px;

}
.dropdownmenulogout{

position:absolute;
right:20px;
  padding-right:15px;
  margin-top:-5px;
}
.loginbutton{
  margin-right:100px;
}
.countrymaker{
  position: fixed;
top:1%;
  left:25%;
  cursor:pointer;
}
.dropdownmenu img{
    width:40px;
}
.dropdownmenulogout img{
    width:40px;
}

.dropdownmenu div{
  width:100%;
 
  overflow:hidden;
  border-radius:10px;
  box-shadow: 2px 2px 2px rgb(20,20,20,0.2);
}
.dropdownmenulogout div{
  width:100%;
 
  overflow:hidden;
  border-radius:10px;
  box-shadow: 2px 2px 2px rgb(20,20,20,0.2);
}

.dropdown{
  display:flex;
  flex-direction: row;
  cursor:pointer;
 
  padding:2px 4px !important;
  margin:2px 2px;
  width:100%!important;
  box-shadow:none!important;
  color:var(--primary);
    text-decoration: none;
}
.dropdownsmall{
  display:flex;
  flex-direction: row;
  cursor:pointer;
 font-weight:normal;
  padding:2px 4px !important;
  margin:2px 2px;
  width:100%!important;
  box-shadow:none!important;
  color:var(--primary);
    text-decoration: none;
}
.dropdown:hover{
  background-color:rgb(200, 50, 50,0.3);
  text-decoration: none;
  color:var(--primary);
}
.dropdown img{
  width:20px;
  height:20px;
  margin-right:5px;
  margin-left:2px;
}
.dropdown:a{
    color:var(--primary);
    text-decoration: none;
}
.fandollar{
cursor:pointer;
  color:#fff;
  top:10px;
  font-size: 0.9rem!important;
border:2px solid white;
border-radius:10px;
padding:2px;
display: inline-block;
position:absolute;
left:46%;
width:8%;
min-width:120px;
margin:auto;
display:flex;
flex-flow:row;
justify-content: space-evenly;

}
.language{
cursor:pointer;
  color:#fff;
  top:10px;
  font-size: 0.9rem!important;

border-radius:10px;
padding:2px;
display: inline-block;
position:absolute;
left:2%;
width:8%;
min-width:120px;
margin:auto;
display:flex;
flex-flow:row;
justify-content: space-evenly;

}
.fandollarimg{
  width:20px;
  margin-left:5px;
  color:var(--primary);
background:none;
}
.login{
    position:absolute;
    top:10px;
    right:20px;
    display:flex;
    flex-flow:row;
    
}
.active{font-weight:bold;}
.inactive{color:#000;}

.payAttention{
  position:absolute;
  right:20%;
  top:10px;
  width:40px;
  filter:brightness(0) invert(1);
   animation: shake 1.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% { transform:  rotate(0deg); }
  10% { transform: rotate(-1deg); }
  20% { transform: rotate(1deg); }
  30% { transform:  rotate(0deg); }
  40% { transform:  rotate(1deg); }
  50% { transform: rotate(-1deg); }
  60% { transform:  rotate(0deg); }
  70% { transform: rotate(-1deg); }
  80% { transform: rotate(1deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-1deg); }
}

.inputElement input {
  font: inherit;
  background-color: #fce1e4;
  color: var(--primary);
  border-radius: 4px;
  border: 1px solid #fce1e4;

  text-align: left;
  padding: 0.25rem;
  margin: 5px;
}
.inputElement {
  width: 100%;
}
.iconinactive {
  filter: grayscale(1);
  width: 30px;
  cursor: pointer;
}
.iconactive {
  filter: grayscale(0);
  width: 30px;
  cursor: pointer;
}
.infotext {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
 height:210px;
  margin-bottom: 5px;
 overflow:hidden;
}
.profpicwrapper {
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  width: 100%;
}
.area {
  width: 100%;
  height: 200px;
}
.personalbox{
 
  padding:3px;
  width:99%;
  margin:auto;
  margin-bottom:10px;
}
.toright{
  position:relative;
 
  right:5px;
  float:right;
}
.desctext{
  max-height:120px;
  overflow:hidden;
}

.toggle {
	 --size: 1.1rem;
	 appearance: none;
	 outline: none;
	 cursor: pointer;
	 width: var(--size);
	 height: var(--size);
	 box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
	 border-radius: 999px;
	 color: #a2a2a2;
	 transition: all 500ms;
   margin-top:2px;
}
 .toggle:checked {
	 --ray-size: calc(var(--size) * -0.4);
	 --offset-orthogonal: calc(var(--size) * 0.65);
	 --offset-diagonal: calc(var(--size) * 0.45);
	 transform: scale(0.75);
	 color: #fa0;
	 box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}