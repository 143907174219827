.btn {
   z-index:0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  
  font-weight: 600;
  font-size: 12px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.btn:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.btn span {
  position: relative; 
  z-index: 1;
}

.btn span a {
text-decoration: none;
}
.btndm {
   z-index:0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;

  font-weight: 600;
  font-size: 12px;
  color: var(--primary);
  background-color: rgb(255,255,255,0.6);
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.btndm:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.btndm span {
  position: relative; 
  z-index: 1;
}

.btndm span a {
text-decoration: none;
}