.leftmenue {
  margin-top: 30px;
cursor:pointer;
  width: 100%;
}
.leftmenuedm {
  margin-top: 30px;
cursor:pointer;
  width: 100%;
}
.icon {
  margin: 0;
  padding: 0;
}
.leftmenue ul {
  list-style: none;
  width: 100%;
}

.leftmenue li {
  margin-right: 0px;
  padding: 0px;
}
.extraMail {
  background-color: aliceblue;
  color: var(--primary);
  border-radius: 20px;
  position: absolute;
  box-shadow: 3px 3px 3px rgb(20, 20, 20, 0.2);
  padding: 2px 8px;
  border: 1px solid var(--primary);
  top: 0;
  right: 30%;
  font-weight: bold;
}
.logowrapper {
  position: relative;
}
.logo {
    position: relative;
  width: 130px;
  margin-bottom: 5vh;
  z-index:50;
  
}
.profilelogo {
  position: absolute;
  width: 93px;
  border-radius: 50%;
  height: 93px;
  z-index:1;
  left:3px;
  top:3px;
  object-fit: cover;
}
.leftmenueextra {
  position: relative;
}
.leftmenue ul li {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.25em;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.leftmenue a {
  color: #333;
  margin-top: -10px;
  padding-bottom: 1%;
  margin-bottom: -1%;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.leftmenue a:hover {
  color: #000000;
  text-decoration: none;
  width: 100%;
  background: #efefef;
  border-radius: 15px;
}

.leftmenuedm ul {
  list-style: none;
  width: 100%;
}

.leftmenuedm li {
  margin-right: 0px;
  padding: 0px;
}

.leftmenuedm ul li {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.25em;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.leftmenuedm a {
  color: #333;
  margin-top: -10px;
  padding-bottom: 1%;
  margin-bottom: -1%;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}
.contain {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.leftmenuedm a:hover {
  color: #000000;
  text-decoration: none;
  width: 100%;
  background:#05022250;
  border-radius: 15px;
}

.updownarrow {
  display: block;
  margin: 0 auto;
  width: 20px;
  cursor: pointer;
}
/*  ###########################################  */
/*  LIGHTMODE ICONS                              */
/*  ###########################################  */ 
.icon1::before {
  content: url(/images/desktop_menue_icons/timeline.png)" ";
  padding: 5px 0 5px 0;
}
.icon2::before {
  content: url(/images/desktop_menue_icons/favoriten.png) " ";
  padding: 5px 0 5px 0;
}
.icon3::before {
  content: url(/images/desktop_menue_icons/login.png) " ";
  padding: 5px 0 5px 0;
}
.icon4::before {
  content: url(/images/desktop_menue_icons/kartehinzufuegen.png) " ";
  padding: 5px 0 5px 0;
}
.icon5::before {
  content: url(/images/desktop_menue_icons/inbox.png) " ";
  padding: 5px 0 5px 0;
}
.icon6::before {
  content: url(/images/icons/benachrichtigung.png) " ";
  padding: 5px 0 5px 0;
}
.icon7::before {
  content: url(/images/icons/lesezeichen.png) " ";
  padding: 5px 0 5px 0;
}
.icon8::before {
  content: url(/images/desktop_menue_icons/channels.png) " ";
  padding: 5px 0 5px 0;
}
.icon9::before {
  content: url(/images/desktop_menue_icons/werdecreator.png) " ";
  padding: 5px 0 5px 0;
}
.icon10::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/gekauft.png) " ";
  padding: 5px 0 5px 0;
}

/*  ###########################################  */
/*  DARKMODE ICONS                               */
/*  ###########################################  */ 
.icon1dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/timeline_dm.png)" ";
  padding: 5px 0 5px 0;
}
.icon2dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/favoriten_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon3dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/login_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon4dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/kartehinzufuegen_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon5dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/inbox_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon6dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/benachrichtigung.png) " ";
  padding: 5px 0 5px 0;
}
.icon7dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/lesezeichen.png) " ";
  padding: 5px 0 5px 0;
}
.icon8dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/channels_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon9dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/werdecreator_dm.png) " ";
  padding: 5px 0 5px 0;
}
.icon10dm::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/gekauft_dm.png) " ";
  padding: 5px 0 5px 0;
}

.xtrabtn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.smallnav {
  text-align: center;
  margin-top: -10%;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
}
.bignav {
  text-align: center;
  margin-top: -5%;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
}
.fandollar {
  cursor: pointer;
  color: #666;
  font-size: 0.9rem !important;

  display: inline-block;

  margin: auto;
}
.fandollarimg {
  width: 20px;
  margin-left: 5px;
  color: var(--primary);
  background: none;
}
@media (max-width: 768px) {
  .outlinegone {
    width: 123%;
  }
  .leftmenue {
    margin: auto;
  }
  .leftmenue ul {
    padding: 0;
  }
  .xtrabtn {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .leftmenue ul li {
    margin-top: 0px !important;
  }
}

@media (max-width: 368px) {
  .fandollarimg {
    display: none;
  }
  .outlinegone {
    width: 80%;
  }
}
