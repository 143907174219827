.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.55);
}
.backdropbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 1);
}
.backdroptwo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.35);
}
.extramodal {
  top: 10%;
  padding: 20px;
  padding-top:40px;
  overflow-y: auto;
  overflow-x: hidden!important;
  max-height:70vh;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modalbig {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;
  top: 5%;
  width: 90vw;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modalbigdm {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;
  top: 5%;
  width: 90vw;
  background-color: rgb(41, 41, 41);
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modalbig::-webkit-scrollbar {
  display: none;
}
.modalfs{
  padding: 20px;
  padding-top:40px;
  position: fixed;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 100vh;
  width:100vw;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modalbigblack {

  padding: 20px;
  padding-top:40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  background-color: black;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modalbigblack::-webkit-scrollbar {
  display: none;
}
.modalunsichtbar {
  padding: 20px;
  padding-top:40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: transparent;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modal {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;

  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.smallmodal {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  min-width: 10%;
   overflow-y: scroll;
    overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.closex {
  background: none;
  width: 25px;
  border: none;
}
.closeBtn {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
filter:grayscale(1);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 0px;
  margin-right: 0px;
  z-index: 1000;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: scale(1.2);
}
.distancer {
  margin: 20px auto;
}
@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  .smallmodal {
    left: 45%;
    width: 20%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.okbutton {
  margin: auto;
}
