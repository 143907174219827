.smaller{
    max-width:1400px;
    margin:auto;
    background-color: rgb(233, 235, 235);
        box-shadow: 6px 3px 5px -4px rgba(183, 192, 205, 0.1), -8px 0 4px -4px rgba(201, 207, 216, 0.1);
  width:100vw;
}
.smallerdm{
    max-width:1400px;
    margin:auto;
    background-color: #262540;
    width:100vw;
}
.thingie{
    min-height:100vh;
}
.header{
    z-index:100;
    width:100vw;
 
    position:fixed;
   
    max-width:1400px;
    background-color: var(--primary);
    height:50px;
    color:white;
    padding:5px 10px;
}
.rechteSpalte{
    position:fixed;
    max-height:100%;
    z-index:0;
    width:100%;
    top:70px;

   
    
}
.rightelement{
    height:110vh;
}
.bottombouncer{
    height:200px;
    position:relative;
    bottom:0;
}
.rechteSpaltedm{
    position:fixed;
    max-height:100%;
    z-index:0;
    width:100%;
    top:70px;
   
   
    
}
.middleElement{
     box-shadow: 3px 3px 5px -4px rgba(31, 73, 125, 0.1), -4px 0 4px -4px rgba(31, 73, 125, 0.1);
    margin-top:50px;
    padding-top:10px;
    min-height:100vh;
     background-color: white;
}
.middleElementdm{
     box-shadow: 3px 3px 5px -4px rgba(31, 73, 125, 0.1), -4px 0 4px -4px rgba(31, 73, 125, 0.1);
    margin-top:50px;
    padding-top:10px;
    min-height:100vh;
     background-color: var(--nearBlack);
     
}
.linkeSpalte{
width: 16.36666667%;
 height:100%;
 min-height:1003px;
position:fixed;
overflow:scroll;
max-width:380px;
 z-index:1;
top:50px;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */


}
.linkeSpalte::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */}
    .linkerwrapper::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */}
.linkerwrapper{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

   
}

@media only screen and (min-width: 1400px) {
  .linkeSpalte {width:222px}
}