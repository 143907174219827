.buttonfield {
    margin: auto;
    padding: 5px 2px;
    display:flex;
    flex-direction: row wrap;
    justify-content: space-between;
    width:100%;
}
.buttonfield > div {
    display: inline-block;
}
.buttonfield > div.active > button {
    text-decoration: underline;
}
.buttonfield > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    white-space: nowrap;
}
.spacer{
    margin:2px 10px;
}

.spinning {
    width: 45px;
    height: 45px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}