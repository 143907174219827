.image{
   margin: 2px 16px;
   width:70px;
   height:100%;
}
.flexit{
    display: flex;
    flex-flow: row;
gap:20px;
width:100%;
justify-content: flex-start!important;
}
.flexit2{
    display: flex;
    flex-flow: row wrap;
gap:20px;
width:100%;
justify-content: space-evenly!important;
}
.addapayment{
    cursor:pointer;  
    margin:auto;
    background-color: #efefef;
    color:#555;
    font-size:1.0em;
    font-weight:bold;
    
}
.flexit{
    display:flex;
    flex-flow:row wrap;
    justify-content: space-evenly;
}
.erklärbär{font-size:0.9em;}
.choosewrapper{
    margin:auto;
    text-align:center;
    padding:10px;
}
.text{
     align-self: center;
   margin-top:14px;
}
.flexcontainer{
    display:flex;
    flex-flow: column;
    background-color: #fbfbfb;
    gap:10px;
    border:1px solid var(--primary);
    border-radius:8px;
    padding:20px;
}
.flexcontainerdm{
    display:flex;
    flex-flow: column;
    background-color: var(--nearBlack);
    gap:10px;
    border:1px solid var(--primary);
    border-radius:8px;
    padding:20px;
}
.secondcontainer{
    margin-top:20px;
    justify-content: space-evenly;
    width:100%;
}
.extraimage{
    cursor:pointer;
     transition:0.5s;
}
.extraimage:hover{
    box-shadow:2px 3px 6px var(--primary) , -2px -3px 6px var(--primary);
    transition:0.5s;
}
.paymentInfo{
    text-align:center;
    font-size:0.7em;
}
.pseudo{
    color:var(--primary);
    font-weight:bold;
}
@media only screen and (max-width: 950px) {
  .text{
    font-size:0.7em;
    font-weight:normal;
  }
  .flexit{
    justify-items: left;
    flex-wrap: nowrap;
  }
  .secondcontainer{
    margin-top:0px;
  }
}