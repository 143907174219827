.auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
   
  }
  .flexit{
    display:flex;
    justify-content: center;
    gap:5px;
  }
  .flexitshort{
    display:flex;
    justify-content: space-around;
    gap:5px;
  }
  .auth h1 {
    text-align: center;
    color: white;
  }
  .facebookbutton{width:30px; height:30px;}
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  .actions2 button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .actions button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  .actions2 .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .actions .toggle:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .white{
    color:#fff;
  }