@font-face{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

:root{
  --primary: #ff7100;
  --secondary-color: #000;
  --nearBlack:#040222;
  --primary-dark: #b15f1b;
  --primaryBright: #ef944a;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --white-color: #fff;
  --black-color: #000;

}

body {
 max-width:100vw;

 overflow-x: hidden;

 

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-display: swap;
}

.color-change {
    -webkit-filter: hue-rotate(9deg) saturate(1.8);
    filter: hue-rotate(9deg) saturate(1.8);
}
 .loadingspinner_mat__1_plZ{
  	width:60vw;
	height:60vh;
	background-color:rgb(135,213,100,0.3);
	border-radius:6px;
	border: 4px solid grey;
	align-content:justify;
  }
  .loadingspinner_wrapper__1-UQ1{
    width:100%;
    height:100%;
    max-height:40vh;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    
  }
  .loadingspinner_wrapbig__2xIlm{
margin-top:30%;
  }
  .loadingspinner_sizebig__3zf0W{ width:120px;
	height:120px;}

  .loadingspinner_wrapsmall__3I5w3{
margin-top:10%;
  }
  .loadingspinner_sizesmall__17SlZ{ width:50px;
	height:50px;}
 


  .loadingspinner_myobject__1NPco{
    
   
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	 position:absolute;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	
	-webkit-animation:loadingspinner_spin__1XJa0 1.6s linear infinite;
	
	        animation:loadingspinner_spin__1XJa0 1.6s linear infinite;
	z-index:4;
	
  }
  .loadingspinner_myobjectApple__2IQ67{
    
   
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	 position:absolute;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	
	-webkit-animation:loadingspinner_spin__1XJa0 1.6s linear infinite;
	
	        animation:loadingspinner_spin__1XJa0 1.6s linear infinite;
	z-index:4;
	
  }
   .loadingspinner_myobject2__2-iqd{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_02.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	-webkit-animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	        animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	
  }
   .loadingspinner_myobjectApple2__1FjD5{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_02.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	-webkit-animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	        animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	
  }
  .loadingspinner_myobject3__1ugI0{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	-webkit-animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	        animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	
  }
   .loadingspinner_myobjectApple3__2ECvJ{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	-webkit-animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	        animation:loadingspinner_spin2__aUi4J 1.6s linear infinite;
	
  }
 
@-webkit-keyframes loadingspinner_spin__1XJa0 {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    to {-webkit-transform: rotate3d(0.1, 1, 0, 360deg);transform: rotate3d(0.1, 1, 0, 360deg);}
}
 
@keyframes loadingspinner_spin__1XJa0 {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    to {-webkit-transform: rotate3d(0.1, 1, 0, 360deg);transform: rotate3d(0.1, 1, 0, 360deg);}
}
@-webkit-keyframes loadingspinner_spin2__aUi4J {
    from {-webkit-transform: rotate3d(0.05, 0.5, 0, 180deg);transform: rotate3d(0.05, 0.5, 0, 180deg);}
    to {-webkit-transform: rotate3d(0.15, 1.5, 0, 540deg);transform: rotate3d(0.15, 1.5, 0, 540deg);}
}
@keyframes loadingspinner_spin2__aUi4J {
    from {-webkit-transform: rotate3d(0.05, 0.5, 0, 180deg);transform: rotate3d(0.05, 0.5, 0, 180deg);}
    to {-webkit-transform: rotate3d(0.15, 1.5, 0, 540deg);transform: rotate3d(0.15, 1.5, 0, 540deg);}
}

  @-webkit-keyframes loadingspinner_spin6__1eBT- {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	45%{left:30.02%}
	55%{left:3.04%;
	  top:30.2%;
	box-shadow:2px 1px 4px; }
	55%{left:30%;
	top:30.0%;
	box-shadow:0px 0px 0px;}
    to {-webkit-transform: rotate3d(1, 1, 0.7, 360deg);transform: rotate3d(1, 1, 0.7, 360deg);}
}

  @keyframes loadingspinner_spin6__1eBT- {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	45%{left:30.02%}
	55%{left:3.04%;
	  top:30.2%;
	box-shadow:2px 1px 4px; }
	55%{left:30%;
	top:30.0%;
	box-shadow:0px 0px 0px;}
    to {-webkit-transform: rotate3d(1, 1, 0.7, 360deg);transform: rotate3d(1, 1, 0.7, 360deg);}
}
 
@media only screen and (max-width: 789px) {
	 .loadingspinner_sizebig__3zf0W{ width:50px;
	height:100px;}
  .loadingspinner_myobjectApple3__2ECvJ{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.loadingspinner_myobjectApple2__1FjD5{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_02.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.loadingspinner_myobjectApple__2IQ67{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.loadingspinner_myobject3__1ugI0{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.loadingspinner_myobject2__2-iqd{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_02.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.loadingspinner_myobject__1NPco{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
}
.modeladvertorial_pad__2rSBo {
  padding: 1%;
  z-index: 0;
  margin-bottom:3px;
  scroll-snap-align: start;
}
.modeladvertorial_nosnappad__2JOBC{
    padding: 1%;
  z-index: 0;
  margin-bottom:3px;

}

.modeladvertorial_padshort__3zDbN {
  padding: 1%;
  margin: 1%;
}
.modeladvertorial_loginButton__213v8{
  width:100%;
  display:flex;
  justify-content: center;
}
.modeladvertorial_userinfo__1L0Yp {
  z-index: 0;
  background-color: #fff;
  border: 1px solid #eeeeee;
  width: 100%;
  border-radius: 10px;
  
  color: #000;
  transition: 0.8s;
  position: relative;
}

.modeladvertorial_userinfo__1L0Yp a {
  text-decoration: none;
}

.modeladvertorial_fullwidth__240Tl {
  width: 100%;
  margin: -5px -10px;
  --bs-gutter-x: 0px;
}

.modeladvertorial_itemsright__U_ERc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modeladvertorial_itemsrightmobi__2LCW5 {
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-top: 5px;
}

.modeladvertorial_userinfodm__3IKcX {
  z-index: 0;
  background-color: rgb(255, 255, 255, 0);
  border: 1px solid rgb(255, 255, 255, 0.5);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #040222;
  color: aliceblue;
}

.modeladvertorial_hoverme__3WHHR:hover {
  background-color: var(--primaryBright);

  box-shadow: 3px 3px 5px 2px rgb(237, 237, 237, 0.6);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2.8s;
}

.modeladvertorial_userinfodm__3IKcX a {
  text-decoration: none;
}

.modeladvertorial_userinfoheader__1d3H1 img {
  width: 100%;
  min-height: 50%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: top left;
}

.modeladvertorial_userinfoheadermobile__1lBDM img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.modeladvertorial_flexit__1BlHs {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.modeladvertorial_userinfoheadershort__3uSyD img {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  object-position: center -25px;
}

.modeladvertorial_userinfobodymobile__2pzl9 {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 10px;
}

.modeladvertorial_userinfobodymobile__2pzl9 a {
  color: var(--primary);
  transition: 0.5s;
}

.modeladvertorial_userinfobodymobile__2pzl9 a:hover {
  color: #000;
}

.modeladvertorial_userinfobodymobile__2pzl9 h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary);
}

.modeladvertorial_userinfobodymobile__2pzl9 h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.modeladvertorial_userinfobodymobile__2pzl9 p {
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}
.modeladvertorial_userinfobodymobile__2pzl9 .modeladvertorial_linkelement__1Kjjk {
  padding: 0 0 10px 0;
}

.modeladvertorial_user__3vktz {
  display: flex;
  margin-top: auto;
  padding: 20px;
  position: absolute;
}

.modeladvertorial_wrapthings__1DbYV {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 12px;
  gap: 12px;
}

.modeladvertorial_grayscale__1Dbqv {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.modeladvertorial_makehigher__3b22_ {
  margin-top: -10px;

}

.modeladvertorial_desctextshort__3ZeNH {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  /* number of lines to show */
  line-height: X;
  /* fallback */
  max-height: X * N;
  /* fallback */
}
.modeladvertorial_likeheart__1YlqH{
   display: flex;
  margin-top: auto;
  right:60px;
  top:200px;
  padding: 20px;
  width:50px;
  position: absolute;
}
.modeladvertorial_likeheart__1YlqH img{
   width: 50px;
}
.modeladvertorial_user__3vktz img {
  border-radius: 50%;
  object-fit: cover;
  width: 160px;
  height: 160px;
  border: 4px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

}

.modeladvertorial_userinfobody__2Ib0l {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px;
}

.modeladvertorial_userinfobody__2Ib0l a {
  color: var(--primary);
  transition: 0.5s;
}

.modeladvertorial_userinfobody__2Ib0l a:hover {
  color: #000;
}

.modeladvertorial_userinfobody__2Ib0l h4 a {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary) !important;
}

.modeladvertorial_hideiftoobig__ofzMk {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left:10px;
  margin-top:10px;
}

.modeladvertorial_userinfobody__2Ib0l h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.modeladvertorial_userinfobody__2Ib0l p {
  font-size: 14px;
}

small {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.modeladvertorial_jahre__2V2Mh {
  color: #000;
}

.modeladvertorial_jahredm__1a9B3 {
  color: #eee !important;
}

.modeladvertorial_tag__3A6A0 {
  margin: 0 0 10px 0;
   display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modeladvertorial_tag__3A6A0 span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  margin-right: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.modeladvertorial_tagmobile__3mCn4 {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
}

.modeladvertorial_tagmobile__3mCn4 span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.modeladvertorial_hideText__22NVi{font-size:0.8em;margin:5px 0px;}
.modeladvertorial_plusmobile__3CbsK {
  text-decoration: none;
  color: #04003b;
  padding: 5px 7px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
  border: 2px solid #04003b;
}

.modeladvertorial_favicon__3Lgm6 {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
  -webkit-filter:grayscale(1);
          filter:grayscale(1)
}
.modeladvertorial_faviconwhite__258El {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
  -webkit-filter:brightness(0) invert(1);
          filter:brightness(0) invert(1);
}
.modeladvertorial_faviconnorm__1eZPl {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
 
}
.modeladvertorial_online__1qTEc{
  inline-size: 1.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;
  position:absolute;
  background:rgb(0, 174, 0);
  border:2px solid white;
  right:18%;
  bottom:20%;
}
.modeladvertorial_online2mob__2XWU8{
   inline-size: 3.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;
  position:absolute;
  background:rgb(0, 174, 0);
  border:2px solid white;
margin-top:2%;
}
.modeladvertorial_favicon__3Lgm6 :hover {
  width: 27px;
  transition: 0.8s;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

.modeladvertorial_tagcolor1__3NGHy {
  background-color: var(--primary);
}

.modeladvertorial_tagcolor2__2deUc {
  background-color: #04003b;
}

.modeladvertorial_tagcolor3__2x8Ma {
  background-color: #5d0202;
}

.modeladvertorial_btnout__dR1Ty {
  margin: 10px auto;
}

.modeladvertorial_btn__268wR {
  z-index: 0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--primary);
  padding: 5px 5px;
  margin: 0 auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.5s;
}

.modeladvertorial_btn__268wR span {
  position: relative;
  z-index: 1;
}

.modeladvertorial_btn__268wR {
  color: #fff;
  text-decoration: none;
}

.modeladvertorial_btn__268wR:hover {
  background-color: #04003b;
}

.modeladvertorial_setback__2oQJS {
  z-index: -40;
}

.modeladvertorial_mobileName__26-EC {
  padding: 2px 10px;
  color: black;
  text-decoration: none;
  font-size: 1em;
}

.modeladvertorial_mobileNameLink__8Puln {
  color: black;
}

.modeladvertorial_mobileNameLink__8Puln small {
  color: black;
}

.modeladvertorial_mobileNameLink__8Puln a {
  color: black;
  text-decoration: none;
}

.modeladvertorial_mobileNameLinkdm__25X0q {
  color: var(--primary) !important;
}

.modeladvertorial_mobileNameLinkdm__25X0q small {
  color: #fff !important;
}

.modeladvertorial_mobileNameLinkdm__25X0q a {
  color: var(--primary) !important;
  text-decoration: none;
}

.modeladvertorial_agemobi__3fThf {
  position: relative;
  top: -10px;
}
.modeladvertorial_agemobidm__36DG0 {
  position: relative;
  top: -10px;
  color: white !important;
}

.modeladvertorial_plus__3q0HO {
  display: inline;
  text-decoration: none;
  color: #04003b;
  padding: 0px 12px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 15px;
  width: 30%;
  cursor: pointer;
}

.modeladvertorial_plus__3q0HO:hover {
  background-color: #2e2e40;
  color: #fff !important;
}

.modeladvertorial_heart__b0KQw {
  z-index: 100;
  position: absolute;
  -webkit-animation: modeladvertorial_heartfade__3lxsr 2s linear;
          animation: modeladvertorial_heartfade__3lxsr 2s linear;
  opacity: 0;
}

.modeladvertorial_heart__b0KQw:before,
.modeladvertorial_heart__b0KQw:after {
  margin-top: -30px;
  content: "";
  z-index: 100;
  background-color: #ff7100;
  position: absolute;
  height: 22px;
  width: 35px;
  border-radius: 15px 0px 0px 15px;
}

.modeladvertorial_heart__b0KQw:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.modeladvertorial_heart__b0KQw:after {
  left: 10.5px;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

@-webkit-keyframes modeladvertorial_heartfade__3lxsr {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }

  70% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }

  100% {
    opacity: 0;
  }
}

@keyframes modeladvertorial_heartfade__3lxsr {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }

  70% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }

  100% {
    opacity: 0;
  }
}

.modeladvertorial_thankyoutext__3TgWR {
  color: black;
}

.modeladvertorial_thankyoudiv__2xyfL {
  width: 50vw;
  height: 100%;
  padding: 5%;
}

.modeladvertorial_thankyouimage__gQA0n {
  border-radius: 50%;
  width: 8vw;
  height: 8vw;
  border: 5px solid var(--primary);
  margin-right: 5%;
}

.modeladvertorial_thankyouheader__gzrOQ {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.modeladvertorial_thankyouimagetext__1RzDX {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modeladvertorial_linkelement__1Kjjk {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.modeladvertorial_linkelement2__WQogk {
  font-weight: bold;
  overflow: hidden;
  display: block;
  width: 100%;
}
.modeladvertorial_smaller__zt8bZ{

  font-size:0.7em!important;
}

.modeladvertorial_dropdownelementwrapper__Dphkq {
  float: right;
  width:100%;
  position:relative;
  z-index:600;
}

.modeladvertorial_dropdownmenu__2mDhY {
  float: right;
  font-size: 0.9rem;
  padding: 3px 15px 3px 3px;
   z-index:600;
 
}

.modeladvertorial_dropdownmenu__2mDhY div {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgb(20, 20, 20, 0.2);
   min-width:230px;
   padding:5px;
  
}

.modeladvertorial_dropdown__FS_M9 {
  width:50%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 4px !important;
  margin: 1px;
  box-shadow: none !important;
}

.modeladvertorial_dropdown__FS_M9:hover {
  background-color: rgb(200, 50, 50, 0.3);
}

.modeladvertorial_dropdown__FS_M9 img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 2px;
}

.modeladvertorial_infotext__6OSYL {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (max-width: 1068px) {
.modeladvertorial_favicon__3Lgm6 {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
  -webkit-filter:grayscale(1);
          filter:grayscale(1)
}
.modeladvertorial_faviconnorm__1eZPl {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
 
}
.modeladvertorial_faviconwhite__258El {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
  -webkit-filter:brightness(0) invert(1);
          filter:brightness(0) invert(1);
}
.modeladvertorial_favicon__3Lgm6 :hover {
  width: 22px;
  transition: 0.8s;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

  .modeladvertorial_fullwidth__240Tl {
  width: 103%;
  margin: -5px -10px;
  --bs-gutter-x: 0px;
}
  .modeladvertorial_thankyoudiv__2xyfL {
    width: 75vw;
  }

  .modeladvertorial_thankyouimage__gQA0n {
    width: 25vw;
    height: 25vw;
  }
}

.modeladvertorial_linkelements__3YNVl {
  width: calc(100% + 10px);
  display: inline-flex;
  flex-flow: row;
 grid-gap:10px;
 gap:10px;
  margin: 0px;
  justify-content: space-around;
  --bs-gutter-x: 0px;
  margin-bottom: 9px;
}
.modeladvertorial_linkelements__3YNVl > * {
  margin: 0px 0 0 0px;
}

.modeladvertorial_linkelement2__WQogk {
  background-color: #1a184b;
  color: white;
  padding: 5px;
  line-clamp: 2;
  border-radius: 10px;
  text-align: center;

  height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modeladvertorial_linkelement2__WQogk a {
  color: white !important;
  font-size: 0.9em;
}
.modeladvertorial_firstName__25q6O {
  font-weight: normal !important;
  font-size: 0.8em;
}
.modeladvertorial_firstNameright__5vbkt {
  font-weight: normal !important;
  font-size: 0.8em;
  margin-left:15px;
}
.modeladvertorial_secondName__2PxZZ {
  font-weight: bold !important;
  font-size: 0.8em;
}
.modeladvertorial_firstNamebig__1XYYp {
  font-weight: normal !important;
  font-size: 1em;
}
.modeladvertorial_secondNamebig__1VBz2 {
  font-weight: bold !important;
  font-size: 1em;
}
.modeladvertorial_mysideimage__1ukHK{
  width: 100%;
}

.modeladvertorial_distanceme__nssTB{
  margin: 10px 0px;
  scroll-snap-align: end;
}
.modeladvertorial_isCurOnline__ZhG10{color:rgb(0, 174, 0);
font-size: 0.6em;}
.modeladvertorial_isCurOnlineMob__2HqWz{color:rgb(0, 174, 0);
font-size: 0.8em;}
.modeladvertorial_isCurOnlineMobBig__F9ljP{color:rgb(0, 174, 0);
font-size: 0.6em;}

.modeladvertorial_online2__2z3so{
  inline-size: 3.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;

  background:rgb(0, 174, 0);
  border:2px solid white;
 
}

.mybutton_btn__2T16X {
   z-index:0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  
  font-weight: 600;
  font-size: 12px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.mybutton_btn__2T16X:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.mybutton_btn__2T16X span {
  position: relative; 
  z-index: 1;
}

.mybutton_btn__2T16X span a {
text-decoration: none;
}
.mybutton_btndm__2qVqB {
   z-index:0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;

  font-weight: 600;
  font-size: 12px;
  color: var(--primary);
  background-color: rgb(255,255,255,0.6);
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.mybutton_btndm__2qVqB:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.mybutton_btndm__2qVqB span {
  position: relative; 
  z-index: 1;
}

.mybutton_btndm__2qVqB span a {
text-decoration: none;
}
.portalmodal_backdrop__AmORm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.55);
}
.portalmodal_backdropbg__2wBg1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 1);
}
.portalmodal_backdroptwo__3vBTD {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.35);
}
.portalmodal_extramodal__3d5jd {
  top: 10%;
  padding: 20px;
  padding-top:40px;
  overflow-y: auto;
  overflow-x: hidden!important;
  max-height:70vh;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modalbig__1epFJ {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;
  top: 5%;
  width: 90vw;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modalbigdm__3Po4t {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;
  top: 5%;
  width: 90vw;
  background-color: rgb(41, 41, 41);
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modalbig__1epFJ::-webkit-scrollbar {
  display: none;
}
.portalmodal_modalfs__3hOIV{
  padding: 20px;
  padding-top:40px;
  position: fixed;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 100vh;
  width:100vw;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modalbigblack__3c2XC {

  padding: 20px;
  padding-top:40px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90vw;
  background-color: black;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modalbigblack__3c2XC::-webkit-scrollbar {
  display: none;
}
.portalmodal_modalunsichtbar__1Jp_9 {
  padding: 20px;
  padding-top:40px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  background-color: transparent;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_modal__3IBHA {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  left: 5%;

  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
   overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_smallmodal__1HhHD {
  padding: 20px;
  padding-top:40px;
  position: fixed;

  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  min-width: 10%;
   overflow-y: scroll;
    overflow: auto;
  max-height:70vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.portalmodal_closex__1a5tB {
  background: none;
  width: 25px;
  border: none;
}
.portalmodal_closeBtn__1SQdg {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
-webkit-filter:grayscale(1);
        filter:grayscale(1);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 0px;
  margin-right: 0px;
  z-index: 1000;
}

.portalmodal_closeBtn__1SQdg:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.portalmodal_distancer__3pya9 {
  margin: 20px auto;
}
@media (min-width: 768px) {
  .portalmodal_modal__3IBHA {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  .portalmodal_smallmodal__1HhHD {
    left: 45%;
    width: 20%;
  }
}

@-webkit-keyframes portalmodal_slide-down__13w4Z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes portalmodal_slide-down__13w4Z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.portalmodal_okbutton__Sxs5m {
  margin: auto;
}

.redbutton_MyButton__3kws_ {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:1em;
	padding:0.8% 6px;
	text-decoration:none;
	min-width:80px;
    transition:1s;
    margin:0.2%;
}
.redbutton_MyButton__3kws_:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.redbutton_MyButton__3kws_:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    
.redbutton_MyButton__3kws_ {
    font-size:13px;
}
}
.writemessage_size__1_DvZ{
    width:100%;
    height:100%;
   color:#333;
}
.writemessage_textforGirl__20f8a{

    min-height:100px;
    width:98%;
    padding:2%;
    margin:1% 0%;
   
    border-radius:20px;
}
.writemessage_fixit__253Bm{background-color: aqua;}
.writemessage_errmsg__2qE5R {
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius:5px;
}

.writemessage_buttonfield__2N2TY {
    margin: auto;
    padding: 2%;
}
.writemessage_buttonfield__2N2TY > div {
    display: inline-block;
}
.writemessage_buttonfield__2N2TY > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    white-space: nowrap;
}
.writemessage_writeMessageAttachments__u1UHX {
    display: flex;
    width: 100%;
}
.writemessage_writemessage__2DR8p{
    padding:10px;
}
.imageUploader_cont__jvAdx {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageUploader_butonwrapper__1PlR9 {
  width: 80%;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}
.imageUploader_imageholderholder__u3reR {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 20px;
}
.imageUploader_imageholder__Ujpm2 {
  position: relative;
  -webkit-mask: -webkit-gradient(
    linear,
    center top,
    center bottom,
    color-stop(0, rgba(0, 0, 0, 1)),
    color-stop(0.35, rgba(0, 0, 0, 1)),
    color-stop(0.5, rgba(0, 0, 0, 1)),
    color-stop(0.65, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 0))
  );
  padding: 10px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 60vh;

  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.imageUploader_imageholder__Ujpm2::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.imageUploader_userimages__2kGdo {
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
}
.imageUploader_userimages__2kGdo:hover {
  transition: 0.4s;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.imageUploader_flexme__1QRXX {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.imageUploader_wrapper__2tCz2 {
  position: relative;
}
.imageUploader_ix__3vTmX {
  position: absolute;
  width: 20px;
  right: 9px;
  top: 9px;
  z-index: 50;
}
.imageUploader_ix__3vTmX:hover {
  transition: 0.4s;
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  cursor: pointer;
}
.imageUploader_buttons__7VY3g {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

.imageUploader_upload__3JASd{
  margin-top:10px;
  padding:5px;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.imageUploader_profilepic__30jHr{
    max-width:100%;
    height:auto;
   max-height:400px;
    border: 3px solid #efefef;
    background-size:cover;
}
.imageUploader_form__1trUB{
    width:200px;
    
}
.imageUploader_button__2cUOO{
  background-color: var(--primary);
  color:white;
  padding:8px 10px;
  border-radius:8px ;
  cursor:pointer;

}
.imageUploader_button__2cUOO:hover{
  background-color:var(--primaryDark);
  transition:0.7s;
}
.imageUploader_headerupload__3XLMq{
  font-size:1.1em;
}
.imageUploader_uploadedPicture__SyP1H{
  position:relative;
}
.imageUploader_igs__J-er8{position:absolute;top:-3px;right:-3px;width:25px;}
.simplepaymentclasses_textforGirl__2eJ7f {
  min-height: 100px;
  width: 90%;
  padding: 2%;
  margin: 2%;

  border-radius: 20px;
}
.simplepaymentclasses_mySelect__1pQhH {
  z-index: 100;
}
.simplepaymentclasses_otpagb__27jDG{
  color:#333;
  text-decoration:none;
  font-weight:bold;
}
.simplepaymentclasses_head__S6guq {
  text-transform: uppercase;
  border-bottom: 1px solid black;
  margin-bottom: 1em;
  font-size: 19px;
  line-height: 2em;
}
.simplepaymentclasses_highlight__2efYz{
  box-shadow: 1px 1px 9px var(--primary) , -1px -1px 9px var(--primary);
}
.simplepaymentclasses_firstelement__2nqFY {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap:1vw;
  gap:1vw;
  font-size:6vw;
  max-width: 82%;
  margin:auto;
}
.simplepaymentclasses_setapart__1wQk1 {
  color: var(--primary);
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  margin:auto;
  grid-gap:0;
  gap:0;
}
.simplepaymentclasses_entermoney__2cR9Q {
  border: none;
  text-align: right;
  font-weight: bold;
 font-size:6vw;
  color: var(--primary);
  width:70%;

}
.simplepaymentclasses_entermoneylong__1OoL7{border: none;
  text-align: right;
  font-weight: bold;
 font-size:4vw;
  color: var(--primary);
  width:70%;}
.simplepaymentclasses_entermoney__2cR9Q input {
  max-width:-webkit-fit-content;
  max-width:-moz-fit-content;
  max-width:fit-content;
  color: var(--primary);
  padding:0;
  margin:0;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 0;
  margin: 0px;
  color: var(--primary);
}

.simplepaymentclasses_fandollarelement__37BHE {
  width: 5vw;
  height:6vw;
  margin:auto;
}
.simplepaymentclasses_increasbutton__3gSqa {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  line-height: 0.1em;
  z-index: 50;
}

.simplepaymentclasses_wrapme__2-AXi {
  padding: 2%;
  z-index: 1000;
}
.simplepaymentclasses_theoption__1J2wI {
  background-color: #fff;
  margin: 3px;
  font-weight: bold;
  max-width: 100%;
  border: 1px solid var(--primary);
}

.simplepaymentclasses_otpagb__27jDG {
  display: inline !important;
  width: 100% !important;
  margin: 1px !important;
  padding: 1%;
}
.simplepaymentclasses_increasbutton__3gSqa:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.5s;
}

.simplepaymentclasses_iframe__3N-ak {
  min-width: 80vw;
  height: 60vh;
  margin: auto;
}
.simplepaymentclasses_iframeinner__1v8D- {
  width: 70vw;
  margin: 3% auto;
  margin-left: 7vw;
  height: 80vh;
}
.simplepaymentclasses_makebold__3P7F6 {
  font-weight: 600;
}
.simplepaymentclasses_checkbox__2D--O{
  margin-right:5px;
}
.simplepaymentclasses_currency__1mwkh{
  font-size: 6vw;
  font-weight:bold;
  margin-top:4%;
}
.simplepaymentclasses_currencychf__1OwrE{
  font-size: 3vw;
  font-weight:bold;
  margin-top:15%;
}
.simplepaymentclasses_currencylong__2cAAz{
  font-size: 4vw;
  font-weight:bold;
  margin-top:4%;
}
.simplepaymentclasses_currencychflong__3zmCu{
  font-size: 3vw;
  font-weight:bold;
  margin-top:6%;
  
}
.simplepaymentclasses_consentblock__1IspH{
  line-height:1em;
  line-break: auto;
  word-break: normal;
  font-size:0.8em;
}
@media (max-width: 1280px) {
  .simplepaymentclasses_currency__1mwkh{
    font-size: 7vw;
    font-weight:bold;
    margin-top:6%;
  }
  .simplepaymentclasses_firstelement__2nqFY {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    grid-gap:1vw;
    gap:1vw;
    font-size:8vw;
    max-width: 90%;
    margin:auto;
  }
  .simplepaymentclasses_setapart__1wQk1 {
    color: var(--primary);
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin:auto;
    grid-gap:0;
    gap:0;
  }
  .simplepaymentclasses_entermoney__2cR9Q {
    border: none;
    text-align: right;
    font-weight: bold;
    color: var(--primary);
    width:90%;

  }
  .simplepaymentclasses_entermoney__2cR9Q input {
    max-width:-webkit-fit-content;
    max-width:-moz-fit-content;
    max-width:fit-content;
    color: var(--primary);
    padding:0;
    margin:0;
  }
  input[type="number"]::-webkit-inner-spin-button {
    opacity: 0;
    margin: 0px;
    color: var(--primary);
  }

  .simplepaymentclasses_fandollarelement__37BHE {
    width: 6vw;
    height:7vw;
    margin:auto;
  }
  .simplepaymentclasses_increasbutton__3gSqa {
    border: none;
    background-color: transparent;
    color: var(--primary);
    font-weight: bold;
    text-align: center;
    line-height: 0.1em;
    z-index: 50;
  }
  .simplepaymentclasses_consenttext__3frPy{
    font-size:0.9em
  }
}
.simplepaymentclasses_flexcontainer__3jAiB{
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
  grid-gap:10px;
  gap:10px;
}
.simplepaymentclasses_buyelement__3DEwP{
  border:1px solid var(--primary);
  border-radius:8px;
  font-weight:bold;
  color:#555;
  display: flex;
  flex-flow: row;
  padding:8px 15px;
  cursor:pointer;
}
.simplepaymentclasses_num__1Oy48{
  font-size: 1.5em;
  color:var(--primary);
}
.simplepaymentclasses_fandollarimg__1-eSt{
height:1.6em;
margin-top:3px;
}
.simplepaymentclasses_numbig__2AZ_M{
  font-size: 3.5em;
  color:var(--primary);
}
.simplepaymentclasses_fandollarimgbig__vuvh3{
height:3.6em;
margin-top:3px;
}
.simplepaymentclasses_buyelementbig__3Q3FN{
  border:1px solid var(--primary);
  border-radius:8px;
  font-weight:bold;
  color:#555;
  display: flex;
  flex-flow: row;
  padding:16px 30px;
  cursor:pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin:auto;
}
.simplepaymentclasses_flexcon__3Lr3_{
 margin:auto;
}
.simplePaymentBaseModal_selector__tIQxu{
  z-index:200;
}

.simplePaymentBaseModal_theoption__1D1WM{ 
  background-color: #fff;
  margin:3px;
  font-weight:bold;
  max-width: 100%;
  border: 1px solid var(--primary);
}
a{color:#333!important;
font-weight:bold;};

.simplePaymentBaseModal_alertlink__2ttbs{
  color:#333;
  text-decoration: none;
}
.simplePaymentBaseModal_alertlink__2ttbs:a{
   color:#333;
  text-decoration: none;
}
.simplePaymentBaseModal_alertlink__2ttbs:hover{
  color:#333;
}
.simplePaymentBaseModal_icon__3s5UW{
   height: 50px;
  width:-webkit-max-content;
  width:max-content;
  transition: 0.5s;
  cursor: pointer;
  
}
.simplePaymentBaseModal_flexit__2u01_{
grid-gap:5px;
gap:5px;
  display:flex;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.simplePaymentBaseModal_flexittop__3xs0i{
grid-gap:5px;
gap:5px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.simplePaymentBaseModal_iframeinner__3_0ug{
  width:100%;
  height:70vh;
}
.simplePaymentBaseModal_glow__12--1{
  box-shadow: -1px -1px 2px rgba(64, 211, 76, 0.8), 1px -1px 2px rgb(64, 211, 76, 0.8), -1px 1px 2px rgb(64, 211, 76, 0.8),
    1px 1px 2px rgb(64, 211, 76, 0.8);
}
.simplePaymentBaseModal_girlimagebig__2u7Cw{
  width:110%;
  margin-left:-5%;
  margin-top:-5%;
  max-height:160px;
  object-fit: cover;
}
.simplePaymentBaseModal_damodal__3OSDV{
  border:2px solid #666;
}
@media (max-width: 780px) {
  .simplePaymentBaseModal_girlimagebig__2u7Cw{
  width:110%;
  margin-left:-5%;
  margin-top:-5%;
  max-height:100px;
 color:#d2c9d2;
  object-fit: cover;
}
}
.getpaymentauthorization_grid__3Q9Fe {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  grid-gap:5px;
  gap:5px;
  margin-bottom:10px;
}
.getpaymentauthorization_icon__3jjky {
  height: 50px;
  width:-webkit-max-content;
  width:max-content;
  transition: 0.5s;
  cursor: pointer;
   -webkit-filter:grayscale(0.8);
           filter:grayscale(0.8);
}
.getpaymentauthorization_icon__3jjky:hover {
  -webkit-filter:grayscale(0);
          filter:grayscale(0);
  transition: 0.5s;
}
.getpaymentauthorization_girlname__3jcC-{color:var(--primary);font-weight:bold;}
.getpaymentauthorization_centerme__1F9tE{
  margin:auto;
  text-align:center;
  display: flex;
  flex-flow:column;
  justify-content: center;
}
.getpaymentauthorization_elements__Bcf5u{
  
  transition: 1.5s;
  display:flex;
  flex-flow:column;
  overflow:hidden;
  padding:3px;
  border-radius:7px;
}
.getpaymentauthorization_elements__Bcf5u:hover{
    background-color:aquamarine;
    transition: 1.5s;
}
.getpaymentauthorization_clicked__OrcK9{
  background-color:aquamarine;
 
}
.getpaymentauthorization_topdown__3ChnU{
  margin-top:10px;
}
.getpaymentauthorization_topdown__3ChnU h1{
  font-size:1.6em;
}
.getpaymentauthorization_profilePic__2dQO_{
 display: inline-block;
  width: 110px;
  height: 110px;
  border-radius: 50%;
margin-top:-60px;

  object-fit: cover;
  border:3px solid #999999;
}
.getpaymentauthorization_profilename__3HPHv{
  color:var(--primary)
}


@media (max-width: 780px) {.getpaymentauthorization_profilePic__2dQO_{
 display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
margin-top:-60px;
  object-fit: cover;
  border:3px solid #999999;
}
.getpaymentauthorization_topdown__3ChnU h1{
  font-size:1em;
}
.getpaymentauthorization_trinkgeldtext__1_t7u{
  font-size:0.9em;
 
}
}
.choosepaymentoption_image__2gtEs{
   margin: 2px 16px;
   width:70px;
   height:100%;
}
.choosepaymentoption_flexit__2I8jt{
    display: flex;
    flex-flow: row;
grid-gap:20px;
gap:20px;
width:100%;
justify-content: flex-start!important;
}
.choosepaymentoption_flexit2__3ANzm{
    display: flex;
    flex-flow: row wrap;
grid-gap:20px;
gap:20px;
width:100%;
justify-content: space-evenly!important;
}
.choosepaymentoption_addapayment__Q4TA6{
    cursor:pointer;  
    margin:auto;
    background-color: #efefef;
    color:#555;
    font-size:1.0em;
    font-weight:bold;
    
}
.choosepaymentoption_flexit__2I8jt{
    display:flex;
    flex-flow:row wrap;
    justify-content: space-evenly;
}
.choosepaymentoption_erklärbär__2F2aw{font-size:0.9em;}
.choosepaymentoption_choosewrapper__1bkc4{
    margin:auto;
    text-align:center;
    padding:10px;
}
.choosepaymentoption_text__3Ybwn{
     align-self: center;
   margin-top:14px;
}
.choosepaymentoption_flexcontainer__1HaZS{
    display:flex;
    flex-flow: column;
    background-color: #fbfbfb;
    grid-gap:10px;
    gap:10px;
    border:1px solid var(--primary);
    border-radius:8px;
    padding:20px;
}
.choosepaymentoption_flexcontainerdm__2gz8p{
    display:flex;
    flex-flow: column;
    background-color: var(--nearBlack);
    grid-gap:10px;
    gap:10px;
    border:1px solid var(--primary);
    border-radius:8px;
    padding:20px;
}
.choosepaymentoption_secondcontainer__3u-TF{
    margin-top:20px;
    justify-content: space-evenly;
    width:100%;
}
.choosepaymentoption_extraimage__29_lM{
    cursor:pointer;
     transition:0.5s;
}
.choosepaymentoption_extraimage__29_lM:hover{
    box-shadow:2px 3px 6px var(--primary) , -2px -3px 6px var(--primary);
    transition:0.5s;
}
.choosepaymentoption_paymentInfo__28Hsz{
    text-align:center;
    font-size:0.7em;
}
.choosepaymentoption_pseudo__1CB3A{
    color:var(--primary);
    font-weight:bold;
}
@media only screen and (max-width: 950px) {
  .choosepaymentoption_text__3Ybwn{
    font-size:0.7em;
    font-weight:normal;
  }
  .choosepaymentoption_flexit__2I8jt{
    justify-items: left;
    flex-wrap: nowrap;
  }
  .choosepaymentoption_secondcontainer__3u-TF{
    margin-top:0px;
  }
}
.addpaymentmethod_darkBG__3nIAX {
     
 position:fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
}

.addpaymentmethod_centered__2zQyG {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 20;
}

.addpaymentmethod_modal__2FFs4 {
  width: 80vw;
  height: 100%;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:2%;
}
.addpaymentmethod_modalbg__30iCA {
  width: 80vw;
  height: 100%;
  background: rgb(107, 98, 122,1);
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:1%;
}

.addpaymentmethod_modalHeader__3m_BC {
  height: auto;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom:10px;
  padding:1%;
}
.addpaymentmethod_modalHeaderbg__1EYL0 {
  height: auto;
  background: rgb(25, 22, 37,0.3);
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
   padding:1%;
}
.addpaymentmethod_closeBtn__1F0oJ {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:100;
}

.addpaymentmethod_closeBtn__1F0oJ:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: translate(-4px, 4px);
          transform: translate(-4px, 4px);
}
.addpaymentmethod_closex__3C0Eq{
    background: none;
    width:25px;
    border:none;
}
.addpaymentmethod_heading__2Uva8 {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.addpaymentmethod_modalContent__2EQ5Q {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
  padding: 5px;
}

.addpaymentmethod_modalActions__1bw9N {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.addpaymentmethod_actionsContainer__31Ztl {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.addpaymentmethod_deleteBtn__1Wv5B {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.addpaymentmethod_deleteBtn__1Wv5B:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: #ff3e4e;
}

.addpaymentmethod_cancelBtn__2yTDj {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.addpaymentmethod_cancelBtn__2yTDj:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.addpaymentmethod_modal__2FFs4{
  padding:2%;
  color:black
}
.addpaymentmethod_inactive__1C4X4{
  background-color: beige;
  color:#2c3e50;
}
.addpaymentmethod_checkbox__3XyqU{
  margin-right:1%;
  
}


.addpaymentmethod_control__1Z69f {
    margin-bottom: 0.5rem;
  }
  
  .addpaymentmethod_control__1Z69f label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .addpaymentmethod_control__1Z69f input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .addpaymentmethod_actions__vnbtF {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .addpaymentmethod_actions__vnbtF button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .addpaymentmethod_actions__vnbtF button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .addpaymentmethod_actions__vnbtF .addpaymentmethod_toggle__2EGZQ {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .addpaymentmethod_actions__vnbtF .addpaymentmethod_toggle__2EGZQ:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .addpaymentmethod_myButton__2snhH {
	box-shadow: 0px 0px 4px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:1% 2%;
	text-decoration:none;
	text-shadow:0px 2px 0px #662828;
    transition:1s;
    margin:0.2%;
}
.addpaymentmethod_myButton__2snhH:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.addpaymentmethod_myButton__2snhH:active {
	position:relative;
	top:1px;
}
.addpaymentmethod_mandatskasten__6B7ND{
  font-size:0.8rem;
  line-height: 0.8rem;
  border:1px solid black;
padding:1%;
border-radius:5px;
background-color: #ebebeb;
}
.addpaymentmethod_mandatskastendm__iDHgl{
  color:white;
  font-size:0.8rem;
  line-height: 0.8rem;
  border:1px solid black;
padding:1%;
border-radius:5px;
background-color: #2a2525;
}
.addpaymentmethod_consent__2Vvor{
  font-size:0.80rem;
  font-weight: normal;

}
.addpaymentmethod_icon__3_h6o{
  height:50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.addpaymentmethod_flexit__17Gb8{
  display:flex;
  flex-flow:row;
  grid-gap:10px;
  gap:10px;
}
.addpaymentmethod_getNumber__QOpNX{width:80%; margin:auto;}
.addpaymentmethod_smalllogo__20OfU{
  height:30px;
}
.addpaymentmethod_topdown__Ldvmz{
  margin-top:10px;
}
.addpaymentmethod_consenttextsmall__27NBp{
  font-size:0.9em;
}
.addpaymentmethod_consenttext__3vEp9{
  font-size:0.9em;
}
.showPrice_wrapper__1xLF5{
    width:100%;
    padding:5px;
}
.wrapmodal_darkBG__397nj {
    
 position:fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  right:0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
}

.wrapmodal_centered__1glFN {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width:100vw;
  max-height:100vh;
  z-index:30;
  
}
.wrapmodal_centeredfull__3cVvR{
   position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width:90vw;
  min-height:90vh;
  max-width:100vw;
  max-height:100vh;
  z-index:30;

}
.wrapmodal_centereddm__3Ysni {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width:100vw;
  max-height:100vh;
  z-index:30;
  
}
.wrapmodal_centeredfulldm__3hGuI{
  background-color: #030222;
   position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width:90vw;
  min-height:90vh;
  max-width:100vw;
  max-height:100vh;
  z-index:30;

}
.wrapmodal_closex__V4oI1{
    background: none;
    width:25px;
    border:none;
}

.wrapmodal_modal__vSFwR {
  position:relative;
  width:100%;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: white;
  color: white;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}
.wrapmodal_modalfull__1NKNp {
  position:relative;
  width:100vw;
  height:90vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: rgb(255, 255, 255,0.8);
  color: rgb(255, 255, 255);
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.wrapmodal_modaldm__2pntx {
  position:relative;
  width:100%;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: white;
  color: white;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}
.wrapmodal_modalfulldm__uxvkt {
  position:relative;
  width:100vw;
  height:90vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: #030222;
  color: rgb(255, 255, 255);
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}

.wrapmodal_modalHeader__qCH4D {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.wrapmodal_heading__vJ12e {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.wrapmodal_modalContent__2CdGG {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 100;
}

.wrapmodal_modalActions__3GBBa {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.wrapmodal_actionsContainer__1a6qN {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.wrapmodal_closeBtn__2Rw9B {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:100;
}

.wrapmodal_closeBtn__2Rw9B:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: translate(-4px, 4px);
          transform: translate(-4px, 4px);
}

.wrapmodal_deleteBtn__3jFWX {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: var(--primaryBright);
  transition: all 0.25s ease;
}

.wrapmodal_deleteBtn__3jFWX:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 162, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: var(--primaryBright);
}

.wrapmodal_cancelBtn__kTzJU {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.wrapmodal_cancelBtn__kTzJU:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.wrapmodal_modal__vSFwR{
  padding:2%;
  color:black
}
.wrapmodal_inactive__QCV7S{
  background-color: beige;
  color:#2c3e50;
}
.wrapmodal_checkbox__1zgJw{
  margin-right:1%;
}
.wrapmodal_bigimage__3nNw6{
    width:100%;
    max-height:90vh;
}
  
    @media (max-width: 768px) {

      .wrapmodal_centered__1glFN{
        width:90%;
      }
    }
.SuccessMessage_successContainer__1_Kv6{
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
}

.SuccessMessage_mainimage__2uKgX{
    width:100%;
}
.SuccessMessage_distancer__27Nva{
    margin-top:5px;
}
.signup_auth__3pqI0 {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
   
  }
  .signup_flexit__20-kr{
    display:flex;
    justify-content: center;
    grid-gap:5px;
    gap:5px;
  }
  .signup_flexitshort__3nh2Y{
    display:flex;
    justify-content: space-around;
    grid-gap:5px;
    gap:5px;
  }
  .signup_auth__3pqI0 h1 {
    text-align: center;
    color: white;
  }
  .signup_facebookbutton__2xI0T{width:30px; height:30px;}
  
  .signup_control__9D2UG {
    margin-bottom: 0.5rem;
  }
  
  .signup_control__9D2UG label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .signup_control__9D2UG input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .signup_actions__3PHWe {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signup_actions__3PHWe button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  .signup_actions2__2rTEO button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .signup_actions__3PHWe button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .signup_actions__3PHWe .signup_toggle__2gdo5 {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  .signup_actions2__2rTEO .signup_toggle__2gdo5 {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .signup_actions__3PHWe .signup_toggle__2gdo5:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .signup_white__23_xb{
    color:#fff;
  }
.uploadpicture_upload__1JyB2{
    color:white;
}
.uploadpicture_profilepic__2z2Q5{
    max-width:100%;
    height:auto;
   
    border: 3px solid #efefef;
    background-size:cover;
}
.uploadpicture_form__2aRAf{
    width:200px;
}

.uploadpicture_uploadedVideo__2quBl{
  position:relative;
}
.uploadpicture_igs__27MLv{position:absolute;top:-3px;left:-3px;width:25px;}

.uploadpicture_loadingspinner__24cMQ{
    position:absolute;
      top: 50%;
  left: 50%;
}
.postCounter_flexitouter__2_s_n{
width:100%;
    display:flex;
    flex-flow: row;
    flex-wrap: wrap;
    text-align: right;
    justify-content: right;
    grid-gap:8px;
    gap:8px;
}
.postCounter_icon__3sMUg{
    width:15px;
    height:15px;
    margin-top:3px;
}
.postCounter_flexitinner__3HyU3{
    display:flex;
    flex-flow: row;
    flex-wrap: wrap;
   
    grid-gap:5px;
   
    gap:5px;
}
.postCounter_bolder__2j1oe{

font-size:0.7em;
}
.firsttime_box__15v9z{
    padding:2%;
    margin:auto;
    width:100%;
    text-align:center;
}
.firsttime_boxdm__2MgbF{
    color:white;
    padding:2%;
    margin:auto;
    width:100%;
    text-align:center;
}
.firsttime_header__3ikMz{
    text-transform: uppercase;
    border-bottom:1px solid black;
    margin-top:2rem;
    margin-bottom:2rem;
}
.firsttime_header__3ikMz a{text-decoration:none; color:var(--primary)}
.firsttime_header__3ikMz {text-decoration:none; color:var(--primary)}
.firsttime_header__3ikMz:hover{text-decoration:none; color:var(--primary)}
.firsttime_text__1hwnM{
    text-align: left;
}
.firsttime_avsimage__1gr0A{
    width:100%;
    max-width:500px;
}
.firsttime_avsimagemobile__2J_Vl{
    width:100%;
    opacity:0.4;
    position:relative;
}
.bluebutton_MyButton__1-ix4 {
	box-shadow: 0px 0px 2px 0px #101335;
	
	background-color:#101335;
	border-radius:10px;
	border:1px solid #101335;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	
	text-decoration:none;
	width:100%;
    transition:1s;
    margin:0.2%;
    font-weight:bold;
    padding:5px;
    margin-left:0.4%;
    margin-right:0.6%;
    
}
.bluebutton_MyButton__1-ix4:hover {
	 transition:0.5s;
	background-color:#282c59;
    border:1px solid #282c59;
  box-shadow: 0px 0px 4px 0px #282c59;
}
.bluebutton_MyButton__1-ix4:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    
.bluebutton_MyButton__1-ix4 {
    font-size:13px;
}
}
.searchfortags_control__3oO9C{
    width:100%;
    margin:auto;
    display:flex;
    justify-content: space-evenly;
    scroll-snap-align: start;
}
.searchfortags_input__3TRVq{
   padding:1%;
     font-size: 16px;
     border-width: 1px;
     border-color: #CCCCCC;
     background-color: #FFFFFF;
     color: #000000;
     border-style: hidden;
     border-radius: 8px;
     box-shadow: 0px 0px 5px rgba(66,66,66,.75);
     text-shadow: 0px 0px 1px rgba(66,66,66,.75);
   width:80%;
   margin-right:1%;
}
input:focus{
     outline:none;
}
.searchfortags_MyButton__2hxMy {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:1% 2%;
	text-decoration:none;
	
    transition:1s;
    margin:0.2%;
}
.searchfortags_MyButton__2hxMy:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.searchfortags_MyButton__2hxMy:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
  
.searchfortags_MyButton__2hxMy {
    font-size:13px;
}
.searchfortags_control__3oO9C{
    width:97.5%;}
}
.privacy_content__aj9op{
    padding:2%;
}
.privacy_header__2E4S_{
   margin-top: 2%;
   margin-bottom:2%;
   border-bottom:1px solid black;
}
.privacy_inputquestion__2UD9B{
    width:100%;
    min-height:20vh;
}
.privacy_inputmail__34WaV{
    width:100%;
    
}
.privacy_form__27uMh{
    margin:2%;
    padding:2%;
    border-radius:10px;
}
.privacy_form__27uMh input{
    margin-bottom: 1%;;
}
.privacy_headline__25sOs{
    font-weight:bold;
}
.agb_content__3sGmi{
    padding:2%;
}
.agb_header__20yBy{
   margin-top: 2%;
   margin-bottom:2%;
   border-bottom:1px solid black;
}
.agb_inputquestion__1dzja{
    width:100%;
    min-height:20vh;
}
.agb_inputmail__2TaGg{
    width:100%;
    
}
.agb_form__12Uq-{
    margin:2%;
    padding:2%;
    border-radius:10px;
}
.agb_form__12Uq- input{
    margin-bottom: 1%;;
}
.agb_bold__1w1pP{
    font-weight:bold;
}
.detail_head__3u-BO{
    margin-top: 1em;
    text-align: center;
}
.detail_bigbutton__2ubrh {
  font-size:1.8rem;
  font-weight:bold;
  color: #fff !important;
  width:100%;
  text-align:center;
  display:block;
  padding:20px;
  background:var(--primary);
  margin:10px 0;
  border-radius:50px;
  cursor: pointer;
  transition:0.25s;
  } 
 
  .detail_bottombouncer__2YdXW{
    height:10vh;
   
  }
  .detail_wrapme__2y-xk{
    margin:10px;
  }
  
.detail_bigbutton__2ubrh:hover {
  background:#04003b;
  } 
  .detail_centerme__xc56I{
    margin:10px auto;
    width:100%;
    text-align:center;
  }
  .detail_bottombouncer__2YdXW{
    height:10vh;
  }
.displaySub_pad__1Ar3o{
    padding:1%;
    z-index:0;
   scroll-snap-type: none;
}
.displaySub_padshort__1zBOx{
   padding:1%;
    margin:1%;
}
.displaySub_userinfo__1axj_ {
  z-index:0;
  background-color: #fff;
  border:1px solid #eeeeee;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#000;
}
.displaySub_container__1X8jn{
    z-index:0;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  color:#000; 
  margin:1%;
  padding:10px;
  width:98%;
 
}
.displaySub_header__cTNj1{
    text-align:center;
    width:100%;
    font-weight:bold;
    font-size:1.3em;
    
}
.displaySub_catoAc__3n7Jl{
    margin-top:8px;
    margin-bottom:6px;
    width:100%;
    cursor:pointer;
    text-align:center;
    margin:auto;
    font-weight:bold;
    font-size:1.5em;
    color:white;
}
.displaySub_innerWrapper__NemZI p{
    margin-bottom:2px;
}
.displaySub_wrapper__2vepx{
    background-color: var(--primary);
    border:3px solid var(--primary);
    border-radius:10px;
   max-width:100%;
    margin:6px;
    padding:0;
     transition:0.5s;
      scroll-snap-align: start;
}
.displaySub_wrapper__2vepx:hover{
    background-color: #810202;
    border:3px solid #810202;
    transition:0.5s;
    box-shadow: 0px 0px 20px 10px rgba(200,50,50,0.19), 0 6px 6px rgba(200,50,50,0.23);  
}
.displaySub_innerWrapper__NemZI{
    background-color: rgb(255, 255, 255);
    border-radius:10px;
    width:100%;
    margin:0;
    padding:10px 6px;
    
}
.displaySub_initialPrice__16rqk{
    margin-top:10px;
   font-weight:bold;
    text-align: center;
    
}

.displaySub_content__3ssRJ {
   z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;

}
.displaySub_header__cTNj1{
    width:100%;
    border-bottom:1px solid black;
    text-transform: uppercase;
    font-weight: 600;
    min-height:5em;
    display:flex;
   align-items: center;
   justify-content: center;
}

.displaySub_thetiers__38IbB{
margin-top:10px;
 width:100%;
 
}
.displaySub_thelist__ye82e{
  
    list-style: none;
    width:100%;
   
    border-radius:10px;
    margin: auto;
    }
    .displaySub_thelistdm__3NMex{
   background-color: #504E64;
    list-style: none;
    width:100%;
    
    border-radius:10px;
    margin: auto;
    }
.displaySub_firstline__q6Tie{
    font-weight:bold;
   
   
}
.displaySub_priceline__2L0Yg{
    margin-top:20px;
    font-weight: bold;
}
.displaySub_thebutton__Xk7Ry{
    margin-left:20px;
   
}
.displaySub_mobilelist__2zMgU{
    list-style: none;
    margin:none;
    padding:2%;
    border:1px solid black;
    border-radius:10px;
    width:100%;
}
.displaySub_mobilelist__2zMgU li{
    margin-left:4px;
    line-height: 0.8em;
}
.displaySub_specialcontainer__2PzrO{
   
}
.displaySub_specialofferheader__1WzNu{
    font-size:16px;
    font-weight:bold;
   
}
.displaySub_specialoffer__1iGXW{
    font-size:16px;
}
.displaySub_specialprice__3Mgsj{
    text-align:center;
    font-weight:bold;
}
.displaySub_smallprice__oei1F{
    font-size:14px;
}
.displaySub_kuendigung__2y-we{
    font-size: 0.8em;
}
.displaySub_containertrans__16HSe{
    z-index:0;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  color:#000; 
  margin:1%;
  padding:0px;
  width:98%;
}
.displaySub_extrahead__1ZCP0{
    text-align:center;
}
.displaySub_extraheaddm__3KVWu{
    color:white;
      text-align:center;
}
.managesubs_hotte__39dqI{
    display:none;
}
.managesubs_otpagb__s7o7t{
    color:#222;
    font-weight:bold;
}
.managesubs_modaltitle__2UmS-{
    margin-bottom:15px;
    font-weight:bold;
    
}
.managesubs_textblock1___XSzv{
    margin-bottom:8px;
}
.managesubs_textblock2__3VmtY{
    margin-top:8px;
    font-size:0.9em;
}
.managesubs_flexit__REjk-{
grid-gap:5px;
gap:5px;
  display:flex;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.managesubs_theoption__1bKI6{
    max-width:100%;
}
.managesubs_epochalert__1r-NP{
    font-weight:bold;
}
.managesubs_flexitwrapper__2_Ugi{
  padding:1% 4%;
    border:1px solid gray;
    border-radius:8px;
}
.managesubs_icon__30hL2{
    height:80px;
}

.managesubs_profilepic__3dmlQ{
    display: inline-block;
  width: 110px;
  height: 110px;
  border-radius: 50%;

  object-fit: cover;
  border:3px solid #999999; 
  justify-self: center;
}
.managesubs_profilepicmob__zkTi7{
    display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 50%;

  object-fit: cover;
  border:3px solid #999999; 
  justify-self: center;
}
.managesubs_bold__34R55{
    font-weight:bold;
}
.managesubs_selector__2qK9k{
    margin-top:10px;
}
.managesubs_checkboxtext__DdmaZ{
    font-size:0.9em;
}
.managesubs_checkbox___UkUp {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  color:var(--primary);
  width: 1.15em;
  height: 1.15em;
  border: 0.25em solid var(--primary);
  border-radius: 0.25em;
}
.managesubs_buybtn__2I54R{
    margin:auto;
    display:flex;
    justify-content: center;
}
.managesubs_checkbox___UkUp{
    color:var(--primary);
}
.managesubs_pseudo___uzJ7{
    color:var(--primary);
    font-weight:bold;
}
.managesubs_consentElement__3OExb{
    font-size:0.9em;
    line-height:0.98em;
}
.managesubs_centerme__16yam{display: flex;justify-content: center;}
.managesubs_infobutton__2nxpp{
    background-color: transparent;
    color:var(--primary);
}
.managesubs_infobuton__29zX1:hover{
    background-color: var(--primary); color:white;
}
.subscriptionammager_girlimagebig__3tId1{
     width:110%;
  margin-left:-5%;
  margin-top:-5%;
  max-height:160px;
  object-fit: cover;
}
.isLiveGirl_element__3ujM3{
border-radius:20px;
border: 2px solid white;
display:flex;
flex-flow:row;
justify-content: space-around;
padding:5px;
width:98%;
margin:1%;
background-color: rgb(80, 78, 100,0.8);
}
.isLiveGirl_mobelement__28M0C{
    border-radius:20px;
border: 2px solid white;
display:flex;
flex-flow:column;
justify-content: center;
margin:auto;
padding:10px;
background-color: rgb(80, 78, 100,0.8);
scroll-snap-align: start;
}
.isLiveGirl_image__32P-t{
max-height: 200px;
border-radius:10px;
max-width:100%;
margin:auto;
}
.isLiveGirl_therow__2QZGP{
    width:100%;
margin:auto;
}
.isLiveGirl_centerme__AWWh4{
    margin:auto;
}
.isLiveGirl_text__2lzHn{
    width:100%;
    text-align:center;
color:white;
text-decoration:none;
font-size: 0.95em;
}
a{
    color:white;
    text-decoration:none;
}

.nextButton{
    position:absolute;
    right:10px;
    top:10px;
    z-index: 900;
}
.wrapper{
  
    overflow:auto;
}
.loadbutton{
    width:100%;
    margin:auto;
}
.postCard_timelineold__1LSQs {
  padding: 1%;
  background:#f1f1f1;
  width:100%;
  margin-left:0.2%;
  margin-right:1.5%;
  margin-bottom:1.5%;
  font-size:0.8em;
  border-bottom:1px solid #888888;
  }
  
.postCard_timeline__1YwHj {
  padding: 1%;
  background:#fff;
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 6px 6px 0px 0px;
  box-shadow:0px 4px 7px 2px  #e5e5eb;
}
.postCard_timelinedm__19eS5 {
  padding: 1%;
  background:rgb(255,255,255,0.3);
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 6px 6px 0px 0px;
  box-shadow:0px 4px 7px 2px  #27272d;
  
}
.postCard_myhr__1IO5W{
  margin-bottom:5px;
  margin-top:5px;
}
.postCard_testit__3ChBb{
  height:200px;
}
.postCard_timelinebg__1rtXq {
  padding: 1%;
  background:rgb(32, 32, 32);
  color:#666;
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 9px 9px 0px 0px;
  box-shadow:0px 4px 7px 2px  #e5e5eb;
 
}
.postCard_timelinedmbg__1usp7 {
  padding: 1%;
  background:rgba(67, 60, 60, 0.8);
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 9px 9px 0px 0px;
  box-shadow:0px 4px 7px 2px  #27272d;
  color:aliceblue;
  z-index:50;
}
.postCard_pins__3CUsg{
width:40px;
cursor:pointer;

}
.postCard_pinsbright__1nPqU{
width:40px;
cursor:pointer;
-webkit-filter:opacity(0.4);
        filter:opacity(0.4);

}

.postCard_bounce__1Edyq {
  -webkit-animation-name: postCard_bounce__1Edyq;
          animation-name: postCard_bounce__1Edyq;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  
}
.postCard_reportbtn__2l587{
  margin-top:5px; 
  width:100%;
  
 
}
.postCard_mybutton__gnYoQ{
background-color: rgb(220, 210, 210,0.1);
  border-radius:10px;
  padding:3px;
  border:none;
   color:#888;
   font-size:0.8rem;
   float:right;
}

@-webkit-keyframes postCard_pulsate__kM7jk {
  0%, 100% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 1;
  }
}

@keyframes postCard_pulsate__kM7jk {
  0%, 100% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 1;
  }
}

@keyframes postCard_bounce__1Edyq {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px) rotate(-45deg);
            transform: translateY(-100px) rotate(-45deg);
  }
30%{
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-5deg);
            transform: translateY(30px) rotate(-5deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-5deg);
            transform: translateY(-10px) rotate(-5deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
  }
}
.postCard_myBounceDiv__as-mP { 
        -moz-animation:postCard_bounce__1Edyq .40s linear;  
        -webkit-animation:postCard_bounce__1Edyq .40s linear; 
}

@-webkit-keyframes postCard_bounce__1Edyq {
        0%{ -webkit-transform:scale(0); opacity:0;}
        50%{ -webkit-transform:scale(1.3); opacity:0.4;}
        75%{ -webkit-transform:scale(0.9); opacity:0.7;}
        100%{ -webkit-transform:scale(1); opacity:1;}
}


  .postCard_new__238Tf{
    color:var(--primary);
  }
  .postCard_timelinecontentnew__SXYgP{
    border:2px solid var(--primary)!important;
  }
.postCard_timelinetitle__2ospS {
 
  color: #04003b;
  cursor: pointer;
  text-decoration: none;
}
  
.postCard_timelinetitle__2ospS a {
  color: #04003b;
  cursor: pointer;
  text-decoration: none;
}
  
.postCard_timelinetitle__2ospS a:hover {
  color: var(--primary);
  cursor: pointer;
}  
  
.postCard_timelinedate__1BfAM {
  color: #333333;
  text-align:right;
}
.postCard_timelinedatedm__XMUvD {
  color: aliceblue;
  text-align:right;
}
  
.postCard_timelinecontent__2brRG {
  padding: 0 20px 20px;
  font-size: 1.2em;
}
  
.postCard_timelinecontent__2brRG .postCard_heading__2RHeP {
  font-size: 1.3em;
  font-weight:700;
} 
.postCard_timelinecontent__2brRG .postCard_subheading__9nGjQ {
  font-size: 1em;
  font-weight:500;
}  
  
.postCard_timelinecontent__2brRG a{
  color: var(--primary); 
  cursor: pointer;
  text-decoration: none;
}
  
.postCard_timelinecontent__2brRG a:hover {
  color: #04003b;
  cursor: pointer;
} 
  
.postCard_timelinecontent__2brRG img {
  border-radius:5px;
  
} 

  
.postCard_timelinemenu__kV9BR {
  border-bottom:1px solid #b0afbb;
  display: flex;
  align-items:center;
  padding:10px 0px;
  text-decoration: none;
   justify-content: space-between;
  
}

  
.postCard_timelinemenuitem__RDy5V {
  text-decoration: none;
  color: #ccc8db;
  padding: 10px 14px;
  line-height: 0.7;
  font-weight: 500;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #ccccd3;
 
}
  
.postCard_timelinemenuitem__RDy5V:hover {
  background-color: #2e2e40;
  color: #fff !important;
}
  
.postCard_bigbutton__2zx8c {
  font-size:1.8rem;
  font-weight:bold;
  color: #fff !important;
  width:100%;
  text-align:center;
  display:block;
  padding:15px;
  background:var(--primary);
  margin:10px 0;
  border-radius:50px;
  cursor: pointer;
  transition:0.25s;
  text-decoration: none;
} 
  
.postCard_bigbutton__2zx8c:hover {
  background:#04003b;
} 
.postCard_imageElement__1nifs {
  width: auto;
  display:block;
  margin:auto;
}

.postCard_smallMediaPreview__1Be5O {
 font-size: 10px;
}
.postCard_contentimage__jiTPA{
  max-width: 20px;
  max-height: 20px;
}
.postCard_clickable__3ZrGK{
  cursor:pointer;
}
.postCard_myimageelement__3f8nF{
  height:100%;
  width:auto;
  max-width:100%;
  max-height:50vh;
  margin:auto;
  transition:0.4s;
}
.postCard_marginme__2q7PE{
  display:flex;
  margin:auto;
}
.postCard_smaller__1FYJw{
  margin:auto;
  max-width:600px;
  padding: 3%;
  background-color: #dcdcdc;
}
.postCard_tag__3Y60n span{
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.postCard_tagcolor1__2WFlk {
  background-color: var(--primary);
}
.postCard_tagcolor2__2NtYw {
  background-color: #04003b;
}
.postCard_likeHerz__24-jY{
  width:20px;
  cursor:pointer;
}
.postCard_unlikeHerz__qkN7B{
  width:20px;
  -webkit-filter:grayscale(1);
          filter:grayscale(1);
  cursor:pointer;
}
.postCard_likeit__3sZCv{
color:#666;
cursor:pointer;
margin-left: auto;
}
.postCard_liketext__3t_Ke{
  align-self: end;
  margin-left:5px;
}
.postCard_morebtn__1HtyD{
  color:var(--primary);
  cursor:pointer;
}
.postCard_mailicon__iUhUx{
  width:30px;
  margin:-10px 5px ;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}
.postCard_toright__2n6II{
  margin-left:93%;
}
.postCard_mobicon__2Xd91{
  
  height:30px;
  
}
.postCard_timelinemobile__2QOEH{

  display:flex;
justify-content: space-evenly;
grid-gap:10%;
gap:10%; 
margin-bottom:1%;
margin-top:1%;
}

.postCard_mobiconunlike__2sUvD{
  -webkit-filter:grayscale(1);
          filter:grayscale(1);
  height:30px;
}
.postCard_rowpinned__2f4Pt{
  margin:2%;
 background-color: #dcc3c3;
}
.postCard_rowpinneddm__248nW{
  margin:1%;
 background-color: rgb(172, 150, 150,0.5);
}
.postCard_pinnedpost__ad51z{margin:auto;width:100%; text-align: center;}
.postCard_showcommentbtn__JqbTq{
  border:none;
  background:rgba(155, 96, 96, 0.1);
}
.postCard_showcommentbtn__JqbTq:hover{
  font-weight:bold;
  background-color:rgb(155, 96, 96,0.4);
  transition:.2s;
}

.postCard_showcommentbtndm__27lGe{
  border:none;
  background:rgba(203, 127, 127, 0.2);
  color:white;
}
.postCard_showcommentbtndm__27lGe:hover{
  font-weight:bold;
  background-color:rgb(203, 127, 127,0.4);
  transition:.2s;
}
.postCard_killit__k0Uq3{
  text-decoration:line-through;
  color:var(--primary);;
}
@media only screen and (max-width: 778px) {

  .postCard_timelinebg__1rtXq,.postCard_timelinedmbg__1usp7{
    margin-top:20%;
     color:#999;
  }

}
.smallimageElement_styles__14fJI{
    height:100%;
    width:100%;
}
.smallimageElement_myimageelement__Ym4TN{
    margin-top:5px;
    border-radius:5px;
    margin:5px;
    overflow:hidden;
    max-width:100%;
    justify-content: center;
}
.smallimageElement_errormessage__2pyhV{
    width:90%;
    font-size:0.9em;
    color:var(--primary);
    text-align:center;
}
.smallimageElement_errorContainer__1gm2u{
    display:flex;
    flex-flow: column;
    justify-content: center;
}
.showimagemodal_darkBG__2Glpz {
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.7);
}

.showimagemodal_centered__ljoPg {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 100vw;
  max-height: 100vh;
  z-index: 20;
}

.showimagemodal_modal__gL85S {
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.7);
  padding: 2%;
  overflow: hidden;
}

.showimagemodal_modalHeader__3cbUc {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.showimagemodal_heading__37kzM {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.showimagemodal_modalContent__1R6Sd {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
}

.showimagemodal_modalActions__2z_gg {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.showimagemodal_actionsContainer__A8tFx {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.showimagemodal_closeBtn__CXBtB {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.showimagemodal_closeBtn__CXBtB:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: translate(-4px, 4px);
          transform: translate(-4px, 4px);
}

.showimagemodal_deleteBtn__3-S66 {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.showimagemodal_deleteBtn__3-S66:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: #ff3e4e;
}

.showimagemodal_cancelBtn__2Emkz {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.showimagemodal_cancelBtn__2Emkz:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.showimagemodal_modal__gL85S {
  padding: 2%;
  color: black;
}
.showimagemodal_inactive__3bI21 {
  background-color: beige;
  color: #2c3e50;
}
.showimagemodal_checkbox__1Meob {
  margin-right: 1%;
}
.showimagemodal_bigimage__Ops84 {
  max-width: 100%;
  max-height: 100%;
  
margin:auto;


}
.showimagemodal_wrapme__1YZpl{
  display:flex;
  justify-content: center;
  max-height:90vh;
  max-width:90vw;
}
@media (max-width: 768px) {
  .showimagemodal_centered__ljoPg {
    width: 90%;
  }
}

.scrollimage_wrapper__12ZPh{
    margin:auto;
    overflow:hidden;
    position: relative;
    z-index:0;
    max-width:100%;
    max-height:100%;
    display:flex;
    justify-content: center;
}

.testpostcard_maxwidth__1jqa7 {
  width: 100%;
}

.testpostcard_element__MBinl {
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.testpostcard_elementinner__1xuKa {
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.testpostcard_mobelementouter__1GT9h {
  width: 100%;
}
.testpostcard_mobelement__ZNaVi {
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-backdrop-filter: blur(14px) grayscale(0.7);
          backdrop-filter: blur(14px) grayscale(0.7);
}
.testpostcard_killit__1J6SB {
  text-decoration: line-through;
}
.testpostcard_rightside__35HBU {
  text-align: right;
  margin-right: 5px;
}
.testpostcard_mobelementinner__TqbFz {
  max-width: 100vw;
  max-height: 90vh;
  position: relative;
  -webkit-filter: blur(0);
  filter: blur(0);
  margin: auto;
  border: 2px solid white;
  scroll-snap-align: start;
}
.testpostcard_maincontentcontainer__55TQz {
  margin: 10px;
  padding: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid #22222220;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}
.testpostcard_pinned__2NJXU {
  background-color: var(--primary)05;
  margin: 10px;
  padding: 5px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.testpostcard_pinneddm__KlFZ9 {
  background-color: #301010;
  color: #efefef;
  margin: 10px;
  padding: 10px;
  border: 1px solid #272540;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}
.testpostcard_maincontentcontainernew__2f8bT {
  background-color: #ffffff;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 2px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.testpostcard_maincontentcontainernewduenn__3Awiz {
  background-color: #ffffff;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
/* DARKMODE CSS */
.testpostcard_maincontentcontainerdm__Winwe {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding: 10px;
  border: 1px solid #272540;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}

.testpostcard_maincontentcontainernewdm__2qdE0 {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px #000000;
  border: 2px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.testpostcard_maincontentcontainernewdmduenn__3uvHP {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px #000000;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}

.testpostcard_newdm__12flE {
  text-align: right;
  padding: 5px;
  margin-right: 10px;
  color: var(--primary);
  position: relative;
}

.testpostcard_pseudonamedm__jKzU- {
  color: var(--primary) !important;
}
/* DARKMODE CSS  - End */

.testpostcard_new__1SyBn {
  text-align: right;
  padding: 5px;
  margin-right: 10px;
}

.testpostcard_newcontainer__1ECsF {
  margin-bottom: 3px;
  width: 100%;
}

.testpostcard_pseudoname__3u4f9 {
  color: var(--primary);
  font-weight: bold;
}

.testpostcard_showpos__2v3NH {
  position: absolute;
  z-index: 250;
  color: var(--primary);
  font-weight: bold;
  top: 10px;
  right: 10px;
}
.testpostcard_fallbackimage__3bOzq {
  width: 100%;
}
.testpostcard_texttoright__2fOpr {
  text-align: right;
  color: grey;
}

.testpostcard_likecontainer__1icmy {
  position: relative;
  cursor: pointer;
}
.testpostcard_linkinner__3LJA8 {
  color: #fff !important;
  text-decoration: none;
}
.testpostcard_headerimage__1-PxG {
  width: 104%;
  margin-left: -2%;
  margin-top: -2%;
  max-height: 160px;
  object-fit: cover;

  object-position: top center;
}
.testpostcard_likenumber__3O3mi {
  position: absolute;
  z-index: 5;
  width: 50px;
  top: 3px;
  display: flex;
  color: var(--primary);
  font-weight: bold;
  justify-content: center;
}

.testpostcard_likenumberbig__1LK5X {
  position: absolute;
  z-index: 5;
  top: 5px;
  left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 0.7em;
}

.testpostcard_likenumbermiddle__2ucZb {
  position: absolute;
  z-index: 5;
  top: 3px;
  left: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 0.8em;
}

.testpostcard_icon__3VBjN {
  width: 50px;
  transition: 0.5s;
  cursor: pointer;
}

.testpostcard_icongray__2qOcc {
  width: 50px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  transition: 0.5s;
  cursor: pointer;
}

.testpostcard_flexit__1mBgV {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.testpostcard_flexitright__1mrhS {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  grid-gap: 20px;
  gap: 20px;
}

.testpostcard_subheading__ixcti {
  font-size: 1em;
  font-weight: 500;
  padding-left: 2%;
}

.testpostcard_heading__15Q5C {
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 20px;
}
.testpostcard_headingshort__393IF {
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 10px;
}
.testpostcard_headingmedium__3AAHj {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 10px;
}
.testpostcard_headinglong__FNSyz {
  font-size: 1em;
  font-weight: 700;
  margin-top: 0px;
}

.testpostcard_topaywrapper__1_ZHZ {
  position: relative;
  width: 100%;
  top: 20%;
}

.testpostcard_topaytext__2dFZO {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: bolder;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 1.6em;
}

.testpostcard_topaytext__2dFZO h5 {
  margin-bottom: 50px;
}

.testpostcard_topaybg__1Ods- {
  margin: auto;
  -webkit-filter: grayscale(0.6);
          filter: grayscale(0.6);
  object-fit: cover;
  display: flex;
  justify-content: center;
}

.testpostcard_marker__NGOJB {
  margin-left: 4px;
}

.testpostcard_iconsm__2Flyf {
  height: 15px;
  margin-top: 5px;
}

.testpostcard_flexitsm__2T8za {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.testpostcard_showcommentbtn__2XSQQ {
  width: 100%;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: var(--primary);
  background-color: #fafbfc;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.testpostcard_showcommentbtn__2XSQQ:hover {
  color: #ffffff;
  background-color: var(--primary);
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
  transition-duration: 0.1s;
}

.testpostcard_showcommentbtndm__iZyre {
  width: 100%;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #ffffff;
  background-color: #1a184b;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.testpostcard_showcommentbtndm__iZyre:hover {
  color: #ffffff;
  background-color: var(--nearBlack);
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
  transition-duration: 0.1s;
}

.testpostcard_reportbtn__2ljUi {
  margin: 5px;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: 1px solid #33333350;
  font-weight: 600;
  background: #fff;
  padding: 0 8px 1px;
  font-size: 12px;
  border-radius: 4px;
  color: rgb(29, 28, 29);
  height: 22px;
  min-width: 56px;
  transition: all 80ms linear;
}

.testpostcard_reportbtn__2ljUi:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  background: rgba(248, 248, 248, 1);
}

.testpostcard_extramessage__EbPwj {
  text-align: center;
  margin: 10px;
  background: bisque;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  color: var(--primary);
}

.testpostcard_pagination__22FS9 {
  display: flex;
  flex-flow: row;
  margin: auto;
  justify-content: center;
  grid-gap: 6px;
  gap: 6px;
  font-size: 2.8em;
  border: 1px solid rgb(245, 245, 220, 0.3);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 6px;
  border-radius: 12px;
  background-color: rgb(245, 245, 220, 0.3);
  line-height: 0.1em;
}

.testpostcard_böpl__3uCWd {
  cursor: pointer;
  margin-top: 8px;
}

.testpostcard_active__2-u9r {
  color: var(--primary);
  text-shadow: -1px -1px 2px rgb(200, 50, 50, 0.4),
    1px -1px 2px rgb(200, 50, 50, 0.4), -1px 1px 2px rgb(200, 50, 50, 0.4),
    1px 1px 2px rgb(200, 50, 50, 0.4);
}

.testpostcard_iconliste__Yph2m {
  display: flex;
  align-content: space-around;
  min-height: 50px;
  background: color #efefef;
  margin: 0 5px;
  border-radius: 10px;
}
.testpostcard_distancer__8ZU0U {
  margin: 5px 0px;
}
.testpostcard_distancerdown__2U_Ui {
  margin-top: 10%;
}
.testpostcard_iconmed__1-uAQ {
  width: 35px;
  cursor: pointer;
}
.testpostcard_dropdown__1Cp8D {
  padding: 10px 0px;
}
.testpostcard_profilePic__3eiTC {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-self: center;
  object-fit: cover;
  border: 3px solid var(--primary);
}
.testpostcard_dropdowntext__3nY3F {
  word-break: break-word;
}
.testpostcard_profileWrapper__2NSmu {
  position: relative;
  display: grid;
  place-content: center top;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: -5px;
}

.testpostcard_profileWrapper__2NSmu > * {
  grid-area: 1/1;
}
.testpostcard_profileText__2Jq6R {
  position: absolute;

  top: 86px;
  line-height: 0px;
  background-color: rgb(255, 255, 255, 0.7);
  padding: 8px;
}
.testpostcard_profileTextdm__3qpXJ {
  position: absolute;

  top: 86px;
  line-height: 0px;
  background-color: rgb(4, 2, 34, 0.7);
  padding: 8px;
}

@media only screen and (max-width: 780px) {
  .testpostcard_profilePic__3eiTC {
    width: 80px;
    height: 80px;
    margin-top:5px;
  }
  .testpostcard_headingshort__393IF {
    font-size: 1.1em;
    font-weight: 700;
    margin-top: 10px;
}
.testpostcard_pseudoname__3u4f9{
  font-size:0.8em;
  margin-left:-5px;
}
.testpostcard_profileText__2Jq6R {
background-color: transparent;
}
.testpostcard_topaytext__2dFZO{
  margin-top:12%;
}
.testpostcard_distancerdown__2U_Ui{
  margin-top:5%;
}
.testpostcard_topaywrapper__1_ZHZ{
  margin-top:5px;
}
}

.videopreview_single__1RLUc{
    
    display: flex;
  flex-direction: row;
}
.videopreview_singleimg__2uMea{
    width:20%;
    padding:3px
}
.videopreview_centerme__1dhZ1{
    position: absolute;
        top: 50%;
        left: 50%;
          -webkit-transform: translate(-50%,-50%);
                  transform: translate(-50%,-50%);
          width:100%;
          height:100%;
}
.videopreview_errormessage__QAso-{
  font-size:0.9em;
  color: var(--primary);
  text-align: center;
  padding:0% 5%;
}
.videopreview_bigimage__2WBsh{
   
    width:100%;
    margin: auto;
    cursor:pointer;
    padding:3px;
   
   
}
.videopreview_videoPrev__KpssF{
max-width:100%;

}.videopreview_bigimageMobile__3QDox{
    max-width:100%;
     max-height:100%;

    margin: auto;
    cursor:pointer;
    padding:3px;
     position: relative;
        top: 50%;
        left: 50%;
          -webkit-transform: translate(-50%,0%);
                  transform: translate(-50%,0%);
         
}
/* .bigimageMobile{
    max-width:100%;
     max-height:100%;

    margin: auto;
    cursor:pointer;
    padding:3px;
     position: absolute;
        top: 50%;
        left: 50%;
          transform: translate(-50%,-60%);
         
} */
.videopreview_playbtn__QOyIx{
  
   margin:auto;
   text-align:center;
   margin-top:-66%;
   margin-left:47%;
   width:40px;
   cursor:pointer;
   box-shadow: 0 0 40px rgba(0,0,0,0.5);
   opacity:80%;
  
}
.videopreview_playbtn2__3a4rN{
  position:absolute;
    z-index:30;
    top: 50%;
        left: 50%;
          -webkit-transform: translate(-50%,-50%);
                  transform: translate(-50%,-50%);
    width:50px;
   
}
.videopreview_wrapper__35YZ2{
    position:relative;
   width:100%;
   background-color:black;
}
.videopreview_wrapvideoclass__1QsuZ{
    height:100%;
    position:relative;
}
.report_textforGirl__h-ia5 {
    margin-top: 5px;
    width: 100%;
    height: 150px;
}

.report_checkbox__26Cjq {
    margin-right: 10px;
}
.commentlist_allthecomments__2lQ8B {
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 100%);
    border-radius: 10px;
    margin: 5px;
    padding: 5px 0px;
}

.commentlist_allthecommentsdm__3fkY5 {
    background-color:#151424;
    border-radius: 10px;
    margin-top: 1%;
    border: 1px solid #c0c0c0;
    margin: 5px;
    padding: 5px 0px;
    color: white;

}

.commentlist_allthecommentsdm__3fkY5 a {
    color: white !important;
}

.commentlist_wrapbuttondiv__HwOkm {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
}

.commentlist_commentbtn__3gf8N {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: 1px solid var(--primary)50;
    font-weight: 700;
    background: #fff;
    padding: 0 8px 1px;
    font-size: 13px;
    border-radius: 4px;
    color: #fff;
    height: 28px;
    min-width: 56px;
    transition: all 80ms linear;
    background: var(--primary);

}

.commentlist_commentbtn__3gf8N:hover {
    border: 1px solid #00000050;
    color: #000;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
    background: rgba(248, 248, 248, 1);
}

.commentlist_thebutton__2EUTH {
    margin: auto;
}
.comment_userimagesmall__ynaEj {
    width: 50px;
    border-radius: 100%;
    height: 50px;
    border: 2px solid white;
}

.comment_reactions__3ivOO {
    display: flex;
    flex-direction: reverse row;
    grid-gap: 5px;
    gap: 5px;
    float: right;
}

.comment_combody__2CIRC:hover {
    background-color: rgb(100, 100, 100, 0.1);
    transition: 0.6s;
}

.comment_combody__2CIRC {
    padding: 1%;
    border-bottom: 1px solid #dddddd;
    margin-left: 0.4%;
    margin-right: 0.4%;
    font-size: 0.8em;
    line-height: 0.5em;
}

.comment_commentdate__3NA4d{
    font-size: 0.9em;
    font-weight: normal;
}
.comment_mobileText__2wgJM {
    font-size: 1em;
    line-height: 1em;
}

.comment_mobilepad__hJ_C9 {
    margin-top: 5px;
}

.comment_reactiones__LMMGb {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.comment_grayscaled__2LEiY {
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-filter: grayscale(0.6);
            filter: grayscale(0.6)
}
.writecomment_size__3s5KA {
    width: 90%;
    height: 100%;
    margin: auto;
}


.writecomment_commentformstyle__3xaC8 textarea {
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
    color: #24292e;
    vertical-align: middle;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: right 8px center;
    border: 1px solid #e1e4e8;
    border-radius: 6px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
}

.writecomment_commentformstyle__3xaC8 textarea:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: rgba(214, 3, 3, 0.3) 0px 0px 0px 3px;
}

.writecomment_textforGirl__3OBln {
    min-height: 100px;
    width: 98%;
    padding: 2%;
    margin: 1% 0%;
}

.writecomment_commentbtn__2gt_I {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: 1px solid var(--primary)50;
    font-weight: 700;
    background: #fff;
    padding: 0 8px 1px;
    font-size: 13px;
    border-radius: 4px;
    color: #fff;
    height: 28px;
    min-width: 56px;
    transition: all 80ms linear;
    background: var(--primary);
}

.writecomment_commentbtn__2gt_I:hover {
    border: 1px solid #00000050;
    color: #000;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
    background: rgba(248, 248, 248, 1);
}
.showInfoNoFollows_wrapper__1R0HD{
text-align:center;
width:80%;
margin:30px auto;
border:3px solid var(--primary);
border-radius:6px;
}
.showInfoNoFollows_header__1BKcO{
margin-top: 10px;
margin-bottom:20px;
}
.showInfoNoFollows_icon__pSugc{
    width:25px;
    margin-left:5px;
    margin-right:5px;
}
.showInfoNoFollows_girltext__F6VKv{
    color:var(--primary);
    font-weight:bold;
}
.ersterPost_wrapper__2YnHI {
  margin: auto;
  width: 90%;
  margin-bottom:25px;
}

.ersterPost_borderisDone__IR03c {
  border: 2px solid rgb(0, 128, 0);
  text-decoration: line-through;
  background-color: rgb(0, 128, 0,0.3);
  text-align: center;
   width: 100%;
  padding:1%;
  border-radius: 8px;
  margin: 1% auto;
  font-weight:bold;
  
}
.ersterPost_border__sK5Tq {
  border: 2px solid var(--primary);
  background-color: rgb(200, 50, 50,0.3);
  text-align: center;
  width: 100%;
  padding:1%;
  border-radius: 8px;
  margin: 1% auto;
   font-weight:bold;
 cursor: pointer;
}
.ersterPost_hostname__2HeSz{
  color: var(--primary);
  text-transform: uppercase;
}
.ersterPost_bouncebottom__2-eIK{
  margin-bottom:15px;
}
.ersterPost_higherup__SoO00 {
  margin-top: -50px;
}
.ersterPost_headline__3IN1U {
  text-align: center;
  margin-top: -8%;
}
.ersterPost_icon__3i5jx{
  max-width:20px;
  width:auto;
  height:auto;
}
.ersterPost_icon2__3ZaCT{
  max-width:40px;
  width:auto;
  height:auto;
  margin: auto;
  display:flex;
  justify-content: center;
}

.avsoverview_checkbox__122iK{
  margin-right:1%;
}
.avsoverview_avsimage__yfnwg{
  max-width:140px;
   max-height:140px;
  border-radius:50%;
}

    .avsoverview_flexouter__pv2HF{
      display:flex;
      flex-flow:row;
      flex-wrap:nowrap;
      grid-gap:15px;
      gap:15px;
    }
  .avsoverview_addapayment__7_6qp{
    cursor:pointer;  
    margin:auto;
    background-color: #efefef;
    color:#555;
    font-size:1.0em;
    font-weight:bold;  
    display:flex;
    flex-flow:row wrap;
    justify-content: flex-start;
    padding:4px 10px;
    
    
}
.avsoverview_icon__6bZLY{max-height:60px;float:left;}
.avsoverview_text__JAAvK{
  text-align:left;
  font-size:0.9em;
  line-height:0.5em;
  margin-top:10px;

}
.avsoverview_text__JAAvK h5{
  font-weight:bold;
  font-size:1em;
}
.avsoverview_text__JAAvK p{
  font-weight:normal;
}
.avsoverview_flexit__PRhmm{
  display:flex;
  flex-flow:row;
  grid-gap:20px;
  gap:20px;
  justify-items: flex-start;
}
  .avsoverview_control__2s5yL {
    margin-bottom: 0.5rem;
   display:flex;
   justify-content: space-around;
   grid-gap:5%;
   gap:5%;
   width:100%;
   
  }
  .avsoverview_container__hJyrq{
    width:100%;
    background-color: #fff;
    color:#222;
    padding:10px;
  }
  .avsoverview_extrainfo__1HZlP{color:#fff;}
  .avsoverview_control__2s5yL label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width:100%;
    padding:3px;
    border-radius:8px;
  }
  
  .avsoverview_control__2s5yL input {
    border-radius:8px;
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
   width:100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .avsoverview_actions__29IEN {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avsoverview_actions__29IEN button {
    max-width:80%;
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 3%;
  }
  
  .avsoverview_actions__29IEN button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .avsoverview_actions__29IEN .avsoverview_toggle__3XP5y {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .avsoverview_actions__29IEN .avsoverview_toggle__3XP5y:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .avsoverview_form__1vGSo{
    border-radius:20px;
    background-color: var(--primary);
    padding:5%;
    display:flex;
 
  
  
  margin-right:4%;
  }
 .avsoverview_form__1vGSo form{
  width:100%;
 }
.avsoverview_wrapper__3lEZe{
 
  width:100%;

}
.avsoverview_header__3Uaid{
  color:white;
  margin-bottom:1rem;
  font-size: 1.5em;
}
.avsoverview_head__q66mj{
  color:white;
 font-size: 0.9em;
}
.avsoverview_showonmobile__Jw7f6{
  display:none
}
.avsoverview_controlzip__2GGhw{
  width:28%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlcity__3g7HV{
  width:68%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlstreet__T6ltl{
  width:100%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlfn__mEbYq{
  width:50%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlln__3ovcm{
  width:50%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlmail__3mEvM{
  width:60%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlphone__3Qfhw{
  width:60%;
}
.avsoverview_controldate__1BNdF{
  width:40%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_controlcountry__2M8eW{
  width:40%;
  color:var(--primary);
  background-color: #fce1e4;
}
.avsoverview_thebuttons__1YoqA{
 display:flex;
 grid-gap:10px;
 gap:10px;
 flex-flow:column;
 margin:auto;
 
width:95%; 
}
.avsoverview_mobile__3CIbz{
  display:none;
}

.avsoverview_showondesktop__2AAk0{
  display:block;
}
@media (max-width: 768px) {
.avsoverview_showonmobile__Jw7f6{
  display:block;
}
.avsoverview_showondesktop__2AAk0{
  display:none;
}
  .avsoverview_controlzip__2GGhw{
  width:100%;
}

.avsoverview_mobile__3CIbz{
  
}
.avsoverview_controlcity__3g7HV{
  width:100%;
}
.avsoverview_flexouter__pv2HF{
  display:flex;
  flex-flow:row;
}
.avsoverview_control__2s5yL label{
  color:var(--primary);
  font-size:0.8rem;
  margin-bottom:-2px;
}
.avsoverview_begone__14Uo3{
  display:none !important;
}
.avsoverview_control__2s5yL {
    margin-bottom: 0.9rem;
  }
  .avsoverview_form__1vGSo{
      margin-right:0;
  }
}
.timelinefilterbar_wrapper__u-SDO {
  position: fixed;
  top: 45px;
  z-index: 50;
  width: 58%;
  height: 2px;
  transition: 0.4s;
}
.timelinefilterbar_wrappermobile__9l0mh {
  position: fixed;
  top: 65px;
  z-index: 50;
  width: 100%;
}
.timelinefilterbar_wrappermain__2xspI {
  position: fixed;
  top: 45px;
  z-index: 50;
  width: 61.4%;
  height: 2px;
  transition: 0.4s;
  margin-left: -12px;
}
.timelinefilterbar_bar__-E_Lk {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.4s;
}
.timelinefilterbar_bar__-E_Lk img {
  width: 30px;
  transition: 0.9s;
}

.timelinefilterbar_fullbar__33Qmi {
  z-index: 550;
  position: fixed;
  top: 50px;
  max-width: 833px;
  margin-left: -1px;
  height: 40px;
  background-color: #e9ebeb;
  color: black;
  width: 61.4%;
  transition: 0.4s;
  display: flex;
  flex-direction: row wrap;
}
.timelinefilterbar_fullbarmobile__1L_N5 {
  z-index: 550;
  position: fixed;
  top: 70px;
  max-width: 833px;
  margin-left: -1px;
  height: 40px;
  background-color: #e9ebeb;
  color: black;
  width: 105%;
  transition: 0.4s;
  display: flex;
  flex-direction: row wrap;
}
.timelinefilterbar_fullbar2__25D4N {
  z-index: 550;
  margin-top: 3px;
  height: 70px;
  background-color: #e9ebeb;
  color: black;
  width: 100%;
  transition: 0.4s;
}

.timelinefilterbar_innerflex__1mkd_ {
  width: 100%;
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}
.timelinefilterbar_innerflex__1mkd_ input {
  margin: 13px 10px;
}
.timelinefilterbar_fullbar__33Qmi img {
  width: 30px;
  float: left;
}

@media (min-width: 1130px) {
}

.timelinefilterbar_allwrapper__Inyzi {
  position: relative;
  height: 20px;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;

  margin: 0;
  padding: 0;
}
.timelinefilterbar_allfullbar__1kaI1 {
  position: fixed;
  top: 50px;
  background-color: #fff;
  width: 60%;
  height: 30px;
}

@media (min-width: 1400px) {
  .timelinefilterbar_allfullbar__1kaI1 {
    width: 800px;
  }
}

@media (max-width: 768px) {
  .timelinefilterbar_allfullbar__1kaI1 {
    width: 900%;
    top: 70px;
  }
}

.aktionswrapper_wrapper__16he-{
    border:1px solid rgb(167, 167, 167);
    padding:5px;
    border-radius:8px;
    display:flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    cursor:pointer;
    margin-bottom:5px;
}

.aktionswrapper_image__1t2pB{
    max-width:100%;
    border-radius:8px;
}
.sendVoucherCode_form__1CEGB {
   margin:auto;
   width:90%;
    margin-bottom: 0.5rem;
  }
  
  .sendVoucherCode_label__e7Y0q {
    display: block;
    color: var(--primary);;
    font-weight: normal;
    margin-bottom: 0.5rem;
    font-size:0.9em;
  }
  
  .sendVoucherCode_input__3fPki {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid var(--primary);
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
 
  .sendVoucherCode_flexbuttons__bCBwb{
    display:flex;
    flex-flow: row wrap;
    margin-top:5px;
    justify-content: space-between;
  }
  .sendVoucherCode_flexit__1dIhb{
    display:flex;
    flex-flow:row nowrap;
    justify-content: first baseline;
    grid-gap:8px;
    gap:8px;
    margin-bottom:8px;
  }
  .sendVoucherCode_thegirlimage__3rzpR{
    max-width:110px;
    height:auto;
    border-radius:50%;
  
    }
  @media (max-width: 768px) {.sendVoucherCode_thegirlimage__3rzpR{
    margin-top:30px;
    max-width:90px;
    height:auto;
    border-radius:50%;
    max-height:200px
  }
.sendVoucherCode_label__e7Y0q {font-size:0.8em;}
.sendVoucherCode_label__e7Y0q h5{
  font-size:1.2em;
}
}
.favbox_userinfo__Gmkhw {
  margin: 10px;
  background-color: #fff;
  width: 95%;
  border-radius: 10px;
  border:1px solid #eeeeee;
  overflow:hidden;
  color:#000;

}
.favbox_userinfodm__23zV6 {
  margin: 10px;
  background-color: rgb(255,255,255,0.3);
  width: 95%;
  border-radius: 10px;
  border:1px solid #eeeeee;
  overflow:hidden;
  color:#000;
}


.favbox_userinfoheader__2Hl5r img {
  width: 100%;
  object-fit: cover;
  object-position: center -10px;
  max-height:70vw;
  height:300px
}

.favbox_userinfobody__PhFqv {
  padding: 10px 20px;
}
  
.favbox_userinfobody__PhFqv h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color:var(--primary);
}
  
.favbox_userinfobody__PhFqv h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.favbox_userinfobody__PhFqv p {
  margin: 12px 0;
}
.favbox_userinfobodydm__10Zm5 {
  padding: 10px 20px;
}
  
.favbox_userinfobodydm__10Zm5 h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color:var(--primary);
}
  
.favbox_userinfobodydm__10Zm5 h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.favbox_userinfobodydm__10Zm5 p {
  margin: 12px 0;
}
.favbox_userinfobodydm__10Zm5 btn{
  background-color: rgb(255,255,255,0.3)!important;
}
  
small { 
  font-size: 14px;
  font-weight: bold;
}
  
.favbox_online__Mcbit {
  color: #11aa00;
  }
.favbox_offline__kAT4Q {
  color: #888888;
  }
  
.favbox_btn__10-fv {
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
  margin:5px 0px;
}
.favbox_btndm__1DMX2 {
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
  background-color: rgb(255,255,255,0.5);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
  margin:5px 0px;
}
.favbox_btn__10-fv:hover {
 background-color: #04003b;
 color: #eeeeee;
}
.favbox_btndm__1DMX2:hover {
 background-color: #04003b;
 color: #eeeeee;
}
  
.favbox_btnabo__1bVhz {
  text-decoration: none;
  margin:5px 0px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: #04003b;
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}

.favbox_btnabo__1bVhz:hover {
 background-color: var(--primary);
 color: #ffffff;
} 
.favbox_btnabodm__2W3aG {
  text-decoration: none;
  margin:5px 0px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: #04003b;
  background-color: rgb(255,255,255,0.5);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}

.favbox_btnabodm__2W3aG:hover {
 background-color: var(--primary);
 color: #ffffff;
}  

.favbox_btnblock__A1v6f {
  display: block;
  width: 100%;
  text-align: center;
}
.favbox_btnblock__A1v6f:hover {
 background-color: var(--primary);
 color: #ffffff !important;
} 
.favbox_nextline__3qQLt{
  margin-top:15px;
  text-align:center;
}
 .favbox_heart__Ry1VS {
   z-index : 100;
   position : absolute;
   -webkit-animation:favbox_heartfade__1PTl3 2s linear;
           animation:favbox_heartfade__1PTl3 2s linear;
}
.favbox_heart__Ry1VS:before,
.favbox_heart__Ry1VS:after {
    margin-top:-30px;
   content : "";
   z-index : 100;
   background-color : var(--primaryBright);
   position : absolute;
   height : 22px;
   width : 35px;
   border-radius : 15px 0px 0px 15px;
}
.favbox_heart__Ry1VS:before {
   -webkit-transform : rotate(45deg);
           transform : rotate(45deg);
}
.favbox_heart__Ry1VS:after {
   left : 10.5px;
   -webkit-transform : rotate(135deg);
           transform : rotate(135deg);
}
  @-webkit-keyframes favbox_heartfade__1PTl3 {
     0% {
      opacity : 0;
     }
     50% {
      opacity : 1;
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
     }
     70% {
      opacity : 0;
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
     }
     100% {
      opacity : 0;
     }
  }
  @keyframes favbox_heartfade__1PTl3 {
     0% {
      opacity : 0;
     }
     50% {
      opacity : 1;
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
     }
     70% {
      opacity : 0;
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
     }
     100% {
      opacity : 0;
     }
  }
  .favbox_thankyoutext__2lK7e{
    color:black;
  }
  .favbox_thankyoudiv__sHLAN{
    width:50vw;
    height:100%;
    padding:5%;
  }
.favbox_thankyouimage__vK1Yh{
  border-radius:50%;
  width:10vw;
  height:10vw;
  border:5px solid var(--primary);
  margin-right:5%;
}
.favbox_thankyouheader__AFDsl{
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom:1em;
}
.favbox_thankyouimagetext__3d5ir{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 1068px) {
  .favbox_thankyoudiv__sHLAN{
    width:75vw;}
.favbox_thankyouimage__vK1Yh{
 
  width:30vw;
  height:30vw;
}
}

.favbox_girlimage__3Ld0h{

  width: 100%;

}
.otpspende_textforGirl__2ExOk{

    min-height:100px;
    width:90%;
    padding:2%;
    margin:2%;
   
    border-radius:20px;
}
.otpspende_entermoney__nW5Wr{
border:none;
text-align:right;
font-weight:bold;
width:11vw;
color:var(--primary);
font-size:5.5vw;

line-height: 1.7vw;
}
.otpspende_head__3sYTJ{
    text-transform: uppercase;
    border-bottom: 1px solid black;
    margin-bottom:1em;
    font-size:19px;
    line-height:2em;
}
.otpspende_entermoney__nW5Wr input{
margin:1%;
 color:var(--primary);
}
.otpspende_firstelement__3t0Fe{
    
    text-align: center;
    margin:auto;
    margin-top:2%;
    margin-bottom:2%;
   font-size:2.9em;
   
}
.otpspende_firstelementbox__2zwV5{
    width:100%;
    text-align: center;
    margin:auto;
 }

input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
  margin:0px;
color:var(--primary);
}
.otpspende_wrapme__3UyxX{
    padding:2%;
   z-index:1000;
}
.otpspende_theoption__2wVdy{ 
  background-color: #fff;
  margin:3px;
  font-weight:bold;
max-width: 100%;
border: 1px solid var(--primary);
}
.otpspende_increasbutton__2G8Uh{
   border:none;
    background-color:transparent;
    color:var(--primary);
     font-weight:bold;
    text-align:center;
        line-height:0.1em;
       
       
}
.otpspende_otpagb__2-F6e{
    display:inline !important;
    width:100%!important;
    margin:1px!important;
    padding:1%;
}
.otpspende_increasbutton__2G8Uh:hover{
    -webkit-transform:scale(1.1);
            transform:scale(1.1);
    transition:0.5s;
}
.otpspende_setapart__3-otE{
margin-left: 2%;
margin-right: 3%;
color:var(--primary);
width:10vw;

}
.otpspende_iframe__QFhIg{
  min-width:80vw;
  height:60vh;
  margin:auto;
}
.otpspende_iframeinner__OMQ_p{
width:70vw;
margin:3% auto;
margin-left:7vw;
height:80vh;
}
  .otpspende_makebold__2VlX9{
    font-weight:600;
  }

  .otpspende_fandollarelement__3YdwV{
    width:5vw;
    
    -webkit-transform: translateY(-1vw);
    
            transform: translateY(-1vw);
  }

  @media (max-width: 968px) {
.otpspende_fandollarelement__3YdwV{
    width:5vw;
    
    -webkit-transform: translateY(0px);
    
            transform: translateY(0px);
  }
.otpspende_entermoney__nW5Wr{
border:none;
text-align:right;
font-weight:bold;
width:14vw;
color:var(--primary);
font-size:7.5vw;

line-height: 1.7vw;
}
  }
.favgirls_content__1LIof{
    padding:5px 10px;
}
.contentcard_box__1Ke-F{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:14vw;


    
}
.contentcard_box__1Ke-F:hover{
    -webkit-filter: none;
            filter: none;
    transition:0.3s;
}
.contentcard_box2__2iDvs{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
  max-width:24vw;

cursor:pointer;
  
}
.contentcard_box2__2iDvs:hover{
    -webkit-filter: sepia(0);
            filter: sepia(0);
    transition:0.3s;
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1{
  width:100%;
  max-width:12vw;
 height:100%;
 max-height:12vw;
 margin:auto;
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1 img{
-webkit-transform: translate(-20%, -15%);
        transform: translate(-20%, -15%);
transition: .5s ease all; 
max-width:400px;

height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1{
  width:100%;
  max-width:25vw;
 height:100%;
 max-height:25vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1 img{

transition: .5s ease all; 
max-width:650px;
-webkit-transform: translate(-20%, -15%);
        transform: translate(-20%, -15%);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

.contentcard_contain__2B9R1 {
  position: relative;
  overflow: hidden;
  
  z-index: 1;
}
.contentcard_contain__2B9R1::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: .5s ease all;
   border-radius:14px;
}
.contentcard_theimage__3eGau{
  transition: .5s ease all; 

object-fit:stretch;
overflow:hidden;
  border-radius:14px;}
.contentcard_contain__2B9R1:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  border-radius:14px;
}

.contentcard_contain__2B9R1:hover::after {opacity: 0.2;}

.contentcard_info__Eirix{
  color:black;
  
}
.contentcard_headline__VAGHj{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.contentcard_infoservice__2v8Ar{
  display:flex;
  justify-content: space-between;
  padding:0px 5px;
}
.contentcard_myname__-Ula6{
  font-weight:bold;
  color:var(--primary);
  text-decoration: none;

}
.contentcard_myname__-Ula6 a{
  text-decoration: none;
  color:var(--primary);
}
.contentcard_buttons__aEeOJ{
   display:flex;
  justify-content: space-evenly;
}
.contentcard_wrapper__1cZcn{
  color: var(--primary);
  width:80%;
  margin:2% auto;
}
@media only screen and (max-width: 1108px) {
  
.contentcard_box__1Ke-F{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:18vw;


    
}
.contentcard_box__1Ke-F:hover{
    -webkit-filter: none;
            filter: none;
    transition:0.3s;
     -webkit-transform: scale(0.95);
             transform: scale(0.95);
}
.contentcard_box2__2iDvs{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
  max-width:98vw;

cursor:pointer;
  
}
.contentcard_box2__2iDvs:hover{
    -webkit-filter: sepia(0);
            filter: sepia(0);
    transition:0.3s;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1{
  width:100%;
  max-width:17vw;
 height:100%;
 max-height:17vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1 img{

transition: .5s ease all; 
max-width:250px;
-webkit-transform: translate(-0, -0);
        transform: translate(-0, -0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1{
  width:100%;
  max-width:95vw;
 height:100%;
 max-height:95vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1 img{

transition: .5s ease all; 
max-width:550px;
-webkit-transform: translate(0, 0);
        transform: translate(0, 0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

}
.contentcard_headline__VAGHj{
  line-height:normal;
  height:1.5rem;
}
@media only screen and (max-width: 778px) {
  
.contentcard_box__1Ke-F{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:47vw;


    
}
.contentcard_box__1Ke-F:hover{
    -webkit-filter: none;
            filter: none;
    transition:0.3s;
}
.contentcard_box2__2iDvs{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    -webkit-filter:sepia(0.3);
            filter:sepia(0.3);
     transition:0.3s;
  max-width:98vw;

cursor:pointer;
  
}
.contentcard_box2__2iDvs:hover{
    -webkit-filter: sepia(0);
            filter: sepia(0);
    transition:0.3s;
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1{
  width:100%;
  max-width:47vw;
 height:100%;
 max-height:44vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box__1Ke-F .contentcard_contain__2B9R1 img{

transition: .5s ease all; 
max-width:250px;
-webkit-transform: translate(-0, -0);
        transform: translate(-0, -0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1{
  width:100%;
  max-width:95vw;
 height:100%;
 max-height:95vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.contentcard_box2__2iDvs .contentcard_contain__2B9R1 img{

transition: .5s ease all; 
max-width:550px;
-webkit-transform: translate(0, 0);
        transform: translate(0, 0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

}
.simplecomponents_flex__1uinf{
margin-top:20px;
    display:flex;
    flex-flow: row;
    justify-content: space-between;
}

.simplecomponents_imageList__3cHqZ{
    list-style: none;
    margin:0;
    padding:0;
    display:flex;
    flex-flow:row;
    flex-wrap: wrap;
    grid-gap:1%;
    gap:1%;
    justify-content: space-evenly;
}
.simplecomponents_imageListElement__30AR3{
    max-width:48%;
    margin-bottom:1%;
}

.simplecomponents_closex__JjdTN{
    background: none;
    width:25px;
    border:none;
}
.simplecomponents_closeBtn__1gs71 {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:1000;
}

.simplecomponents_maxImage__2Rnyi{
   width:100%;
   height:100%;
max-width:99vw;
max-height:99vh;
    
}

.simplecomponents_extra__2_MH6{
display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 100vh;
}
.simplecomponents_imageelement__2mzJ5{
    max-height:50vh;
    max-width:100%;
    margin:auto;
    cursor:pointer;
     transition: 0.4s;
}
.simplecomponents_imageelement__2mzJ5:hover{
    -webkit-transform:scale(0.97);
            transform:scale(0.97);
    transition: 0.2s;
}
.simplecomponents_imagewrapper__3hHxV{
    align-content: center;
    display: flex;
max-width: 90vw;
background-color: rgb(188,28,15,0.01);
padding:4px;

}
.simplecomponents_moreImage__PSl4n{
    max-width:100%;
}
.videoplayer2_videowrapper__1tlHn {
  width: 100%;
 
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.videoplayer2_videowrapper__1tlHn:hover .videoplayer2_controls__3LqHO {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.videoplayer2_videowrapper__1tlHn:hover .videoplayer2_controls__3LqHO {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.videoplayer2_container__1Sm8L video{
    width:auto;
    height:auto;
    max-width:100%;
    max-height:100%;
}
.videoplayer2_nocontrolcontrol__2MoHU{
display: none;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;

  bottom: -50px;
  padding: 14px;
  width: 100%;

  flex-wrap: wrap;
  
}
.videoplayer2_noactions__1GhCD{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
}

.videoplayer2_controls__3LqHO{
      display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.videoplayer2_controls__3LqHO input{
     -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}
.videoplayer2_controls__3LqHO input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
  
  
}

.videoplayer2_controls__3LqHO input[type="range"]::-moz-range-progress {
  background: white;
}

.videoplayer2_actions__3-HcE button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.videoplayer2_bx__1u4Em{
    width:25px;
}
.videoplayer2_btn__2bQJo{
    background: none;
    outline: none;
    border:none;
}
.videoplayer2_containermobile__2cxGh{

    width:100%;
}
.videoplayer2_mobilecontrol__38cDM{
display:flex;
justify-content: space-evenly;
    width:90vw;
    margin:auto;
}

.videoplayer2_inputmobile__SXEaX{
    -webkit-appearance: none !important;
color:var(--primary);
 background-color: none!important;

    border-radius: 20px;
  height: 20px;
  max-width:100%;
  margin-top:1%;
}
.videoplayer2_inputmobile__SXEaX input::-webkit-slider-thumb{
color:green;
 background-color: aqua;
}

.allcontent_wrapper__2rDQc{
    width:100%;
}
.allcontent_flexit__2IAy9{display:flex;}
.allcontent_view__2zaSr{cursor:pointer;}
.allcontent_list__3eL_N{
   
    list-style: none;
    margin: none;
    padding:none;
    display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
    grid-gap:10px;
    gap:10px;
margin:0;
padding:0;
    }
    .allcontent_centercontent__De5UZ{
      text-align:center;
    }
  .allcontent_trenner__1saIQ{
    color: var(--primary);
    border-bottom:3px solid var(--primary);
  }
  .allcontent_mainhead__3pUEZ{
    text-align: center;
    margin-bottom:1em;
    color:var(--primary);
  }
  .allcontent_functionicons__1rjsK{
    width:60px;
    transition:0.5s;

  }
  .allcontent_iconlist__3iUBd{
    margin-top:2rem;
    display:flex;
    flex-flow: row;
    justify-content: space-around;
  }
.allcontent_functionicons__1rjsK:hover{
  -webkit-transform:scale(0.9);
          transform:scale(0.9);
  transition:0.5s;
}
  
@media only screen and (max-width: 778px) {

 .allcontent_functionicons__1rjsK{
    width:45px;
  }
}

 .newmailpw_boxit__1XLs-{
        border:3px solid white;
        border-radius:8px;
        margin:2% 0;
        padding:1%;
        background:#dfa9ab;
    }
.newmailpw_auth__2Dsbg {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
  }
  .newmailpw_boxit__1XLs- h5{
    color: #333;
  }
  .newmailpw_auth__2Dsbg h1  {
    text-align: center;
    color: white;
  }
  
  .newmailpw_control__3I__O {
    margin-bottom: 0.5rem;
  }
  
  .newmailpw_control__3I__O label {
    display: block;
    color: #333;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .newmailpw_control__3I__O input {
    font: inherit;
    background-color: #f2e6e8;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .newmailpw_actions__PKjb4 {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .newmailpw_actions__PKjb4 button {
    cursor: pointer;
    font: inherit;
  
    padding: 0.5rem 2.5rem;
  }
 
  
  .newmailpw_actions__PKjb4 .newmailpw_toggle__18Bpu {
    margin-top: 1rem;
  

    padding: 0.15rem 1.5rem;
  }
  

  .newmailpw_white__2w8ef{
    color:#fff;
  }
  .newmailpw_logininfo__2ROpI{
    text-align:center;
  }
.paymentmethods_thelist__1RclJ {
  list-style: none;
  margin: 0;
  padding: 0;
}

.paymentmethods_list__u3Byx {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 1%;
}
.paymentmethods_listbutton__3oj3i {
  display: inline-flex;
}
.paymentmethods_listelement__3c04s {
  margin: 5px 0;
   border: 2px solid rgb(18, 159, 14);
  padding: 5px;
  transition:0.5s;
}
.paymentmethods_listelement__3c04s:hover{
    background-color:rgb(18, 159, 14,0.1); transition:0.5s;
}
.paymentmethods_addmore__2F5w_ {
  margin: 15px 20px;
}
.paymentmethods_boxit__2_lZt {
 
   border: 1px solid var(--primary);
  border-radius: 6px;
  margin: 2% 0;
  padding: 1%;
  background: transparent;
}
.paymentmethods_deleteIcon__ryWTA {
  width: 25px;
-webkit-filter:grayscale(1);
        filter:grayscale(1);
  float: right;
}
.paymentmethods_thingieicon__2s_QJ {
  height: 25px;
  margin-right: 10px;
}

.languageswitch_select__7C56L {
    position: relative;
    display: inline-block;
    margin: 2%;
    width: 20%;
}    .languageswitch_select__7C56L select {
        font-family: 'Arial';
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 2px solid #000000;
        border-radius: 0px;
        background: #e6e6e6;
        color: var(--primary);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
        .languageswitch_select__7C56L select::-ms-expand {
            display: none;
        }
        .languageswitch_select__7C56L select:hover,
        .languageswitch_select__7C56L select:focus {
            color: var(--primary);
            background: #cccccc;
        }
        .languageswitch_select__7C56L select:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
.languageswitch_select_arrow__vJe9T {
    position: absolute;
    top: 11px;
    right: 17px;
    width: 0px;
    height: 0px;
    border: solid #7b7b7b;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.languageswitch_select__7C56L select:hover ~ .languageswitch_select_arrow__vJe9T,
.languageswitch_select__7C56L select:focus ~ .languageswitch_select_arrow__vJe9T {
    border-color: #000000;
}
.languageswitch_select__7C56L select:disabled ~ .languageswitch_select_arrow__vJe9T {
    border-top-color: #cccccc;
}
.languageswitch_flexit__1BaKk{display:flex;
flex-flow: row;
grid-gap:10px;
gap:10px;margin-top:2px;}
.languageswitch_flagicon__agPWa{
    border:3px solid #cccccc;
    border-radius:50%;
    margin:2%;
    -webkit-filter:grayscale(0);
            filter:grayscale(0);
    width:60px;
}
.languageswitch_flagicon__agPWa img{border:2px solid #fff;}
.languageswitch_flagiconinactive__3Xd0o{
    border:3px solid #cccccc;
    border-radius:50%;
    margin:2%;
    -webkit-filter:grayscale(1);
            filter:grayscale(1);
    cursor:pointer;
    width:60px;
}
@media (max-width: 768px) {
 .languageswitch_flagicon__agPWa {
    width:1.3em;
    height:1.3em;
    border:none;
 }
 .languageswitch_flagiconinactive__3Xd0o {
    width:50px;
    width:1.3em;
    height:1.3em;
    border:none;
 }
}
.profile_profilepic__25_y7 {
  max-width: 50px;
  border-radius: 50%;
  border: 5px solid var(--primary);
}

.profile_language__1Xo6K {

  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.profile_header__mdNVR {
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}
.profile_payments__2w-x0 {
  border-radius: 20px;

  font-weight: 500;
}
.profile_btnelements__3gREi {
  padding: 10px;
  
}
.profile_btnelements__3gREi div {
  margin: 10px 0px;
}
.profile_padextra__2OwdJ {
  padding: 10px;
}
.profile_profpic__2pFQf {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--primary);
  object-fit: cover;
}
.profile_therows__2wGRU {
  margin-top: 10px;
  PADDING:5px;
 
}
.profile_welcome__2_FSJ{
  padding:5px;
}
.profile_distancer__1KsDy {
  margin: 5px;
}
.profile_taglist__2mEOP {
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  grid-gap: 5px;
  gap: 5px;
}
.profile_tag__1cBY3 {
  cursor: pointer;
  border-radius: 12px;
  background-color: #04003b;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
}
.profile_tagplus__3yNZc {
  cursor: pointer;
  border-radius: 12px;
  background-color: #ffffff;
  color: #04003b;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
}
.profile_tagbox__2yq_I {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
  margin:10px 0px;
}
.profile_flexit__FNUZK {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.profile_inputElement__3Tbd- input {
  font: inherit;
  background-color: #fce1e4;
  color: var(--primary);
  border-radius: 4px;
  border: 1px solid #fce1e4;

  text-align: left;
  padding: 0.25rem;
  margin: 5px;
}
.profile_inputElement__3Tbd- {
  width: 100%;
}
.profile_iconinactive__3PWdY {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 30px;
  cursor: pointer;
}
.profile_iconactive__1dYKx {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  width: 30px;
  cursor: pointer;
}
.profile_infotext__3V9qy {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
 height:210px;
  margin-bottom: 5px;
 overflow:hidden;
}
.profile_profpicwrapper__1x2fj {
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  width: 100%;
}
.profile_area__1BQhU {
  width: 100%;
  height: 200px;
}
.profile_personalbox__1JIas{
 
  padding:3px;
  width:99%;
  margin:auto;
  margin-bottom:10px;
}
.profile_toright__1suNl{
  position:relative;
 
  right:5px;
  float:right;
}
.profile_desctext__2_jYB{
  max-height:120px;
  overflow:hidden;
}
.profile_wrapper__3LM3G{margin:0px 10px 90px 8px;}
.profile_toggle__1RHcV {
	 --size: 1.3rem;
	 -webkit-appearance: none;
	         appearance: none;
	 outline: none;
	 cursor: pointer;
	 width: var(--size);
	 height: var(--size);
	 box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
	 border-radius: 999px;
	 color: var(--nearBlack);
	 transition: all 500ms;
   margin-top:-6px;
}
 .profile_toggle__1RHcV:checked {
	 --ray-size: calc(var(--size) * -0.4);
	 --offset-orthogonal: calc(var(--size) * 0.65);
	 --offset-diagonal: calc(var(--size) * 0.45);
	 -webkit-transform: scale(0.75);
	         transform: scale(0.75);
	 color: #fa0;
	 box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}
.profile_distancerdm__bigtR{
  margin-top:20px;
  float:right;
}
.dropdownbutton_MyButton__8I8r5 {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:transparent;
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:var(--primaryBright);
		font-size:1em;
	padding:0.8% 6px;
	text-decoration:none;
    transition:1s;
    margin:0.3%;
    margin-bottom:7px;
    width:100%;
    text-align:center;
    font-weight:bold;
}
.dropdownbutton_MyButton__8I8r5:hover {
	 transition:0.5s;
	background-color:none;
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.dropdownbutton_MyButton__8I8r5:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    
.dropdownbutton_MyButton__8I8r5 {
    font-size:13px;
}
}
.allsubscriptions_mylist__3u5xT{
    padding:1%;
    margin-left:0px;
    margin-right:0px;
}
.allsubscriptions_outer__1Hfhq{
     margin-left:0px;
    margin-right:0px;
    padding:0;
}
.subbox_aboinfo__1kqeE {
  margin: 10px 0px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  color:#000;
}
.subbox_aboinfodm__Fdz6L {
  margin: 10px 0px;
  background-color: rgba(69, 65, 84, 0.4);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#fff;
}

.subbox_aboinfoheader__2ohfD img {
  width: 100%;
  object-fit: cover;
}
.subbox_flexbuttons__3grsS{
  display: flex;
  flex-flow:row wrap;
  justify-content: space-evenly;
  width:100%;
}
.subbox_aboinfobody__peuqo {
  padding: 10px 20px;
}
  
.subbox_aboinfobody__peuqo h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color:var(--primary);
}
  
.subbox_aboinfobody__peuqo h5 {
  font-size: 1.2rem;
  font-weight: bold;
}  
  
.subbox_aboinfobody__peuqo p {
  margin: 12px 0;
}
  .subbox_tiername__1CYA-{
    font-weight: 600;
  }
.subbox_aboinfobody__peuqo ul {
  list-style:none;
  margin:10px 0px;
  padding:0px;
  }
  .subbox_addborder__182p6{
    border:1px solid black;
    padding:5px;
  }
.subbox_abopseudo__Qo8dT {
  color: #11aa00;
  text-decoration:none;
  }
  .subbox_abopseudo__Qo8dT a{
    text-decoration: none;
  }
  
.subbox_btn__2ADWj {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}
  
.subbox_btn__2ADWj:hover {
 background-color: #04003b;
 color: #eeeeee;
}
 
.subbox_abobtn__1J7Cr {
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  background-color: var(--primary);
  padding: 5px 10px;
  border-radius: 15px;
  transition:0.5s;
}  
  
.subbox_abobtn__1J7Cr:hover {
 background-color: #04003b;
 color: #eeeeee;
}  
  
.subbox_abobasic_off__1mBh_, .subbox_abostandard_off__2WaTu, .subbox_abopremium_off__1d-IC {
font-size:1.1rem;  
color: #999999;   
}
.subbox_abobasic_aktive__1Dze6, .subbox_abostandard_aktive__1GjRI, .subbox_abopremium_aktive__1hiux {
font-size:1.1rem;  
font-weight:bold;
color: var(--primary);  
} 
.subbox_thebtns__3VcrL{
    margin:8px;
}
.subscriptionmodal_darkBG__h8esg {
   
 position:fixed;
  z-index: 0;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
   
}

.subscriptionmodal_centered__3EGdV {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index:5;
}

.subscriptionmodal_modal__3iGO9 {
  width: 80vw;
  height: 100%;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:2%;
  overflow: hidden;
}

.subscriptionmodal_modalHeader__3YuJh {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.subscriptionmodal_heading__Ugee5 {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.subscriptionmodal_maintext__35iQY{
  font-weight: bold;
}
.subscriptionmodal_theoption__2ug_h{
  background-color: #efefef;
  
  font-weight:bold;
  max-width: 100%;
}
.subscriptionmodal_modalContent__252ab {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
}

.subscriptionmodal_modalActions__PNYUp {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.subscriptionmodal_actionsContainer__3gdmv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subscriptionmodal_closeBtn__1VLrT {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.subscriptionmodal_closeBtn__1VLrT:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: translate(-4px, 4px);
          transform: translate(-4px, 4px);
}

.subscriptionmodal_deleteBtn__1fJXu {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.subscriptionmodal_deleteBtn__1fJXu:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: #ff3e4e;
}

.subscriptionmodal_cancelBtn__2moD8 {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.subscriptionmodal_cancelBtn__2moD8:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.subscriptionmodal_modal__3iGO9{
  padding:2%;
  color:black
}
.subscriptionmodal_inactive__Px-ja{
  background-color: beige;
  color:#2c3e50;
}
.subscriptionmodal_checkbox__1c5_8{
  margin-right:1%;
}

  
    
.loginmodal_darkBG__XfK1n {
   
 position:fixed;
  z-index: 0;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
   
}

.loginmodal_centered__vYGBB {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loginmodal_modal__2H7Ld {
  width: 100%;
  height: 100%;
 
 
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:1%;
  overflow: hidden;
}

.loginmodal_modalHeader__1t_9O {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.loginmodal_heading__28lmy {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.loginmodal_modalContent__ns1xb {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
}

.loginmodal_modalActions__2jp1D {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.loginmodal_actionsContainer__2BIv5 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loginmodal_closeBtn__nJBMd {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #fff;
 background:transparent;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 50px;
  margin-right: 12px;
}

.loginmodal_closeBtn__nJBMd:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.loginmodal_deleteBtn__1P5Nq {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.loginmodal_deleteBtn__1P5Nq:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: #ff3e4e;
}

.loginmodal_cancelBtn__2nDCO {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.loginmodal_cancelBtn__2nDCO:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.loginmodal_modal__2H7Ld{
  padding:2%;
  color:black
}
.loginmodal_inactive__1N3TS{
  background-color: beige;
  color:#2c3e50;
}
.loginmodal_checkbox__LAqor{
  margin-right:1%;
}

    .loginmodal_auth__SHX6N {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
  }
  
  .loginmodal_auth__SHX6N h1 {
    text-align: center;
    color: white;
  }
  
  .loginmodal_control__2QtR0 {
    margin-bottom: 0.5rem;
  }
  
  .loginmodal_control__2QtR0 label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .loginmodal_control__2QtR0 input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .loginmodal_actions__1Dj3O {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loginmodal_actions__1Dj3O button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .loginmodal_actions__1Dj3O button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .loginmodal_actions__1Dj3O .loginmodal_toggle__335eW {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .loginmodal_actions__1Dj3O .loginmodal_toggle__335eW:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .loginmodal_form__1La0e{
    border-radius:20px;
    background-color: var(--primary);
    padding:5%;
  }
  .loginmodal_theheader__3jokZ{
    color:#fff;
    text-align: center;
  }
.managetiers_pad__2tAau{
    padding:1%;
    z-index:0;
}
.managetiers_padshort__aRgQy{
   padding:1%;
    margin:1%;
}
.managetiers_userinfo__7S-3q {
  z-index:0;
  background-color: #fff;
  border:1px solid #eeeeee;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#000;
}
.managetiers_content__19n3k {
   z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
}
.managetiers_header__2FPmI{
    width:100%;
    border-bottom:1px solid black;
    text-transform: uppercase;
    font-weight: 600;
}

.managetiers_thetiers__3-fGS{
margin-top:10px;
 width:100%;
 
}
.managetiers_thelist__370FM{
   
    list-style: none;
    width:100%;
    padding:2%;
    border-radius:10px;
    margin: auto;
    }
.managetiers_firstline__24ynJ{
    font-weight:bold;
   
   
}
.managetiers_priceline__2MzC7{
    margin-left:10px;
    font-weight: bold;
}
.managetiers_thebutton__3zwwK{
    margin-left:20px;
   
}
.managetiers_mobilelist__2Eh8-{
    list-style: none;
    margin:none;
    padding:2%;
    border:1px solid black;
    border-radius:10px;
    width:100%;
}
.managetiers_mobilelist__2Eh8- li{
    margin-left:4px;
    line-height: 0.8em;
}

body{
  min-height:100lvh;
  max-width:100vw;
  overflow-x: hidden;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-display: swap;
}
@font-face {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-display: swap;
}
.app_main__10WyS{
  background-color: #efefef;
  color: var(--nearBlack);
     max-width:100vw;
  overflow-x: hidden;
 min-height: 100vh;
}
.app_bigbutton__3kNYC{
margin-top:20px;
}
.app_bigger__3M9Bs{
  margin-top:20px;
  padding:10px;
  font-size:1.5em;
}
.app_head__3Nq34{
  font-size:1.2em;
}
.app_isnapstart__NTgW6{
  padding:10px;
  margin:auto;
  margin-top:20px;
  text-align: center;
}
.app_maindm__31TiO{
  background-color: var(--nearBlack);;
  color:#efefef;
     max-width:100vw;
  overflow-x: hidden;
   min-height: 100vh;
}
.app_testclass__1xuPr{
  -webkit-filter:grayscale(1);
          filter:grayscale(1);
}
.authresult_resultdiv__3_3ie{
    text-align: center;
    padding:10px;
    border:2px solid var(--primary);
    border-radius:8px;
    margin-top: 10px;
    margin-bottom:10px;
}
.basicsites_content__2Xr_y{
    padding:2%;
}
.basicsites_header__23hu3{
   margin-top: 2%;
   margin-bottom:2%;
   border-bottom:1px solid black;
}
.basicsites_inputquestion__3GGyU{
    width:100%;
    min-height:20vh;
}
.basicsites_inputmail__1ZywN{
    width:100%;
    
}
 
.basicsites_form__18f_W{
    margin:2%;
    padding:2%;
    border-radius:10px;
}
.basicsites_form__18f_W input{
    margin-bottom: 1%;
    white-space: normal;
}

.basicsites_link__26hsr a{color: var(--primary);
  text-decoration: none;
  font-weight: bold;}

.darkwrapmodal_darkBG__mibVG {
    
 position:fixed;
  z-index: 10;
  padding-top: 5px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.8);
}

.darkwrapmodal_centered__2yc_6 {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width:100vw;
  max-height:100vh;
  z-index: 20;
  
}

.darkwrapmodal_modal__-vUsR {
  width:100%;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: black;
  color: black;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:2%;
  overflow: hidden;
}

.darkwrapmodal_modalHeader__3n08F {
  height: 0px;
  background: black;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.darkwrapmodal_heading__1D1Pw {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.darkwrapmodal_modalContent__2NVPg {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
}

.darkwrapmodal_modalActions__TIJK6 {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.darkwrapmodal_actionsContainer__1_dtw {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.darkwrapmodal_closex__K9psb{
    background: none;
    width:25px;
    border:none;
}
.darkwrapmodal_closeBtn__1WuJJ {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:100;
}

.darkwrapmodal_closeBtn__1WuJJ:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.darkwrapmodal_deleteBtn__1HMF5 {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.darkwrapmodal_deleteBtn__1HMF5:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background: #ff3e4e;
}

.darkwrapmodal_cancelBtn__HgMSu {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.darkwrapmodal_cancelBtn__HgMSu:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background: whitesmoke;
}
.darkwrapmodal_modal__-vUsR{
  padding:1%;
  color:white
}
.darkwrapmodal_inactive__2UQbl{
  background-color: beige;
  color:#2c3e50;
}
.darkwrapmodal_checkbox__3A3K7{
  margin-right:1%;
}
.darkwrapmodal_bigimage__38dRE{
    width:100%;
    max-height:99vh;
}
  
    @media (max-width: 768px) {

      .darkwrapmodal_centered__2yc_6{
        width:99%;
      }
    }
.displayMessages_card__nG7EK{

    width:100%;
    cursor:pointer;
    background-color: aliceblue;
    color:black;
    border-radius:20px;
    box-shadow: 2px 2px 2px 2px rgb(98, 98, 98,0.3);
    margin-top: 2%;
    
}
.displayMessages_textfield__2tdjH{
  padding:3px 15px;
}
.displayMessages_textfielddm__3Lthi{
color:#333;
padding:3px 15px;
}
.displayMessages_textdark__3bIU7{color:#333}
.displayMessages_textbright__LVnnf{color:#333}
.displayMessages_headerfield__2y3e8{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.displayMessages_headersmall__3RwS6{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.displayMessages_headerfieldclicked__wyxhn{
    background-color:rgba(159, 159, 159, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.displayMessages_headersmallclicked__2pxFL{
    background-color:rgba(159, 159, 159, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.displayMessages_buttonfield__mMHb_ {
    margin: auto;
    padding: 2%;
}
.displayMessages_buttonfield__mMHb_ > div {
    display: inline-block;
}
.displayMessages_buttonfield__mMHb_ > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
}
.displayMessages_contentfield__1bi_R{
    transition:1s;
}
.displayMessages_msgimg__XXgLt {
    position: absolute;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    top: 8px;
    right: 10px;
    z-index: 11;
    outline: solid 2px white;
}

.displayMessages_mobileCard__2g6g9{
    font-size: smaller;
    padding:2%;
    
}
.displayMessages_headline__b9PTX{
    font-size: 12px;
    margin:3%;
    width:50%;
}
.displayMessages_msgimgmobile__1_PJ6 {
    position: absolute;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    top: 15%;
    right: 12%;
   
    outline: solid 2px white;
}
.displayMessages_contentfieldmobile__1YMxV{
    background-color: rgb(223, 219, 214);
}
.displayMessages_messagedate__3YFGl {
    font-size: 80%;
    color: #333;
}
.displayMessages_messagedate__3YFGl > label {
    margin-left: 10px;
    display: inline-block;
}
.displayMessages_messagedate__3YFGl > label > span {
    font-size: 11px;
    line-height: 11px;
    margin-left: 5px;
    display: inline-block;
    background-color: #333;
    color: #eee;
    padding: 2px 5px;
    border-radius: 5px;
}
.displayMessages_messagedatemobile__LZqPf{
    font-size: 80%;
    color: #333;
    padding:0px 8px;
}
.displayMessages_messagedatemobile__LZqPf > label {
    margin-left: 10px;
    display: inline-block;
}
.displayMessages_messagedatemobile__LZqPf > label > span {
    font-size: 10px;
    line-height: 10px;
    margin-left: 5px;
    display: inline-block;
    background-color: #333;
    color: #eee;
    padding: 2px 5px;
    border-radius: 5px;
}
.displayMessages_msgattachmentimg__3fwbh {
    min-width: 100px;
    min-height: 100px;
    width:100%;
}
.displayMessages_msgattachmentvideo__3JIFh {
width:100%;
}
.displayMessages_msgattachmentaudio__2Y_-t {
    
}
.displayMessages_errormodal__2tyBf{
    text-align:center;
    border:1px solid black;
    border-radius:8px;
}
.displayMessages_debugmsg__2mqIn {
    color: #777;
    font-size: 80%;
    margin-bottom: 0px;
}
.displayMessages_mediafield__12n_U {
    padding: 10px;
}
.displayMessages_mediaattachment__2JwLF {
    padding: 2px;
}

.displayMessages_spinning__32osC {
    width: 45px;
    height: 45px;
    -webkit-animation-name: displayMessages_spin__2L-3i;
            animation-name: displayMessages_spin__2L-3i;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; 
}

@-webkit-keyframes displayMessages_spin__2L-3i {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

@keyframes displayMessages_spin__2L-3i {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

.displayMessages_faviconDIV__kFV-j {
    display: inline-block;
    margin-left: 5px;
  
    position: relative;
}


.displayMessages_favicon__HA_H0 {
    width: 32px;
    transition: 0.5s;
    cursor: pointer;
}

.displayMessages_favicongray__2Qne2 {
    width: 32px;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    transition: 0.5s;
    cursor: pointer;
}
.deletemessage_size__K8B5t{
    width:100%;
    height:100%;
   
}
.deletemessage_textforGirl__1smTA{

    min-height:100px;
    width:98%;
    padding:2%;
    margin:1% 0%;
   
    border-radius:20px;
}
.displayResponseMessage_card__2h03r{

    width:100%;
    cursor:pointer;
    background-color: aliceblue;
    color:black;
    border-radius:20px;
    box-shadow: 2px 2px 2px 2px rgb(98, 98, 98,0.3);
    margin-top: 2%;
}
.displayResponseMessage_textfield__2B3Wg{
  padding:2%;
}
.displayResponseMessage_headerfield__1DdH1{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.displayResponseMessage_headersmall__1XDHm{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.displayResponseMessage_buttonfield__3tpFQ{
    margin:auto;
    padding:2%;
}
.displayResponseMessage_contentfield__2yj_-{
    transition:1s;
}
.displayResponseMessage_msgimg__3eTTV {
    position: absolute;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    top: 18px;
    right: 10px;
    z-index: 11;
    outline: solid 2px white;
}

.displayResponseMessage_mobileCard__1fPXm{
    font-size: smaller;
    padding:2%;
    
}
.displayResponseMessage_headline__nYg-0{
    font-size: 12px;
    margin:3%;
    width:50%;
}
.displayResponseMessage_msgimgmobile__3IbDh {
    position: absolute;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    top: 15%;
    right: 12%;
    z-index: 11;
    outline: solid 2px white;
}
.displayResponseMessage_contentfieldmobile__1M27A{
    background-color: rgb(223, 219, 214);
}
.makeavs_container__17ICO{
    text-align:center;
    margin:auto auto;
}
.searchmessage_control__3lIsU{
    margin:20px;
   
     width:95%;
    display:flex;
    justify-content: space-between;
}
.searchmessage_input__EA5KU{
   padding:1%;
     font-size: 16px;
     border-width: 1px;
     border-color: #CCCCCC;
     background-color: #FFFFFF;
     color: #000000;
     border-style: hidden;
     border-radius: 8px;
     box-shadow: 0px 0px 5px rgba(66,66,66,.75);
     text-shadow: 0px 0px 1px rgba(66,66,66,.75);
   width:100%;
   margin-right:2%;
}
input:focus{
     outline:none;
}
.searchmessage_MyButton__1xEJQ {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:1% 2%;
	text-decoration:none;
	
    transition:1s;
    margin:0.2%;
}
.searchmessage_MyButton__1xEJQ:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.searchmessage_MyButton__1xEJQ:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    .searchmessage_control__3lIsU{
    margin:10px 5px;
   
     width:95%;
    display:flex;
    justify-content: space-between;
}
.searchmessage_MyButton__1xEJQ {
    font-size:13px;
}
}
.messagesforuser_buttonfield__uCAL3 {
    margin: auto;
    padding: 5px 2px;
    display:flex;
    flex-direction: row wrap;
    justify-content: space-between;
    width:100%;
}
.messagesforuser_buttonfield__uCAL3 > div {
    display: inline-block;
}
.messagesforuser_buttonfield__uCAL3 > div.messagesforuser_active__qrrO1 > button {
    text-decoration: underline;
}
.messagesforuser_buttonfield__uCAL3 > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    white-space: nowrap;
}
.messagesforuser_spacer__1-67V{
    margin:2px 10px;
}

.messagesforuser_spinning__xKh72 {
    width: 45px;
    height: 45px;
    -webkit-animation-name: messagesforuser_spin__11ISf;
            animation-name: messagesforuser_spin__11ISf;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; 
}

@-webkit-keyframes messagesforuser_spin__11ISf {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

@keyframes messagesforuser_spin__11ISf {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}


.cookiemodal_modal__2gnn7 {
  position: fixed;
  right: 5%;
  bottom:10%;
  max-width: 300px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
-webkit-animation:cookiemodal_slide-down__4aHY3 1s;
        animation:cookiemodal_slide-down__4aHY3 1s;
}
.cookiemodal_modaltop__pyMNJ{
  position: fixed;
  right: 5%;
  bottom:35%;
  max-width: 300px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
-webkit-animation:cookiemodal_slide-down__4aHY3 1s;
        animation:cookiemodal_slide-down__4aHY3 1s;
}
.cookiemodal_closex__1lfnQ{
    background: none;
    width:25px;
    border:none;
    height:25px;
}
.cookiemodal_closeBtn__H_foY {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:1000;
}

.cookiemodal_closeBtn__H_foY:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}



@-webkit-keyframes cookiemodal_slide-down__4aHY3 {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}



@keyframes cookiemodal_slide-down__4aHY3 {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.cookie_heads__1-6AO{
    font-weight:bold;
}
.cookie_buttons__SaGU8{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    grid-gap:10%;
    gap:10%;

}
.cookie_buttons__SaGU8 div{
    width:100%;
}
.cookie_text__1EYNU{
    font-size:0.9em;
}
.add_addcard__3a83O {
  margin-top: 1%;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px rgba(164, 164, 164, 0.5);

  text-decoration: none;
  color: white;
  margin: 4% 2%;
  background-size: 115% !important;

  transition: 1s;
 
  overflow: hidden;
  max-height: 200px ;
  border: 4px solid rgb(246, 108, 108, 0.5);
  text-decoration: none;
height:500px;
}
.add_headerwrapper__1dytC {
  position: relative;
  padding: 1%;
  height:100%;
}
.add_extrawrapper__3IpVw {
  position: absolute;
  bottom: 0vw;
  text-shadow: 2px 2px 2px #777;
  height:100%;
}
.add_header__1sAMe {
  position:relative;
   text-decoration: none;
  color: white;
  text-align: left;
font-size:1.1em;
  padding: 1%;
  top:0%;
}
.add_image__2SPbO {
 
  height: 100%;
}

.add_addcontent__X1Z0z {
  position:relative;
  top:20%;
  text-decoration: none;
  color: white;
  padding: 1% 3%;
  font-size:1em;
}
.add_addcard__3a83O a {
  text-decoration: none;
}

.add_addcard__3a83O:hover {
  background-position: left top !important;
  background-size: 130% !important;
  background-repeat: no-repeat !important;
}
.add_anzeigetext__-cqFR {
  position: absolute;
  z-index: 1;
  right: 10px;
bottom:0;
  color: var(--primary);
  font-weight: 600;
  text-decoration: none;
  
}
.add_anzeigetext__-cqFR a {
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {

.add_addcard__3a83O {
    max-height: 140px !important;
}
.add_header__1sAMe {

  font-size:0.9em;
}
.add_addcontent__X1Z0z {font-size:0.83em;}
}
.mybuttonrund_btn__10Vzi {
  
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;

  font-weight: 800;
  font-size: 12px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.mybuttonrund_btn__10Vzi:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.mybuttonrund_btn__10Vzi a {
text-decoration: none;
}
.allapishort_contain__O2Dv6{
 height:100%;
 position:fixed;
  overflow-y:scroll;
 z-index:1;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
max-width:310px;
margin:auto;
}

.allapishort_contain2__2ROru{
 height:100%;

 position:fixed;
  overflow-y:scroll;
 z-index:1;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
max-width:310px;
width:21%;

}
.allapishort_bottombouncer__gmpTf{
    height:150px;
    background-color: transparent;
}
.allapishort_contain__O2Dv6::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.allapishort_contain2__2ROru::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.allapishort_footerelement__1z-ur{
    height:12vh;
   
    width:100%;
   
}
.allapishort_shortadd__1bOsV{width:100%;}
.allapishort_add__2dwZ9{
    max-width:310px;

    margin:auto;
     overflow:hidden;
    color: #000;
    padding: 0px;
    width: 100%;
    margin-left:0;
}
.allapishort_wrappadds__3Cb9u{
 width:100%;
}
@media (max-width: 768px) {

    .allapishort_contain__O2Dv6{display:none;}
}

.dynafilters_leftmenue__wDTWq {
  margin-top: 30px;
cursor:pointer;
  width: 100%;
}
.dynafilters_leftmenuedm__10KSa {
  margin-top: 30px;
cursor:pointer;
  width: 100%;
}
.dynafilters_icon__10bDA {
  margin: 0;
  padding: 0;
}
.dynafilters_leftmenue__wDTWq ul {
  list-style: none;
  width: 100%;
}

.dynafilters_leftmenue__wDTWq li {
  margin-right: 0px;
  padding: 0px;
}
.dynafilters_extraMail__20pLn {
  background-color: aliceblue;
  color: var(--primary);
  border-radius: 20px;
  position: absolute;
  box-shadow: 3px 3px 3px rgb(20, 20, 20, 0.2);
  padding: 2px 8px;
  border: 1px solid var(--primary);
  top: 0;
  right: 30%;
  font-weight: bold;
}
.dynafilters_logowrapper__Gu9ll {
  position: relative;
}
.dynafilters_logo__3boCH {
    position: relative;
  width: 130px;
  margin-bottom: 5vh;
  z-index:50;
  
}
.dynafilters_profilelogo__2hxzw {
  position: absolute;
  width: 93px;
  border-radius: 50%;
  height: 93px;
  z-index:1;
  left:3px;
  top:3px;
  object-fit: cover;
}
.dynafilters_leftmenueextra__3rIus {
  position: relative;
}
.dynafilters_leftmenue__wDTWq ul li {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.25em;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.dynafilters_leftmenue__wDTWq a {
  color: #333;
  margin-top: -10px;
  padding-bottom: 1%;
  margin-bottom: -1%;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.dynafilters_leftmenue__wDTWq a:hover {
  color: #000000;
  text-decoration: none;
  width: 100%;
  background: #efefef;
  border-radius: 15px;
}

.dynafilters_leftmenuedm__10KSa ul {
  list-style: none;
  width: 100%;
}

.dynafilters_leftmenuedm__10KSa li {
  margin-right: 0px;
  padding: 0px;
}

.dynafilters_leftmenuedm__10KSa ul li {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.25em;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}

.dynafilters_leftmenuedm__10KSa a {
  color: #333;
  margin-top: -10px;
  padding-bottom: 1%;
  margin-bottom: -1%;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}
.dynafilters_contain__3OflU {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.dynafilters_leftmenuedm__10KSa a:hover {
  color: #000000;
  text-decoration: none;
  width: 100%;
  background:#05022250;
  border-radius: 15px;
}

.dynafilters_updownarrow__2Trm5 {
  display: block;
  margin: 0 auto;
  width: 20px;
  cursor: pointer;
}
/*  ###########################################  */
/*  LIGHTMODE ICONS                              */
/*  ###########################################  */ 
.dynafilters_icon1__3jYXd::before {
  content: url(/images/desktop_menue_icons/timeline.png)" ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon2__16dgm::before {
  content: url(/images/desktop_menue_icons/favoriten.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon3__3eXFb::before {
  content: url(/images/desktop_menue_icons/login.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon4__sQGiI::before {
  content: url(/images/desktop_menue_icons/kartehinzufuegen.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon5__oGO_r::before {
  content: url(/images/desktop_menue_icons/inbox.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon6__kqccO::before {
  content: url(/images/icons/benachrichtigung.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon7__3BnGh::before {
  content: url(/images/icons/lesezeichen.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon8__4WIyg::before {
  content: url(/images/desktop_menue_icons/channels.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon9__1QVh9::before {
  content: url(/images/desktop_menue_icons/werdecreator.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon10__EVCFG::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/gekauft.png) " ";
  padding: 5px 0 5px 0;
}

/*  ###########################################  */
/*  DARKMODE ICONS                               */
/*  ###########################################  */ 
.dynafilters_icon1dm__OHZ4C::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/timeline_dm.png)" ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon2dm__3r7zI::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/favoriten_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon3dm__2UDNV::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/login_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon4dm__2JIwH::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/kartehinzufuegen_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon5dm__1EMYj::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/inbox_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon6dm__1GuRq::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/benachrichtigung.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon7dm__2g4Ec::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/lesezeichen.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon8dm__1jC0-::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/channels_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon9dm__tur_4::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/desktop/werdecreator_dm.png) " ";
  padding: 5px 0 5px 0;
}
.dynafilters_icon10dm__3hC0W::before {
  content: url(https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/gekauft_dm.png) " ";
  padding: 5px 0 5px 0;
}

.dynafilters_xtrabtn__3ts57 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.dynafilters_smallnav__1e6jK {
  text-align: center;
  margin-top: -10%;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
}
.dynafilters_bignav__hVUF9 {
  text-align: center;
  margin-top: -5%;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
}
.dynafilters_fandollar__adZvh {
  cursor: pointer;
  color: #666;
  font-size: 0.9rem !important;

  display: inline-block;

  margin: auto;
}
.dynafilters_fandollarimg__JTNfs {
  width: 20px;
  margin-left: 5px;
  color: var(--primary);
  background: none;
}
@media (max-width: 768px) {
  .dynafilters_outlinegone__3zo62 {
    width: 123%;
  }
  .dynafilters_leftmenue__wDTWq {
    margin: auto;
  }
  .dynafilters_leftmenue__wDTWq ul {
    padding: 0;
  }
  .dynafilters_xtrabtn__3ts57 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .dynafilters_leftmenue__wDTWq ul li {
    margin-top: 0px !important;
  }
}

@media (max-width: 368px) {
  .dynafilters_fandollarimg__JTNfs {
    display: none;
  }
  .dynafilters_outlinegone__3zo62 {
    width: 80%;
  }
}

.desktopheader_dropdownelementwrapper__11oed{
  float:right;
}
.desktopheader_dropdownmenu__bLO0_{

position:absolute;
right:20px;
  padding-right:15px;

}
.desktopheader_dropdownmenulogout__2Gcjn{

position:absolute;
right:20px;
  padding-right:15px;
  margin-top:-5px;
}
.desktopheader_loginbutton__uZ61M{
  margin-right:100px;
}
.desktopheader_countrymaker__1_3IE{
  position: fixed;
top:1%;
  left:25%;
  cursor:pointer;
}
.desktopheader_dropdownmenu__bLO0_ img{
    width:40px;
}
.desktopheader_dropdownmenulogout__2Gcjn img{
    width:40px;
}

.desktopheader_dropdownmenu__bLO0_ div{
  width:100%;
 
  overflow:hidden;
  border-radius:10px;
  box-shadow: 2px 2px 2px rgb(20,20,20,0.2);
}
.desktopheader_dropdownmenulogout__2Gcjn div{
  width:100%;
 
  overflow:hidden;
  border-radius:10px;
  box-shadow: 2px 2px 2px rgb(20,20,20,0.2);
}

.desktopheader_dropdown__2Z80Y{
  display:flex;
  flex-direction: row;
  cursor:pointer;
 
  padding:2px 4px !important;
  margin:2px 2px;
  width:100%!important;
  box-shadow:none!important;
  color:var(--primary);
    text-decoration: none;
}
.desktopheader_dropdownsmall__209QG{
  display:flex;
  flex-direction: row;
  cursor:pointer;
 font-weight:normal;
  padding:2px 4px !important;
  margin:2px 2px;
  width:100%!important;
  box-shadow:none!important;
  color:var(--primary);
    text-decoration: none;
}
.desktopheader_dropdown__2Z80Y:hover{
  background-color:rgb(200, 50, 50,0.3);
  text-decoration: none;
  color:var(--primary);
}
.desktopheader_dropdown__2Z80Y img{
  width:20px;
  height:20px;
  margin-right:5px;
  margin-left:2px;
}
.desktopheader_dropdown__2Z80Y:a{
    color:var(--primary);
    text-decoration: none;
}
.desktopheader_fandollar__3_2Kj{
cursor:pointer;
  color:#fff;
  top:10px;
  font-size: 0.9rem!important;
border:2px solid white;
border-radius:10px;
padding:2px;
display: inline-block;
position:absolute;
left:46%;
width:8%;
min-width:120px;
margin:auto;
display:flex;
flex-flow:row;
justify-content: space-evenly;

}
.desktopheader_language__GpL-b{
cursor:pointer;
  color:#fff;
  top:10px;
  font-size: 0.9rem!important;

border-radius:10px;
padding:2px;
display: inline-block;
position:absolute;
left:2%;
width:8%;
min-width:120px;
margin:auto;
display:flex;
flex-flow:row;
justify-content: space-evenly;

}
.desktopheader_fandollarimg__1ogpf{
  width:20px;
  margin-left:5px;
  color:var(--primary);
background:none;
}
.desktopheader_login__xQebS{
    position:absolute;
    top:10px;
    right:20px;
    display:flex;
    flex-flow:row;
    
}
.desktopheader_active__31EjP{font-weight:bold;}
.desktopheader_inactive__3231f{color:#000;}

.desktopheader_payAttention__3K1nt{
  position:absolute;
  right:20%;
  top:10px;
  width:40px;
  -webkit-filter:brightness(0) invert(1);
          filter:brightness(0) invert(1);
   -webkit-animation: desktopheader_shake__FH_Fb 1.5s;
           animation: desktopheader_shake__FH_Fb 1.5s;

  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes desktopheader_shake__FH_Fb {
  0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  10% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  20% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
  30% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  40% { -webkit-transform:  rotate(1deg); transform:  rotate(1deg); }
  50% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  60% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  70% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  80% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
  90% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
}
@keyframes desktopheader_shake__FH_Fb {
  0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  10% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  20% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
  30% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  40% { -webkit-transform:  rotate(1deg); transform:  rotate(1deg); }
  50% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  60% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg); }
  70% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
  80% { -webkit-transform: rotate(1deg); transform: rotate(1deg); }
  90% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(-1deg); transform: rotate(-1deg); }
}

.desktopheader_inputElement__3Pexs input {
  font: inherit;
  background-color: #fce1e4;
  color: var(--primary);
  border-radius: 4px;
  border: 1px solid #fce1e4;

  text-align: left;
  padding: 0.25rem;
  margin: 5px;
}
.desktopheader_inputElement__3Pexs {
  width: 100%;
}
.desktopheader_iconinactive__2KvPI {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 30px;
  cursor: pointer;
}
.desktopheader_iconactive__q9GLM {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  width: 30px;
  cursor: pointer;
}
.desktopheader_infotext__f7hPr {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
 height:210px;
  margin-bottom: 5px;
 overflow:hidden;
}
.desktopheader_profpicwrapper__1HPt7 {
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  width: 100%;
}
.desktopheader_area__1XIYC {
  width: 100%;
  height: 200px;
}
.desktopheader_personalbox__snFO9{
 
  padding:3px;
  width:99%;
  margin:auto;
  margin-bottom:10px;
}
.desktopheader_toright__bmXLy{
  position:relative;
 
  right:5px;
  float:right;
}
.desktopheader_desctext__2mdHp{
  max-height:120px;
  overflow:hidden;
}

.desktopheader_toggle__DjNA2 {
	 --size: 1.1rem;
	 -webkit-appearance: none;
	         appearance: none;
	 outline: none;
	 cursor: pointer;
	 width: var(--size);
	 height: var(--size);
	 box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
	 border-radius: 999px;
	 color: #a2a2a2;
	 transition: all 500ms;
   margin-top:2px;
}
 .desktopheader_toggle__DjNA2:checked {
	 --ray-size: calc(var(--size) * -0.4);
	 --offset-orthogonal: calc(var(--size) * 0.65);
	 --offset-diagonal: calc(var(--size) * 0.45);
	 -webkit-transform: scale(0.75);
	         transform: scale(0.75);
	 color: #fa0;
	 box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}
.footer_footer__1pt2Q{
    color:black;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 10vh;
    width:100%;
  position:relative;
 grid-gap:20px;
 gap:20px;
  background-color: var(--primaryBright);
  padding:1%;
  z-index:1;
}
.footer_footerlinks__337Pi{
    color:black;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
   
    width:90vw;
    margin:auto;

 

}
.footer_footertext__1PUN_{
    color:black;
text-decoration: none;


font-size:1rem;
font-weight:normal;
}
.footer_center__nktUv{
  width:100%;
  text-align:center;
}
.smallmainpagedesktop_smaller__1zXkK{
    max-width:1400px;
    margin:auto;
    background-color: rgb(233, 235, 235);
        box-shadow: 6px 3px 5px -4px rgba(183, 192, 205, 0.1), -8px 0 4px -4px rgba(201, 207, 216, 0.1);
  width:100vw;
}
.smallmainpagedesktop_smallerdm__2sD6M{
    max-width:1400px;
    margin:auto;
    background-color: #262540;
    width:100vw;
}
.smallmainpagedesktop_thingie__2aXlP{
    min-height:100vh;
}
.smallmainpagedesktop_header__3OoFd{
    z-index:100;
    width:100vw;
 
    position:fixed;
   
    max-width:1400px;
    background-color: var(--primary);
    height:50px;
    color:white;
    padding:5px 10px;
}
.smallmainpagedesktop_rechteSpalte__R61-H{
    position:fixed;
    max-height:100%;
    z-index:0;
    width:100%;
    top:70px;

   
    
}
.smallmainpagedesktop_rightelement__2piax{
    height:110vh;
}
.smallmainpagedesktop_bottombouncer__18rgW{
    height:200px;
    position:relative;
    bottom:0;
}
.smallmainpagedesktop_rechteSpaltedm__gd0_v{
    position:fixed;
    max-height:100%;
    z-index:0;
    width:100%;
    top:70px;
   
   
    
}
.smallmainpagedesktop_middleElement__XSzxv{
     box-shadow: 3px 3px 5px -4px rgba(31, 73, 125, 0.1), -4px 0 4px -4px rgba(31, 73, 125, 0.1);
    margin-top:50px;
    padding-top:10px;
    min-height:100vh;
     background-color: white;
}
.smallmainpagedesktop_middleElementdm__ddHr1{
     box-shadow: 3px 3px 5px -4px rgba(31, 73, 125, 0.1), -4px 0 4px -4px rgba(31, 73, 125, 0.1);
    margin-top:50px;
    padding-top:10px;
    min-height:100vh;
     background-color: var(--nearBlack);
     
}
.smallmainpagedesktop_linkeSpalte__1icwv{
width: 16.36666667%;
 height:100%;
 min-height:1003px;
position:fixed;
overflow:scroll;
max-width:380px;
 z-index:1;
top:50px;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */


}
.smallmainpagedesktop_linkeSpalte__1icwv::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */}
    .smallmainpagedesktop_linkerwrapper__3Ql6i::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */}
.smallmainpagedesktop_linkerwrapper__3Ql6i{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

   
}

@media only screen and (min-width: 1400px) {
  .smallmainpagedesktop_linkeSpalte__1icwv {width:222px}
}

.boughtItemsTimeline_wrapper__11Cgg{
    border:1px solid grey;
    border-radius: 5px 5px 0 0;
    margin:5px;
    padding:5px;
    cursor:pointer;
}
.boughtItemsTimeline_header__3HXwh{
    font-size:1em; font-weight:bold;
}
.boughtItemsTimeline_headerWrapper__16ibX{display:flex;
flex-flow:row;
text-overflow: ellipsis;
justify-content: space-between;}
.boughtItemsTimeline_content__3Xz0w{

    font-weight:bold;
    text-overflow: ellipsis;
    font-size:1.2em;
    line-clamp: 2;
}
.boughtItemsTimeline_listofgirls__15b8p{
    display:flex;
    flex-flow:row wrap;
    grid-gap:8px;
    gap:8px;
    justify-content: space-evenly;
    margin-bottom:10px;
}
.boughtItemsTimeline_girl__19ANT{
    cursor:pointer;
    border:1px solid grey;
    padding:3px 6px;
    border-radius:4px;
    background-color: #f6f6f6;
}
.boughtItemsTimeline_girl__19ANT:hover{
    transition:0.6s;
    background-color: #efefef;
}
.boughtItemsTimeline_girl_dm__3xZad{
    cursor:pointer;
    border:1px solid grey;
    padding:3px 6px;
    border-radius:4px;
    background-color: #262540;
}
.boughtItemsTimeline_girl_dm__3xZad:hover{
    transition:0.6s;
    background-color: #302f48;
}
.boughtcontentgirlcard_btn__2PVC4 {
    display: inline-block;
    font: inherit;
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    cursor: pointer;
}

.boughtcontentgirlcard_btn__2PVC4:focus {
    outline: 0.5rem auto #4d90fe;
}

/* Profile Section */

.boughtcontentgirlcard_profile__2JzfB {
    padding: 1rem 0;
	border-bottom:1px solid var(--primary);
    display:flex;
    flex-flow:row;
    grid-gap:10px;
    gap:10px;
    cursor:pointer;
}
.boughtcontentgirlcard_rightcontainer__hA8aA{
    display: flex;
    flex-flow:column;
}
.boughtcontentgirlcard_profileImage__1yDBu {
    float: left;
   
}

.boughtcontentgirlcard_profileImage__1yDBu img {
     border-radius: 50%;
  object-fit: cover;
  object-position: 20%;
  width: 90px;
  height: 90px;
  border: 3px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.boughtcontentgirlcard_profileUserSettings__26zek {
    margin-top: 0.1rem;
}

.boughtcontentgirlcard_profileUserName__2TXKF {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  color:var(--primary);
}

.boughtcontentgirlcard_profileEditBtn__2wnxm {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 1.4rem;
  font-size: 1.0rem;
}

.boughtcontentgirlcard_profileStats__38OFQ {
    margin-top: 1rem;
}

.boughtcontentgirlcard_profileStats__38OFQ li {
    display: inline-block;
    font-size: 1.0rem;
    line-height: 1.5;
    margin-right: 3rem;
    cursor: pointer;
}

.boughtcontentgirlcard_profileStatCount__3VMry,
.boughtcontentgirlcard_profileEditBtn__2wnxm {
    font-weight: 600;
}
@media screen and (max-width: 40rem) {
    .boughtcontentgirlcard_profile__2JzfB {
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 0;
    }

    .boughtcontentgirlcard_profile__2JzfB::after {
        display: none;
    }

    .boughtcontentgirlcard_profileImage__1yDBu,
    .boughtcontentgirlcard_profileUserSettings__26zek,
    .boughtcontentgirlcard_profileStats__38OFQ {
        float: none;
        width: auto;
    }

    .boughtcontentgirlcard_profileImage__1yDBu img {
        width: 7.7rem;
    }

    .boughtcontentgirlcard_profileUserSettings__26zek {
        flex-basis: calc(100% - 10.7rem);
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .boughtcontentgirlcard_profileUserName__2TXKF {
        font-size: 2.2rem;
    }

    .boughtcontentgirlcard_profileEditBtn__2wnxm {
        order: 1;
        padding: 0;
        text-align: center;
        margin-top: 1rem;
    }

    .boughtcontentgirlcard_profileEditBtn__2wnxm {
        margin-left: 0;
    }

    .boughtcontentgirlcard_profileEditBtn__2wnxm,
    .boughtcontentgirlcard_profileStats__38OFQ {
        flex-basis: 100%;
    }

    .boughtcontentgirlcard_profileStats__38OFQ {
        order: 1;
        margin-top: 1.5rem;
    }

    .boughtcontentgirlcard_profileStats__38OFQ ul {
        display: flex;
        text-align: center;
        padding: 1.2rem 0;
        border-top: 0.1rem solid #dadada;
        border-bottom: 0.1rem solid #dadada;
    }

    .boughtcontentgirlcard_profileStats__38OFQ li {
        font-size: 1.4rem;
        flex: 1 1;
        margin: 0;
    }

    .boughtcontentgirlcard_profileStatCount__3VMry {
        display: block;
    }
}
