.wrapper {
  position: fixed;
  top: 45px;
  z-index: 50;
  width: 58%;
  height: 2px;
  transition: 0.4s;
}
.wrappermobile {
  position: fixed;
  top: 65px;
  z-index: 50;
  width: 100%;
}
.wrappermain {
  position: fixed;
  top: 45px;
  z-index: 50;
  width: 61.4%;
  height: 2px;
  transition: 0.4s;
  margin-left: -12px;
}
.bar {
  width: fit-content;
  transition: 0.4s;
}
.bar img {
  width: 30px;
  transition: 0.9s;
}

.fullbar {
  z-index: 550;
  position: fixed;
  top: 50px;
  max-width: 833px;
  margin-left: -1px;
  height: 40px;
  background-color: #e9ebeb;
  color: black;
  width: 61.4%;
  transition: 0.4s;
  display: flex;
  flex-direction: row wrap;
}
.fullbarmobile {
  z-index: 550;
  position: fixed;
  top: 70px;
  max-width: 833px;
  margin-left: -1px;
  height: 40px;
  background-color: #e9ebeb;
  color: black;
  width: 105%;
  transition: 0.4s;
  display: flex;
  flex-direction: row wrap;
}
.fullbar2 {
  z-index: 550;
  margin-top: 3px;
  height: 70px;
  background-color: #e9ebeb;
  color: black;
  width: 100%;
  transition: 0.4s;
}

.innerflex {
  width: 100%;
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  gap: 40px;
}
.innerflex input {
  margin: 13px 10px;
}
.fullbar img {
  width: 30px;
  float: left;
}

@media (min-width: 1130px) {
}

.allwrapper {
  position: relative;
  height: 20px;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;

  margin: 0;
  padding: 0;
}
.allfullbar {
  position: fixed;
  top: 50px;
  background-color: #fff;
  width: 60%;
  height: 30px;
}

@media (min-width: 1400px) {
  .allfullbar {
    width: 800px;
  }
}

@media (max-width: 768px) {
  .allfullbar {
    width: 900%;
    top: 70px;
  }
}
