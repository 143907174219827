.userimagesmall {
    width: 50px;
    border-radius: 100%;
    height: 50px;
    border: 2px solid white;
}

.reactions {
    display: flex;
    flex-direction: reverse row;
    gap: 5px;
    float: right;
}

.combody:hover {
    background-color: rgb(100, 100, 100, 0.1);
    transition: 0.6s;
}

.combody {
    padding: 1%;
    border-bottom: 1px solid #dddddd;
    margin-left: 0.4%;
    margin-right: 0.4%;
    font-size: 0.8em;
    line-height: 0.5em;
}

.commentdate{
    font-size: 0.9em;
    font-weight: normal;
}
.mobileText {
    font-size: 1em;
    line-height: 1em;
}

.mobilepad {
    margin-top: 5px;
}

.reactiones {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.grayscaled {
    width: 20px;
    height: 20px;
    cursor: pointer;
    filter: grayscale(0.6)
}