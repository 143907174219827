.btn {
    display: inline-block;
    font: inherit;
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    cursor: pointer;
}

.btn:focus {
    outline: 0.5rem auto #4d90fe;
}

/* Profile Section */

.profile {
    padding: 1rem 0;
	border-bottom:1px solid var(--primary);
    display:flex;
    flex-flow:row;
    gap:10px;
    cursor:pointer;
}
.rightcontainer{
    display: flex;
    flex-flow:column;
}
.profileImage {
    float: left;
   
}

.profileImage img {
     border-radius: 50%;
  object-fit: cover;
  object-position: 20%;
  width: 90px;
  height: 90px;
  border: 3px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.profileUserSettings {
    margin-top: 0.1rem;
}

.profileUserName {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  color:var(--primary);
}

.profileEditBtn {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 1.4rem;
  font-size: 1.0rem;
}

.profileStats {
    margin-top: 1rem;
}

.profileStats li {
    display: inline-block;
    font-size: 1.0rem;
    line-height: 1.5;
    margin-right: 3rem;
    cursor: pointer;
}

.profileStatCount,
.profileEditBtn {
    font-weight: 600;
}
@media screen and (max-width: 40rem) {
    .profile {
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 0;
    }

    .profile::after {
        display: none;
    }

    .profileImage,
    .profileUserSettings,
    .profileStats {
        float: none;
        width: auto;
    }

    .profileImage img {
        width: 7.7rem;
    }

    .profileUserSettings {
        flex-basis: calc(100% - 10.7rem);
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .profileUserName {
        font-size: 2.2rem;
    }

    .profileEditBtn {
        order: 1;
        padding: 0;
        text-align: center;
        margin-top: 1rem;
    }

    .profileEditBtn {
        margin-left: 0;
    }

    .profileEditBtn,
    .profileStats {
        flex-basis: 100%;
    }

    .profileStats {
        order: 1;
        margin-top: 1.5rem;
    }

    .profileStats ul {
        display: flex;
        text-align: center;
        padding: 1.2rem 0;
        border-top: 0.1rem solid #dadada;
        border-bottom: 0.1rem solid #dadada;
    }

    .profileStats li {
        font-size: 1.4rem;
        flex: 1;
        margin: 0;
    }

    .profileStatCount {
        display: block;
    }
}