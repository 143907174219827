
 .boxit{
        border:3px solid white;
        border-radius:8px;
        margin:2% 0;
        padding:1%;
        background:#dfa9ab;
    }
.auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: var(--primary);
    box-shadow: 0 1px 4px rgba(46, 21, 21, 0.2);
    padding: 1rem;
    text-align: center;
  }
  .boxit h5{
    color: #333;
  }
  .auth h1  {
    text-align: center;
    color: white;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    color: #333;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    font: inherit;
    background-color: #f2e6e8;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    cursor: pointer;
    font: inherit;
  
    padding: 0.5rem 2.5rem;
  }
 
  
  .actions .toggle {
    margin-top: 1rem;
  

    padding: 0.15rem 1.5rem;
  }
  

  .white{
    color:#fff;
  }
  .logininfo{
    text-align:center;
  }