
body{
  min-height:100lvh;
  max-width:100vw;
  overflow-x: hidden;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-display: swap;
}
@font-face {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-display: swap;
}
.main{
  background-color: #efefef;
  color: var(--nearBlack);
     max-width:100vw;
  overflow-x: hidden;
 min-height: 100vh;
}
.bigbutton{
margin-top:20px;
}
.bigger{
  margin-top:20px;
  padding:10px;
  font-size:1.5em;
}
.head{
  font-size:1.2em;
}
.isnapstart{
  padding:10px;
  margin:auto;
  margin-top:20px;
  text-align: center;
}
.maindm{
  background-color: var(--nearBlack);;
  color:#efefef;
     max-width:100vw;
  overflow-x: hidden;
   min-height: 100vh;
}
.testclass{
  filter:grayscale(1);
}