.grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap:5px;
  margin-bottom:10px;
}
.icon {
  height: 50px;
  width:max-content;
  transition: 0.5s;
  cursor: pointer;
   filter:grayscale(0.8);
}
.icon:hover {
  filter:grayscale(0);
  transition: 0.5s;
}
.girlname{color:var(--primary);font-weight:bold;}
.centerme{
  margin:auto;
  text-align:center;
  display: flex;
  flex-flow:column;
  justify-content: center;
}
.elements{
  
  transition: 1.5s;
  display:flex;
  flex-flow:column;
  overflow:hidden;
  padding:3px;
  border-radius:7px;
}
.elements:hover{
    background-color:aquamarine;
    transition: 1.5s;
}
.clicked{
  background-color:aquamarine;
 
}
.topdown{
  margin-top:10px;
}
.topdown h1{
  font-size:1.6em;
}
.profilePic{
 display: inline-block;
  width: 110px;
  height: 110px;
  border-radius: 50%;
margin-top:-60px;

  object-fit: cover;
  border:3px solid #999999;
}
.profilename{
  color:var(--primary)
}


@media (max-width: 780px) {.profilePic{
 display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
margin-top:-60px;
  object-fit: cover;
  border:3px solid #999999;
}
.topdown h1{
  font-size:1em;
}
.trinkgeldtext{
  font-size:0.9em;
 
}
}