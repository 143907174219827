.darkBG {
    
 position:fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  right:0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width:100vw;
  max-height:100vh;
  z-index:30;
  
}
.centeredfull{
   position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width:90vw;
  min-height:90vh;
  max-width:100vw;
  max-height:100vh;
  z-index:30;

}
.centereddm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width:100vw;
  max-height:100vh;
  z-index:30;
  
}
.centeredfulldm{
  background-color: #030222;
   position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width:90vw;
  min-height:90vh;
  max-width:100vw;
  max-height:100vh;
  z-index:30;

}
.closex{
    background: none;
    width:25px;
    border:none;
}

.modal {
  position:relative;
  width:100%;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: white;
  color: white;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}
.modalfull {
  position:relative;
  width:100vw;
  height:90vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: rgb(255, 255, 255,0.8);
  color: rgb(255, 255, 255);
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modaldm {
  position:relative;
  width:100%;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: white;
  color: white;
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}
.modalfulldm {
  position:relative;
  width:100vw;
  height:90vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width:40vw;
  min-height:20vh;
  background: #030222;
  color: rgb(255, 255, 255);
  z-index: 100;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  padding:3%;
  overflow: hidden;
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 100;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:100;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: var(--primaryBright);
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 162, 78, 0.6);
  transform: translateY(-5px);
  background: var(--primaryBright);
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
.modal{
  padding:2%;
  color:black
}
.inactive{
  background-color: beige;
  color:#2c3e50;
}
.checkbox{
  margin-right:1%;
}
.bigimage{
    width:100%;
    max-height:90vh;
}
  
    @media (max-width: 768px) {

      .centered{
        width:90%;
      }
    }