.videowrapper {
  width: 100%;
 
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.videowrapper:hover .controls {
  transform: translateY(0%);
}

.videowrapper:hover .controls {
  transform: translateY(0%);
}

.container video{
    width:auto;
    height:auto;
    max-width:100%;
    max-height:100%;
}
.nocontrolcontrol{
display: none;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;

  bottom: -50px;
  padding: 14px;
  width: 100%;

  flex-wrap: wrap;
  
}
.noactions{
  width:fit-content;
}

.controls{
      display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.controls input{
     -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}
.controls input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
  
  
}

.controls input[type="range"]::-moz-range-progress {
  background: white;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.bx{
    width:25px;
}
.btn{
    background: none;
    outline: none;
    border:none;
}
.containermobile{

    width:100%;
}
.mobilecontrol{
display:flex;
justify-content: space-evenly;
    width:90vw;
    margin:auto;
}

.inputmobile{
    -webkit-appearance: none !important;
color:var(--primary);
 background-color: none!important;

    border-radius: 20px;
  height: 20px;
  max-width:100%;
  margin-top:1%;
}
.inputmobile input::-webkit-slider-thumb{
color:green;
 background-color: aqua;
}