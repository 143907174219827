.flex{
margin-top:20px;
    display:flex;
    flex-flow: row;
    justify-content: space-between;
}

.imageList{
    list-style: none;
    margin:0;
    padding:0;
    display:flex;
    flex-flow:row;
    flex-wrap: wrap;
    gap:1%;
    justify-content: space-evenly;
}
.imageListElement{
    max-width:48%;
    margin-bottom:1%;
}

.closex{
    background: none;
    width:25px;
    border:none;
}
.closeBtn {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:1000;
}

.maxImage{
   width:100%;
   height:100%;
max-width:99vw;
max-height:99vh;
    
}

.extra{
display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 100vh;
}
.imageelement{
    max-height:50vh;
    max-width:100%;
    margin:auto;
    cursor:pointer;
     transition: 0.4s;
}
.imageelement:hover{
    transform:scale(0.97);
    transition: 0.2s;
}
.imagewrapper{
    align-content: center;
    display: flex;
max-width: 90vw;
background-color: rgb(188,28,15,0.01);
padding:4px;

}
.moreImage{
    max-width:100%;
}