.maxwidth {
  width: 100%;
}

.element {
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: center;
}

.elementinner {
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.mobelementouter {
  width: 100%;
}
.mobelement {
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(14px) grayscale(0.7);
}
.killit {
  text-decoration: line-through;
}
.rightside {
  text-align: right;
  margin-right: 5px;
}
.mobelementinner {
  max-width: 100vw;
  max-height: 90vh;
  position: relative;
  -webkit-filter: blur(0);
  filter: blur(0);
  margin: auto;
  border: 2px solid white;
  scroll-snap-align: start;
}
.maincontentcontainer {
  margin: 10px;
  padding: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid #22222220;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}
.pinned {
  background-color: var(--primary)05;
  margin: 10px;
  padding: 5px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.pinneddm {
  background-color: #301010;
  color: #efefef;
  margin: 10px;
  padding: 10px;
  border: 1px solid #272540;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}
.maincontentcontainernew {
  background-color: #ffffff;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 2px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.maincontentcontainernewduenn {
  background-color: #ffffff;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 7px 2px #e5e5eb;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
/* DARKMODE CSS */
.maincontentcontainerdm {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding: 10px;
  border: 1px solid #272540;
  border-radius: 10px;
  scroll-snap-align: start;
  overflow: hidden;
}

.maincontentcontainernewdm {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px #000000;
  border: 2px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}
.maincontentcontainernewdmduenn {
  background-color: var(--nearBlack);
  color: #efefef;
  margin: 10px;
  padding-bottom: 6px;
  border-radius: 10px;
  box-shadow: 0px 4px 7px 2px #000000;
  border: 1px solid var(--primary);
  scroll-snap-align: start;
  overflow: hidden;
}

.newdm {
  text-align: right;
  padding: 5px;
  margin-right: 10px;
  color: var(--primary);
  position: relative;
}

.pseudonamedm {
  color: var(--primary) !important;
}
/* DARKMODE CSS  - End */

.new {
  text-align: right;
  padding: 5px;
  margin-right: 10px;
}

.newcontainer {
  margin-bottom: 3px;
  width: 100%;
}

.pseudoname {
  color: var(--primary);
  font-weight: bold;
}

.showpos {
  position: absolute;
  z-index: 250;
  color: var(--primary);
  font-weight: bold;
  top: 10px;
  right: 10px;
}
.fallbackimage {
  width: 100%;
}
.texttoright {
  text-align: right;
  color: grey;
}

.likecontainer {
  position: relative;
  cursor: pointer;
}
.linkinner {
  color: #fff !important;
  text-decoration: none;
}
.headerimage {
  width: 104%;
  margin-left: -2%;
  margin-top: -2%;
  max-height: 160px;
  object-fit: cover;

  object-position: top center;
}
.likenumber {
  position: absolute;
  z-index: 5;
  width: 50px;
  top: 3px;
  display: flex;
  color: var(--primary);
  font-weight: bold;
  justify-content: center;
}

.likenumberbig {
  position: absolute;
  z-index: 5;
  top: 5px;
  left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 0.7em;
}

.likenumbermiddle {
  position: absolute;
  z-index: 5;
  top: 3px;
  left: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 0.8em;
}

.icon {
  width: 50px;
  transition: 0.5s;
  cursor: pointer;
}

.icongray {
  width: 50px;
  filter: grayscale(1);
  transition: 0.5s;
  cursor: pointer;
}

.flexit {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.flexitright {
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  gap: 20px;
}

.subheading {
  font-size: 1em;
  font-weight: 500;
  padding-left: 2%;
}

.heading {
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 20px;
}
.headingshort {
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 10px;
}
.headingmedium {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 10px;
}
.headinglong {
  font-size: 1em;
  font-weight: 700;
  margin-top: 0px;
}

.topaywrapper {
  position: relative;
  width: 100%;
  top: 20%;
}

.topaytext {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 1.6em;
}

.topaytext h5 {
  margin-bottom: 50px;
}

.topaybg {
  margin: auto;
  filter: grayscale(0.6);
  object-fit: cover;
  display: flex;
  justify-content: center;
}

.marker {
  margin-left: 4px;
}

.iconsm {
  height: 15px;
  margin-top: 5px;
}

.flexitsm {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.showcommentbtn {
  width: 100%;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: var(--primary);
  background-color: #fafbfc;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.showcommentbtn:hover {
  color: #ffffff;
  background-color: var(--primary);
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
  transition-duration: 0.1s;
}

.showcommentbtndm {
  width: 100%;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #ffffff;
  background-color: #1a184b;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.showcommentbtndm:hover {
  color: #ffffff;
  background-color: var(--nearBlack);
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
  transition-duration: 0.1s;
}

.reportbtn {
  margin: 5px;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: 1px solid #33333350;
  font-weight: 600;
  background: #fff;
  padding: 0 8px 1px;
  font-size: 12px;
  border-radius: 4px;
  color: rgb(29, 28, 29);
  height: 22px;
  min-width: 56px;
  transition: all 80ms linear;
}

.reportbtn:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  background: rgba(248, 248, 248, 1);
}

.extramessage {
  text-align: center;
  margin: 10px;
  background: bisque;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  color: var(--primary);
}

.pagination {
  display: flex;
  flex-flow: row;
  margin: auto;
  justify-content: center;
  gap: 6px;
  font-size: 2.8em;
  border: 1px solid rgb(245, 245, 220, 0.3);
  width: fit-content;
  padding: 0px 6px;
  border-radius: 12px;
  background-color: rgb(245, 245, 220, 0.3);
  line-height: 0.1em;
}

.böpl {
  cursor: pointer;
  margin-top: 8px;
}

.active {
  color: var(--primary);
  text-shadow: -1px -1px 2px rgb(200, 50, 50, 0.4),
    1px -1px 2px rgb(200, 50, 50, 0.4), -1px 1px 2px rgb(200, 50, 50, 0.4),
    1px 1px 2px rgb(200, 50, 50, 0.4);
}

.iconliste {
  display: flex;
  align-content: space-around;
  min-height: 50px;
  background: color #efefef;
  margin: 0 5px;
  border-radius: 10px;
}
.distancer {
  margin: 5px 0px;
}
.distancerdown {
  margin-top: 10%;
}
.iconmed {
  width: 35px;
  cursor: pointer;
}
.dropdown {
  padding: 10px 0px;
}
.profilePic {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-self: center;
  object-fit: cover;
  border: 3px solid var(--primary);
}
.dropdowntext {
  word-break: break-word;
}
.profileWrapper {
  position: relative;
  display: grid;
  place-content: center top;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: -5px;
}

.profileWrapper > * {
  grid-area: 1/1;
}
.profileText {
  position: absolute;

  top: 86px;
  line-height: 0px;
  background-color: rgb(255, 255, 255, 0.7);
  padding: 8px;
}
.profileTextdm {
  position: absolute;

  top: 86px;
  line-height: 0px;
  background-color: rgb(4, 2, 34, 0.7);
  padding: 8px;
}

@media only screen and (max-width: 780px) {
  .profilePic {
    width: 80px;
    height: 80px;
    margin-top:5px;
  }
  .headingshort {
    font-size: 1.1em;
    font-weight: 700;
    margin-top: 10px;
}
.pseudoname{
  font-size:0.8em;
  margin-left:-5px;
}
.profileText {
background-color: transparent;
}
.topaytext{
  margin-top:12%;
}
.distancerdown{
  margin-top:5%;
}
.topaywrapper{
  margin-top:5px;
}
}
