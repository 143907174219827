.profilepic {
  max-width: 50px;
  border-radius: 50%;
  border: 5px solid var(--primary);
}

.language {

  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.header {
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}
.payments {
  border-radius: 20px;

  font-weight: 500;
}
.btnelements {
  padding: 10px;
  
}
.btnelements div {
  margin: 10px 0px;
}
.padextra {
  padding: 10px;
}
.profpic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--primary);
  object-fit: cover;
}
.therows {
  margin-top: 10px;
  PADDING:5px;
 
}
.welcome{
  padding:5px;
}
.distancer {
  margin: 5px;
}
.taglist {
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 5px;
}
.tag {
  cursor: pointer;
  border-radius: 12px;
  background-color: #04003b;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
}
.tagplus {
  cursor: pointer;
  border-radius: 12px;
  background-color: #ffffff;
  color: #04003b;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
}
.tagbox {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
  margin:10px 0px;
}
.flexit {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.inputElement input {
  font: inherit;
  background-color: #fce1e4;
  color: var(--primary);
  border-radius: 4px;
  border: 1px solid #fce1e4;

  text-align: left;
  padding: 0.25rem;
  margin: 5px;
}
.inputElement {
  width: 100%;
}
.iconinactive {
  filter: grayscale(1);
  width: 30px;
  cursor: pointer;
}
.iconactive {
  filter: grayscale(0);
  width: 30px;
  cursor: pointer;
}
.infotext {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 5px;
 height:210px;
  margin-bottom: 5px;
 overflow:hidden;
}
.profpicwrapper {
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  width: 100%;
}
.area {
  width: 100%;
  height: 200px;
}
.personalbox{
 
  padding:3px;
  width:99%;
  margin:auto;
  margin-bottom:10px;
}
.toright{
  position:relative;
 
  right:5px;
  float:right;
}
.desctext{
  max-height:120px;
  overflow:hidden;
}
.wrapper{margin:0px 10px 90px 8px;}
.toggle {
	 --size: 1.3rem;
	 appearance: none;
	 outline: none;
	 cursor: pointer;
	 width: var(--size);
	 height: var(--size);
	 box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
	 border-radius: 999px;
	 color: var(--nearBlack);
	 transition: all 500ms;
   margin-top:-6px;
}
 .toggle:checked {
	 --ray-size: calc(var(--size) * -0.4);
	 --offset-orthogonal: calc(var(--size) * 0.65);
	 --offset-diagonal: calc(var(--size) * 0.45);
	 transform: scale(0.75);
	 color: #fa0;
	 box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}
.distancerdm{
  margin-top:20px;
  float:right;
}