.content{
    padding:2%;
}
.header{
   margin-top: 2%;
   margin-bottom:2%;
   border-bottom:1px solid black;
}
.inputquestion{
    width:100%;
    min-height:20vh;
}
.inputmail{
    width:100%;
    
}
.form{
    margin:2%;
    padding:2%;
    border-radius:10px;
}
.form input{
    margin-bottom: 1%;;
}
.bold{
    font-weight:bold;
}