 .mat{
  	width:60vw;
	height:60vh;
	background-color:rgb(135,213,100,0.3);
	border-radius:6px;
	border: 4px solid grey;
	align-content:justify;
  }
  .wrapper{
    width:100%;
    height:100%;
    max-height:40vh;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    
  }
  .wrapbig{
margin-top:30%;
  }
  .sizebig{ width:120px;
	height:120px;}

  .wrapsmall{
margin-top:10%;
  }
  .sizesmall{ width:50px;
	height:50px;}
 


  .myobject{
    
   
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	 position:absolute;
	backface-visibility: hidden;
	
	animation:spin 1.6s linear infinite;
	z-index:4;
	
  }
  .myobjectApple{
    
   
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	 position:absolute;
	backface-visibility: hidden;
	
	animation:spin 1.6s linear infinite;
	z-index:4;
	
  }
   .myobject2{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_02.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	animation:spin2 1.6s linear infinite;
	
  }
   .myobjectApple2{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_02.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	animation:spin2 1.6s linear infinite;
	
  }
  .myobject3{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	animation:spin2 1.6s linear infinite;
	
  }
   .myobjectApple3{
position:absolute;
 
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/loading_01.png");
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	
	 z-index:2;
	animation:spin2 1.6s linear infinite;
	
  }
 
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform: rotate3d(0.1, 1, 0, 360deg);}
}
@keyframes spin2 {
    from {transform: rotate3d(0.05, 0.5, 0, 180deg);}
    to {transform: rotate3d(0.15, 1.5, 0, 540deg);}
}

  @keyframes spin6 {
    from {transform:rotate(0deg);}
	45%{left:30.02%}
	55%{left:3.04%;
	  top:30.2%;
	box-shadow:2px 1px 4px; }
	55%{left:30%;
	top:30.0%;
	box-shadow:0px 0px 0px;}
    to {transform: rotate3d(1, 1, 0.7, 360deg);}
}
 
@media only screen and (max-width: 789px) {
	 .sizebig{ width:50px;
	height:100px;}
  .myobjectApple3{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.myobjectApple2{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_02.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.myobjectApple{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.myobject3{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.myobject2{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_02.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
	.myobject{
	background:url("https://d39j9qa9t0x6xj.cloudfront.net/logos/x100/loading_01.png");
background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;}
}