

.modal {
  position: fixed;
  right: 5%;
  bottom:10%;
  max-width: 300px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
animation:slide-down 1s;
}
.modaltop{
  position: fixed;
  right: 5%;
  bottom:35%;
  max-width: 300px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
animation:slide-down 1s;
}
.closex{
    background: none;
    width:25px;
    border:none;
    height:25px;
}
.closeBtn {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:1000;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: scale(1.2);
}



@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}