.wrapper{
    margin:auto;
    overflow:hidden;
    position: relative;
    z-index:0;
    max-width:100%;
    max-height:100%;
    display:flex;
    justify-content: center;
}
