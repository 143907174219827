.upload{
    color:white;
}
.profilepic{
    max-width:100%;
    height:auto;
   
    border: 3px solid #efefef;
    background-size:cover;
}
.form{
    width:200px;
}

.uploadedVideo{
  position:relative;
}
.igs{position:absolute;top:-3px;left:-3px;width:25px;}

.loadingspinner{
    position:absolute;
      top: 50%;
  left: 50%;
}