.form {
   margin:auto;
   width:90%;
    margin-bottom: 0.5rem;
  }
  
  .label {
    display: block;
    color: var(--primary);;
    font-weight: normal;
    margin-bottom: 0.5rem;
    font-size:0.9em;
  }
  
  .input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid var(--primary);
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
 
  .flexbuttons{
    display:flex;
    flex-flow: row wrap;
    margin-top:5px;
    justify-content: space-between;
  }
  .flexit{
    display:flex;
    flex-flow:row nowrap;
    justify-content: first baseline;
    gap:8px;
    margin-bottom:8px;
  }
  .thegirlimage{
    max-width:110px;
    height:auto;
    border-radius:50%;
  
    }
  @media (max-width: 768px) {.thegirlimage{
    margin-top:30px;
    max-width:90px;
    height:auto;
    border-radius:50%;
    max-height:200px
  }
.label {font-size:0.8em;}
.label h5{
  font-size:1.2em;
}
}