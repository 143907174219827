.element{
border-radius:20px;
border: 2px solid white;
display:flex;
flex-flow:row;
justify-content: space-around;
padding:5px;
width:98%;
margin:1%;
background-color: rgb(80, 78, 100,0.8);
}
.mobelement{
    border-radius:20px;
border: 2px solid white;
display:flex;
flex-flow:column;
justify-content: center;
margin:auto;
padding:10px;
background-color: rgb(80, 78, 100,0.8);
scroll-snap-align: start;
}
.image{
max-height: 200px;
border-radius:10px;
max-width:100%;
margin:auto;
}
.therow{
    width:100%;
margin:auto;
}
.centerme{
    margin:auto;
}
.text{
    width:100%;
    text-align:center;
color:white;
text-decoration:none;
font-size: 0.95em;
}
a{
    color:white;
    text-decoration:none;
}