.wrapper{
    border:1px solid grey;
    border-radius: 5px 5px 0 0;
    margin:5px;
    padding:5px;
    cursor:pointer;
}
.header{
    font-size:1em; font-weight:bold;
}
.headerWrapper{display:flex;
flex-flow:row;
text-overflow: ellipsis;
justify-content: space-between;}
.content{

    font-weight:bold;
    text-overflow: ellipsis;
    font-size:1.2em;
    line-clamp: 2;
}
.listofgirls{
    display:flex;
    flex-flow:row wrap;
    gap:8px;
    justify-content: space-evenly;
    margin-bottom:10px;
}
.girl{
    cursor:pointer;
    border:1px solid grey;
    padding:3px 6px;
    border-radius:4px;
    background-color: #f6f6f6;
}
.girl:hover{
    transition:0.6s;
    background-color: #efefef;
}
.girl_dm{
    cursor:pointer;
    border:1px solid grey;
    padding:3px 6px;
    border-radius:4px;
    background-color: #262540;
}
.girl_dm:hover{
    transition:0.6s;
    background-color: #302f48;
}