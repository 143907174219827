.darkBG {
     
 position:fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  
  background-color: rgba(0,0,0,0.4);
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.modal {
  width: 80vw;
  height: 100%;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:2%;
}
.modalbg {
  width: 80vw;
  height: 100%;
  background: rgb(107, 98, 122,1);
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding:1%;
}

.modalHeader {
  height: auto;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom:10px;
  padding:1%;
}
.modalHeaderbg {
  height: auto;
  background: rgb(25, 22, 37,0.3);
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
   padding:1%;
}
.closeBtn {
  cursor: pointer;
  font-weight: 500;

  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: none;
  transition: all 0.25s ease;
  
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  z-index:100;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}
.closex{
    background: none;
    width:25px;
    border:none;
}
.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  z-index: 10;
  padding: 5px;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
.modal{
  padding:2%;
  color:black
}
.inactive{
  background-color: beige;
  color:#2c3e50;
}
.checkbox{
  margin-right:1%;
  
}


.control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
  
  .actions button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .actions .toggle:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .myButton {
	box-shadow: 0px 0px 4px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:1% 2%;
	text-decoration:none;
	text-shadow:0px 2px 0px #662828;
    transition:1s;
    margin:0.2%;
}
.myButton:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.myButton:active {
	position:relative;
	top:1px;
}
.mandatskasten{
  font-size:0.8rem;
  line-height: 0.8rem;
  border:1px solid black;
padding:1%;
border-radius:5px;
background-color: #ebebeb;
}
.mandatskastendm{
  color:white;
  font-size:0.8rem;
  line-height: 0.8rem;
  border:1px solid black;
padding:1%;
border-radius:5px;
background-color: #2a2525;
}
.consent{
  font-size:0.80rem;
  font-weight: normal;

}
.icon{
  height:50px;
  width: fit-content;
}
.flexit{
  display:flex;
  flex-flow:row;
  gap:10px;
}
.getNumber{width:80%; margin:auto;}
.smalllogo{
  height:30px;
}
.topdown{
  margin-top:10px;
}
.consenttextsmall{
  font-size:0.9em;
}
.consenttext{
  font-size:0.9em;
}