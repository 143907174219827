.box{
    padding:2%;
    margin:auto;
    width:100%;
    text-align:center;
}
.boxdm{
    color:white;
    padding:2%;
    margin:auto;
    width:100%;
    text-align:center;
}
.header{
    text-transform: uppercase;
    border-bottom:1px solid black;
    margin-top:2rem;
    margin-bottom:2rem;
}
.header a{text-decoration:none; color:var(--primary)}
.header {text-decoration:none; color:var(--primary)}
.header:hover{text-decoration:none; color:var(--primary)}
.text{
    text-align: left;
}
.avsimage{
    width:100%;
    max-width:500px;
}
.avsimagemobile{
    width:100%;
    opacity:0.4;
    position:relative;
}