.selector{
  z-index:200;
}

.theoption{ 
  background-color: #fff;
  margin:3px;
  font-weight:bold;
  max-width: 100%;
  border: 1px solid var(--primary);
}
a{color:#333!important;
font-weight:bold;};

.alertlink{
  color:#333;
  text-decoration: none;
}
.alertlink:a{
   color:#333;
  text-decoration: none;
}
.alertlink:hover{
  color:#333;
}
.icon{
   height: 50px;
  width:max-content;
  transition: 0.5s;
  cursor: pointer;
  
}
.flexit{
gap:5px;
  display:flex;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.flexittop{
gap:5px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.iframeinner{
  width:100%;
  height:70vh;
}
.glow{
  box-shadow: -1px -1px 2px rgba(64, 211, 76, 0.8), 1px -1px 2px rgb(64, 211, 76, 0.8), -1px 1px 2px rgb(64, 211, 76, 0.8),
    1px 1px 2px rgb(64, 211, 76, 0.8);
}
.girlimagebig{
  width:110%;
  margin-left:-5%;
  margin-top:-5%;
  max-height:160px;
  object-fit: cover;
}
.damodal{
  border:2px solid #666;
}
@media (max-width: 780px) {
  .girlimagebig{
  width:110%;
  margin-left:-5%;
  margin-top:-5%;
  max-height:100px;
 color:#d2c9d2;
  object-fit: cover;
}
}