.btn {
  
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;

  font-weight: 800;
  font-size: 12px;
  color: var(--primary);
  background-color: #eeeeee;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  transition:0.5s;
}
.btn:hover {
 background-color: #04003b;
 color: #eeeeee;
}

.btn a {
text-decoration: none;
}