.textforGirl {
  min-height: 100px;
  width: 90%;
  padding: 2%;
  margin: 2%;

  border-radius: 20px;
}
.mySelect {
  z-index: 100;
}
.otpagb{
  color:#333;
  text-decoration:none;
  font-weight:bold;
}
.head {
  text-transform: uppercase;
  border-bottom: 1px solid black;
  margin-bottom: 1em;
  font-size: 19px;
  line-height: 2em;
}
.highlight{
  box-shadow: 1px 1px 9px var(--primary) , -1px -1px 9px var(--primary);
}
.firstelement {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap:1vw;
  font-size:6vw;
  max-width: 82%;
  margin:auto;
}
.setapart {
  color: var(--primary);
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  margin:auto;
  gap:0;
}
.entermoney {
  border: none;
  text-align: right;
  font-weight: bold;
 font-size:6vw;
  color: var(--primary);
  width:70%;

}
.entermoneylong{border: none;
  text-align: right;
  font-weight: bold;
 font-size:4vw;
  color: var(--primary);
  width:70%;}
.entermoney input {
  max-width:fit-content;
  color: var(--primary);
  padding:0;
  margin:0;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 0;
  margin: 0px;
  color: var(--primary);
}

.fandollarelement {
  width: 5vw;
  height:6vw;
  margin:auto;
}
.increasbutton {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  line-height: 0.1em;
  z-index: 50;
}

.wrapme {
  padding: 2%;
  z-index: 1000;
}
.theoption {
  background-color: #fff;
  margin: 3px;
  font-weight: bold;
  max-width: 100%;
  border: 1px solid var(--primary);
}

.otpagb {
  display: inline !important;
  width: 100% !important;
  margin: 1px !important;
  padding: 1%;
}
.increasbutton:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.iframe {
  min-width: 80vw;
  height: 60vh;
  margin: auto;
}
.iframeinner {
  width: 70vw;
  margin: 3% auto;
  margin-left: 7vw;
  height: 80vh;
}
.makebold {
  font-weight: 600;
}
.checkbox{
  margin-right:5px;
}
.currency{
  font-size: 6vw;
  font-weight:bold;
  margin-top:4%;
}
.currencychf{
  font-size: 3vw;
  font-weight:bold;
  margin-top:15%;
}
.currencylong{
  font-size: 4vw;
  font-weight:bold;
  margin-top:4%;
}
.currencychflong{
  font-size: 3vw;
  font-weight:bold;
  margin-top:6%;
  
}
.consentblock{
  line-height:1em;
  line-break: auto;
  word-break: normal;
  font-size:0.8em;
}
@media (max-width: 1280px) {
  .currency{
    font-size: 7vw;
    font-weight:bold;
    margin-top:6%;
  }
  .firstelement {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap:1vw;
    font-size:8vw;
    max-width: 90%;
    margin:auto;
  }
  .setapart {
    color: var(--primary);
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin:auto;
    gap:0;
  }
  .entermoney {
    border: none;
    text-align: right;
    font-weight: bold;
    color: var(--primary);
    width:90%;

  }
  .entermoney input {
    max-width:fit-content;
    color: var(--primary);
    padding:0;
    margin:0;
  }
  input[type="number"]::-webkit-inner-spin-button {
    opacity: 0;
    margin: 0px;
    color: var(--primary);
  }

  .fandollarelement {
    width: 6vw;
    height:7vw;
    margin:auto;
  }
  .increasbutton {
    border: none;
    background-color: transparent;
    color: var(--primary);
    font-weight: bold;
    text-align: center;
    line-height: 0.1em;
    z-index: 50;
  }
  .consenttext{
    font-size:0.9em
  }
}
.flexcontainer{
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap:10px;
}
.buyelement{
  border:1px solid var(--primary);
  border-radius:8px;
  font-weight:bold;
  color:#555;
  display: flex;
  flex-flow: row;
  padding:8px 15px;
  cursor:pointer;
}
.num{
  font-size: 1.5em;
  color:var(--primary);
}
.fandollarimg{
height:1.6em;
margin-top:3px;
}
.numbig{
  font-size: 3.5em;
  color:var(--primary);
}
.fandollarimgbig{
height:3.6em;
margin-top:3px;
}
.buyelementbig{
  border:1px solid var(--primary);
  border-radius:8px;
  font-weight:bold;
  color:#555;
  display: flex;
  flex-flow: row;
  padding:16px 30px;
  cursor:pointer;
  width: fit-content;
  margin:auto;
}
.flexcon{
 margin:auto;
}