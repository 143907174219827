.box{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:14vw;


    
}
.box:hover{
    filter: none;
    transition:0.3s;
}
.box2{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
  max-width:24vw;

cursor:pointer;
  
}
.box2:hover{
    filter: sepia(0);
    transition:0.3s;
}
.box .contain{
  width:100%;
  max-width:12vw;
 height:100%;
 max-height:12vw;
 margin:auto;
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box .contain img{
transform: translate(-20%, -15%);
transition: .5s ease all; 
max-width:400px;

height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.box2 .contain{
  width:100%;
  max-width:25vw;
 height:100%;
 max-height:25vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box2 .contain img{

transition: .5s ease all; 
max-width:650px;
transform: translate(-20%, -15%);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

.contain {
  position: relative;
  overflow: hidden;
  
  z-index: 1;
}
.contain::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: .5s ease all;
   border-radius:14px;
}
.theimage{
  transition: .5s ease all; 

object-fit:stretch;
overflow:hidden;
  border-radius:14px;}
.contain:hover img {
  transform: scale(1.1);
  border-radius:14px;
}

.contain:hover::after {opacity: 0.2;}

.info{
  color:black;
  
}
.headline{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.infoservice{
  display:flex;
  justify-content: space-between;
  padding:0px 5px;
}
.myname{
  font-weight:bold;
  color:var(--primary);
  text-decoration: none;

}
.myname a{
  text-decoration: none;
  color:var(--primary);
}
.buttons{
   display:flex;
  justify-content: space-evenly;
}
.wrapper{
  color: var(--primary);
  width:80%;
  margin:2% auto;
}
@media only screen and (max-width: 1108px) {
  
.box{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:18vw;


    
}
.box:hover{
    filter: none;
    transition:0.3s;
     transform: scale(0.95);
}
.box2{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
  max-width:98vw;

cursor:pointer;
  
}
.box2:hover{
    filter: sepia(0);
    transition:0.3s;
    transform: scale(0.95);
}
.box .contain{
  width:100%;
  max-width:17vw;
 height:100%;
 max-height:17vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box .contain img{

transition: .5s ease all; 
max-width:250px;
transform: translate(-0, -0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.box2 .contain{
  width:100%;
  max-width:95vw;
 height:100%;
 max-height:95vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box2 .contain img{

transition: .5s ease all; 
max-width:550px;
transform: translate(0, 0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

}
.headline{
  line-height:normal;
  height:1.5rem;
}
@media only screen and (max-width: 778px) {
  
.box{
    background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
    cursor:pointer;
     max-width:47vw;


    
}
.box:hover{
    filter: none;
    transition:0.3s;
}
.box2{
     background-color: azure;
    border: 1px solid black;
    border-radius:15px;
    padding:5px;
    filter:sepia(0.3);
     transition:0.3s;
  max-width:98vw;

cursor:pointer;
  
}
.box2:hover{
    filter: sepia(0);
    transition:0.3s;
}
.box .contain{
  width:100%;
  max-width:47vw;
 height:100%;
 max-height:44vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box .contain img{

transition: .5s ease all; 
max-width:250px;
transform: translate(-0, -0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}
.box2 .contain{
  width:100%;
  max-width:95vw;
 height:100%;
 max-height:95vw;
 
   border-radius:14px;
object-fit:contain;
position:relative;
}
.box2 .contain img{

transition: .5s ease all; 
max-width:550px;
transform: translate(0, 0);
height:auto;
object-fit:cover;

border-radius:14px;
position:relative;
}

}