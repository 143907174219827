.successContainer{
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
}

.mainimage{
    width:100%;
}
.distancer{
    margin-top:5px;
}