.cont {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.butonwrapper {
  width: 80%;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}
.imageholderholder {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 20px;
}
.imageholder {
  position: relative;
  -webkit-mask: -webkit-gradient(
    linear,
    center top,
    center bottom,
    color-stop(0, rgba(0, 0, 0, 1)),
    color-stop(0.35, rgba(0, 0, 0, 1)),
    color-stop(0.5, rgba(0, 0, 0, 1)),
    color-stop(0.65, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 0))
  );
  padding: 10px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 60vh;

  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.imageholder::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.userimages {
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
}
.userimages:hover {
  transition: 0.4s;
  transform: scale(1.02);
}
.flexme {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.wrapper {
  position: relative;
}
.ix {
  position: absolute;
  width: 20px;
  right: 9px;
  top: 9px;
  z-index: 50;
}
.ix:hover {
  transition: 0.4s;
  transform: scale(1.04);
  cursor: pointer;
}
.buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

.upload{
  margin-top:10px;
  padding:5px;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.profilepic{
    max-width:100%;
    height:auto;
   max-height:400px;
    border: 3px solid #efefef;
    background-size:cover;
}
.form{
    width:200px;
    
}
.button{
  background-color: var(--primary);
  color:white;
  padding:8px 10px;
  border-radius:8px ;
  cursor:pointer;

}
.button:hover{
  background-color:var(--primaryDark);
  transition:0.7s;
}
.headerupload{
  font-size:1.1em;
}
.uploadedPicture{
  position:relative;
}
.igs{position:absolute;top:-3px;right:-3px;width:25px;}