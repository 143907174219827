.contain{
 height:100%;
 position:fixed;
  overflow-y:scroll;
 z-index:1;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
max-width:310px;
margin:auto;
}

.contain2{
 height:100%;

 position:fixed;
  overflow-y:scroll;
 z-index:1;
 -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
max-width:310px;
width:21%;

}
.bottombouncer{
    height:150px;
    background-color: transparent;
}
.contain::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.contain2::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.footerelement{
    height:12vh;
   
    width:100%;
   
}
.shortadd{width:100%;}
.add{
    max-width:310px;

    margin:auto;
     overflow:hidden;
    color: #000;
    padding: 0px;
    width: 100%;
    margin-left:0;
}
.wrappadds{
 width:100%;
}
@media (max-width: 768px) {

    .contain{display:none;}
}
