
.checkbox{
  margin-right:1%;
}
.avsimage{
  max-width:140px;
   max-height:140px;
  border-radius:50%;
}

    .flexouter{
      display:flex;
      flex-flow:row;
      flex-wrap:nowrap;
      gap:15px;
    }
  .addapayment{
    cursor:pointer;  
    margin:auto;
    background-color: #efefef;
    color:#555;
    font-size:1.0em;
    font-weight:bold;  
    display:flex;
    flex-flow:row wrap;
    justify-content: flex-start;
    padding:4px 10px;
    
    
}
.icon{max-height:60px;float:left;}
.text{
  text-align:left;
  font-size:0.9em;
  line-height:0.5em;
  margin-top:10px;

}
.text h5{
  font-weight:bold;
  font-size:1em;
}
.text p{
  font-weight:normal;
}
.flexit{
  display:flex;
  flex-flow:row;
  gap:20px;
  justify-items: flex-start;
}
  .control {
    margin-bottom: 0.5rem;
   display:flex;
   justify-content: space-around;
   gap:5%;
   width:100%;
   
  }
  .container{
    width:100%;
    background-color: #fff;
    color:#222;
    padding:10px;
  }
  .extrainfo{color:#fff;}
  .control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width:100%;
    padding:3px;
    border-radius:8px;
  }
  
  .control input {
    border-radius:8px;
    font: inherit;
    background-color: #fce1e4;
    color: var(--primary);
    border-radius: 4px;
    border: 1px solid #fce1e4;
   width:100%;
    text-align: left;
    padding: 0.25rem;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    max-width:80%;
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: var(--primaryBright);
    border: 1px solid var(--primaryBright);
    border-radius: 4px;
    padding: 3%;
  }
  
  .actions button:hover {
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
  }
  
  .actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: white;
    border: none;
    padding: 0.15rem 1.5rem;
  }
  
  .actions .toggle:hover {
    background-color: transparent;
    color: rgb(234, 166, 166);
  }
  .form{
    border-radius:20px;
    background-color: var(--primary);
    padding:5%;
    display:flex;
 
  
  
  margin-right:4%;
  }
 .form form{
  width:100%;
 }
.wrapper{
 
  width:100%;

}
.header{
  color:white;
  margin-bottom:1rem;
  font-size: 1.5em;
}
.head{
  color:white;
 font-size: 0.9em;
}
.showonmobile{
  display:none
}
.controlzip{
  width:28%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlcity{
  width:68%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlstreet{
  width:100%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlfn{
  width:50%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlln{
  width:50%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlmail{
  width:60%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlphone{
  width:60%;
}
.controldate{
  width:40%;
  color:var(--primary);
  background-color: #fce1e4;
}
.controlcountry{
  width:40%;
  color:var(--primary);
  background-color: #fce1e4;
}
.thebuttons{
 display:flex;
 gap:10px;
 flex-flow:column;
 margin:auto;
 
width:95%; 
}
.mobile{
  display:none;
}

.showondesktop{
  display:block;
}
@media (max-width: 768px) {
.showonmobile{
  display:block;
}
.showondesktop{
  display:none;
}
  .controlzip{
  width:100%;
}

.mobile{
  
}
.controlcity{
  width:100%;
}
.flexouter{
  display:flex;
  flex-flow:row;
}
.control label{
  color:var(--primary);
  font-size:0.8rem;
  margin-bottom:-2px;
}
.begone{
  display:none !important;
}
.control {
    margin-bottom: 0.9rem;
  }
  .form{
      margin-right:0;
  }
}