.select {
    position: relative;
    display: inline-block;
    margin: 2%;
    width: 20%;
}    .select select {
        font-family: 'Arial';
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 2px solid #000000;
        border-radius: 0px;
        background: #e6e6e6;
        color: var(--primary);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
        .select select::-ms-expand {
            display: none;
        }
        .select select:hover,
        .select select:focus {
            color: var(--primary);
            background: #cccccc;
        }
        .select select:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
.select_arrow {
    position: absolute;
    top: 11px;
    right: 17px;
    width: 0px;
    height: 0px;
    border: solid #7b7b7b;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-color: #000000;
}
.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
.flexit{display:flex;
flex-flow: row;
gap:10px;margin-top:2px;}
.flagicon{
    border:3px solid #cccccc;
    border-radius:50%;
    margin:2%;
    filter:grayscale(0);
    width:60px;
}
.flagicon img{border:2px solid #fff;}
.flagiconinactive{
    border:3px solid #cccccc;
    border-radius:50%;
    margin:2%;
    filter:grayscale(1);
    cursor:pointer;
    width:60px;
}
@media (max-width: 768px) {
 .flagicon {
    width:1.3em;
    height:1.3em;
    border:none;
 }
 .flagiconinactive {
    width:50px;
    width:1.3em;
    height:1.3em;
    border:none;
 }
}