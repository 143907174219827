.footer{
    color:black;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 10vh;
    width:100%;
  position:relative;
 gap:20px;
  background-color: var(--primaryBright);
  padding:1%;
  z-index:1;
}
.footerlinks{
    color:black;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
   
    width:90vw;
    margin:auto;

 

}
.footertext{
    color:black;
text-decoration: none;


font-size:1rem;
font-weight:normal;
}
.center{
  width:100%;
  text-align:center;
}