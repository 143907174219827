.textforGirl{

    min-height:100px;
    width:90%;
    padding:2%;
    margin:2%;
   
    border-radius:20px;
}
.entermoney{
border:none;
text-align:right;
font-weight:bold;
width:11vw;
color:var(--primary);
font-size:5.5vw;

line-height: 1.7vw;
}
.head{
    text-transform: uppercase;
    border-bottom: 1px solid black;
    margin-bottom:1em;
    font-size:19px;
    line-height:2em;
}
.entermoney input{
margin:1%;
 color:var(--primary);
}
.firstelement{
    
    text-align: center;
    margin:auto;
    margin-top:2%;
    margin-bottom:2%;
   font-size:2.9em;
   
}
.firstelementbox{
    width:100%;
    text-align: center;
    margin:auto;
 }

input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
  margin:0px;
color:var(--primary);
}
.wrapme{
    padding:2%;
   z-index:1000;
}
.theoption{ 
  background-color: #fff;
  margin:3px;
  font-weight:bold;
max-width: 100%;
border: 1px solid var(--primary);
}
.increasbutton{
   border:none;
    background-color:transparent;
    color:var(--primary);
     font-weight:bold;
    text-align:center;
        line-height:0.1em;
       
       
}
.otpagb{
    display:inline !important;
    width:100%!important;
    margin:1px!important;
    padding:1%;
}
.increasbutton:hover{
    transform:scale(1.1);
    transition:0.5s;
}
.setapart{
margin-left: 2%;
margin-right: 3%;
color:var(--primary);
width:10vw;

}
.iframe{
  min-width:80vw;
  height:60vh;
  margin:auto;
}
.iframeinner{
width:70vw;
margin:3% auto;
margin-left:7vw;
height:80vh;
}
  .makebold{
    font-weight:600;
  }

  .fandollarelement{
    width:5vw;
    
    transform: translateY(-1vw);
  }

  @media (max-width: 968px) {
.fandollarelement{
    width:5vw;
    
    transform: translateY(0px);
  }
.entermoney{
border:none;
text-align:right;
font-weight:bold;
width:14vw;
color:var(--primary);
font-size:7.5vw;

line-height: 1.7vw;
}
  }