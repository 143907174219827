.flexitouter{
width:100%;
    display:flex;
    flex-flow: row;
    flex-wrap: wrap;
    text-align: right;
    justify-content: right;
    gap:8px;
}
.icon{
    width:15px;
    height:15px;
    margin-top:3px;
}
.flexitinner{
    display:flex;
    flex-flow: row;
    flex-wrap: wrap;
   
    gap:5px;
}
.bolder{

font-size:0.7em;
}