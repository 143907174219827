.control{
    margin:20px;
   
     width:95%;
    display:flex;
    justify-content: space-between;
}
.input{
   padding:1%;
     font-size: 16px;
     border-width: 1px;
     border-color: #CCCCCC;
     background-color: #FFFFFF;
     color: #000000;
     border-style: hidden;
     border-radius: 8px;
     box-shadow: 0px 0px 5px rgba(66,66,66,.75);
     text-shadow: 0px 0px 1px rgba(66,66,66,.75);
   width:100%;
   margin-right:2%;
}
input:focus{
     outline:none;
}
.MyButton {
	box-shadow: 0px 0px 2px 0px var(--primaryBright);
	
	background-color:var(--primary);
	border-radius:10px;
	border:1px solid var(--primaryBright);
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:1% 2%;
	text-decoration:none;
	
    transition:1s;
    margin:0.2%;
}
.MyButton:hover {
	 transition:0.5s;
	background-color:var(--primaryBright);
    border:1px solid var(--primaryBright);
  box-shadow: 0px 0px 4px 0px var(--primaryBright);
}
.MyButton:active {
	position:relative;
	top:1px;
}
@media (max-width: 768px) {
    .control{
    margin:10px 5px;
   
     width:95%;
    display:flex;
    justify-content: space-between;
}
.MyButton {
    font-size:13px;
}
}