.hotte{
    display:none;
}
.otpagb{
    color:#222;
    font-weight:bold;
}
.modaltitle{
    margin-bottom:15px;
    font-weight:bold;
    
}
.textblock1{
    margin-bottom:8px;
}
.textblock2{
    margin-top:8px;
    font-size:0.9em;
}
.flexit{
gap:5px;
  display:flex;
  justify-content: center;
  margin-top:8px;
  margin-bottom:8px;
}
.theoption{
    max-width:100%;
}
.epochalert{
    font-weight:bold;
}
.flexitwrapper{
  padding:1% 4%;
    border:1px solid gray;
    border-radius:8px;
}
.icon{
    height:80px;
}

.profilepic{
    display: inline-block;
  width: 110px;
  height: 110px;
  border-radius: 50%;

  object-fit: cover;
  border:3px solid #999999; 
  justify-self: center;
}
.profilepicmob{
    display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 50%;

  object-fit: cover;
  border:3px solid #999999; 
  justify-self: center;
}
.bold{
    font-weight:bold;
}
.selector{
    margin-top:10px;
}
.checkboxtext{
    font-size:0.9em;
}
.checkbox {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  color:var(--primary);
  width: 1.15em;
  height: 1.15em;
  border: 0.25em solid var(--primary);
  border-radius: 0.25em;
}
.buybtn{
    margin:auto;
    display:flex;
    justify-content: center;
}
.checkbox{
    color:var(--primary);
}
.pseudo{
    color:var(--primary);
    font-weight:bold;
}
.consentElement{
    font-size:0.9em;
    line-height:0.98em;
}
.centerme{display: flex;justify-content: center;}
.infobutton{
    background-color: transparent;
    color:var(--primary);
}
.infobuton:hover{
    background-color: var(--primary); color:white;
}