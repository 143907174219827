.wrapper{
    border:1px solid rgb(167, 167, 167);
    padding:5px;
    border-radius:8px;
    display:flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    cursor:pointer;
    margin-bottom:5px;
}

.image{
    max-width:100%;
    border-radius:8px;
}