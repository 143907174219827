.timelineold {
  padding: 1%;
  background:#f1f1f1;
  width:100%;
  margin-left:0.2%;
  margin-right:1.5%;
  margin-bottom:1.5%;
  font-size:0.8em;
  border-bottom:1px solid #888888;
  }
  
.timeline {
  padding: 1%;
  background:#fff;
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 6px 6px 0px 0px;
  box-shadow:0px 4px 7px 2px  #e5e5eb;
}
.timelinedm {
  padding: 1%;
  background:rgb(255,255,255,0.3);
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 6px 6px 0px 0px;
  box-shadow:0px 4px 7px 2px  #27272d;
  
}
.myhr{
  margin-bottom:5px;
  margin-top:5px;
}
.testit{
  height:200px;
}
.timelinebg {
  padding: 1%;
  background:rgb(32, 32, 32);
  color:#666;
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 9px 9px 0px 0px;
  box-shadow:0px 4px 7px 2px  #e5e5eb;
 
}
.timelinedmbg {
  padding: 1%;
  background:rgba(67, 60, 60, 0.8);
  margin-left:1%;
  margin-right:1%;
  margin-bottom:2.5%;
  font-size:0.8em;
  border-radius: 9px 9px 0px 0px;
  box-shadow:0px 4px 7px 2px  #27272d;
  color:aliceblue;
  z-index:50;
}
.pins{
width:40px;
cursor:pointer;

}
.pinsbright{
width:40px;
cursor:pointer;
filter:opacity(0.4);

}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  
}
.reportbtn{
  margin-top:5px; 
  width:100%;
  
 
}
.mybutton{
background-color: rgb(220, 210, 210,0.1);
  border-radius:10px;
  padding:3px;
  border:none;
   color:#888;
   font-size:0.8rem;
   float:right;
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-100px) rotate(-45deg);
  }
30%{
  transform: rotate(-45deg);
}
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-5deg);
  }

  80% {
    transform: translateY(-10px) rotate(-5deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
  }
}
.myBounceDiv { 
        -moz-animation:bounce .40s linear;  
        -webkit-animation:bounce .40s linear; 
} 

@-moz-keyframes bounce {
        0%{ -moz-transform:scale(0); opacity:0;}
        50%{ -moz-transform:scale(1.3); opacity:0.4; }
        75%{ -moz-transform:scale(0.9); opacity:0.7;}
        100%{ -moz-transform:scale(1); opacity:1;}
}

@-webkit-keyframes bounce {
        0%{ -webkit-transform:scale(0); opacity:0;}
        50%{ -webkit-transform:scale(1.3); opacity:0.4;}
        75%{ -webkit-transform:scale(0.9); opacity:0.7;}
        100%{ -webkit-transform:scale(1); opacity:1;}
}


  .new{
    color:var(--primary);
  }
  .timelinecontentnew{
    border:2px solid var(--primary)!important;
  }
.timelinetitle {
 
  color: #04003b;
  cursor: pointer;
  text-decoration: none;
}
  
.timelinetitle a {
  color: #04003b;
  cursor: pointer;
  text-decoration: none;
}
  
.timelinetitle a:hover {
  color: var(--primary);
  cursor: pointer;
}  
  
.timelinedate {
  color: #333333;
  text-align:right;
}
.timelinedatedm {
  color: aliceblue;
  text-align:right;
}
  
.timelinecontent {
  padding: 0 20px 20px;
  font-size: 1.2em;
}
  
.timelinecontent .heading {
  font-size: 1.3em;
  font-weight:700;
} 
.timelinecontent .subheading {
  font-size: 1em;
  font-weight:500;
}  
  
.timelinecontent a{
  color: var(--primary); 
  cursor: pointer;
  text-decoration: none;
}
  
.timelinecontent a:hover {
  color: #04003b;
  cursor: pointer;
} 
  
.timelinecontent img {
  border-radius:5px;
  
} 

  
.timelinemenu {
  border-bottom:1px solid #b0afbb;
  display: flex;
  align-items:center;
  padding:10px 0px;
  text-decoration: none;
   justify-content: space-between;
  
}

  
.timelinemenuitem {
  text-decoration: none;
  color: #ccc8db;
  padding: 10px 14px;
  line-height: 0.7;
  font-weight: 500;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #ccccd3;
 
}
  
.timelinemenuitem:hover {
  background-color: #2e2e40;
  color: #fff !important;
}
  
.bigbutton {
  font-size:1.8rem;
  font-weight:bold;
  color: #fff !important;
  width:100%;
  text-align:center;
  display:block;
  padding:15px;
  background:var(--primary);
  margin:10px 0;
  border-radius:50px;
  cursor: pointer;
  transition:0.25s;
  text-decoration: none;
} 
  
.bigbutton:hover {
  background:#04003b;
} 
.imageElement {
  width: auto;
  display:block;
  margin:auto;
}

.smallMediaPreview {
 font-size: 10px;
}
.contentimage{
  max-width: 20px;
  max-height: 20px;
}
.clickable{
  cursor:pointer;
}
.myimageelement{
  height:100%;
  width:auto;
  max-width:100%;
  max-height:50vh;
  margin:auto;
  transition:0.4s;
}
.marginme{
  display:flex;
  margin:auto;
}
.smaller{
  margin:auto;
  max-width:600px;
  padding: 3%;
  background-color: #dcdcdc;
}
.tag span{
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tagcolor1 {
  background-color: var(--primary);
}
.tagcolor2 {
  background-color: #04003b;
}
.likeHerz{
  width:20px;
  cursor:pointer;
}
.unlikeHerz{
  width:20px;
  filter:grayscale(1);
  cursor:pointer;
}
.likeit{
color:#666;
cursor:pointer;
margin-left: auto;
}
.liketext{
  align-self: end;
  margin-left:5px;
}
.morebtn{
  color:var(--primary);
  cursor:pointer;
}
.mailicon{
  width:30px;
  margin:-10px 5px ;
  transform: translateY(-6px);
}
.toright{
  margin-left:93%;
}
.mobicon{
  
  height:30px;
  
}
.timelinemobile{

  display:flex;
justify-content: space-evenly;
gap:10%; 
margin-bottom:1%;
margin-top:1%;
}

.mobiconunlike{
  filter:grayscale(1);
  height:30px;
}
.rowpinned{
  margin:2%;
 background-color: #dcc3c3;
}
.rowpinneddm{
  margin:1%;
 background-color: rgb(172, 150, 150,0.5);
}
.pinnedpost{margin:auto;width:100%; text-align: center;}
.showcommentbtn{
  border:none;
  background:rgba(155, 96, 96, 0.1);
}
.showcommentbtn:hover{
  font-weight:bold;
  background-color:rgb(155, 96, 96,0.4);
  transition:.2s;
}

.showcommentbtndm{
  border:none;
  background:rgba(203, 127, 127, 0.2);
  color:white;
}
.showcommentbtndm:hover{
  font-weight:bold;
  background-color:rgb(203, 127, 127,0.4);
  transition:.2s;
}
.killit{
  text-decoration:line-through;
  color:var(--primary);;
}
@media only screen and (max-width: 778px) {

  .timelinebg,.timelinedmbg{
    margin-top:20%;
     color:#999;
  }

}