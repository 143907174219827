
.nextButton{
    position:absolute;
    right:10px;
    top:10px;
    z-index: 900;
}
.wrapper{
  
    overflow:auto;
}
.loadbutton{
    width:100%;
    margin:auto;
}