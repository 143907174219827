.card{

    width:100%;
    cursor:pointer;
    background-color: aliceblue;
    color:black;
    border-radius:20px;
    box-shadow: 2px 2px 2px 2px rgb(98, 98, 98,0.3);
    margin-top: 2%;
    
}
.textfield{
  padding:3px 15px;
}
.textfielddm{
color:#333;
padding:3px 15px;
}
.textdark{color:#333}
.textbright{color:#333}
.headerfield{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.headersmall{
    background-color:rgb(219, 119, 59, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.headerfieldclicked{
    background-color:rgba(159, 159, 159, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px 20px 0px 0px;
    position: relative;
}
.headersmallclicked{
    background-color:rgba(159, 159, 159, 0.6);
    width:100%;
    padding:2% 2% 0.5% 2%;
    border-radius:20px;
    position: relative;
}
.buttonfield {
    margin: auto;
    padding: 2%;
}
.buttonfield > div {
    display: inline-block;
}
.buttonfield > div > button {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
}
.contentfield{
    transition:1s;
}
.msgimg {
    position: absolute;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    top: 8px;
    right: 10px;
    z-index: 11;
    outline: solid 2px white;
}

.mobileCard{
    font-size: smaller;
    padding:2%;
    
}
.headline{
    font-size: 12px;
    margin:3%;
    width:50%;
}
.msgimgmobile {
    position: absolute;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    top: 15%;
    right: 12%;
   
    outline: solid 2px white;
}
.contentfieldmobile{
    background-color: rgb(223, 219, 214);
}
.messagedate {
    font-size: 80%;
    color: #333;
}
.messagedate > label {
    margin-left: 10px;
    display: inline-block;
}
.messagedate > label > span {
    font-size: 11px;
    line-height: 11px;
    margin-left: 5px;
    display: inline-block;
    background-color: #333;
    color: #eee;
    padding: 2px 5px;
    border-radius: 5px;
}
.messagedatemobile{
    font-size: 80%;
    color: #333;
    padding:0px 8px;
}
.messagedatemobile > label {
    margin-left: 10px;
    display: inline-block;
}
.messagedatemobile > label > span {
    font-size: 10px;
    line-height: 10px;
    margin-left: 5px;
    display: inline-block;
    background-color: #333;
    color: #eee;
    padding: 2px 5px;
    border-radius: 5px;
}
.msgattachmentimg {
    min-width: 100px;
    min-height: 100px;
    width:100%;
}
.msgattachmentvideo {
width:100%;
}
.msgattachmentaudio {
    
}
.errormodal{
    text-align:center;
    border:1px solid black;
    border-radius:8px;
}
.debugmsg {
    color: #777;
    font-size: 80%;
    margin-bottom: 0px;
}
.mediafield {
    padding: 10px;
}
.mediaattachment {
    padding: 2px;
}

.spinning {
    width: 45px;
    height: 45px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.faviconDIV {
    display: inline-block;
    margin-left: 5px;
  
    position: relative;
}


.favicon {
    width: 32px;
    transition: 0.5s;
    cursor: pointer;
}

.favicongray {
    width: 32px;
    filter: grayscale(1);
    transition: 0.5s;
    cursor: pointer;
}