.pad{
    padding:1%;
    z-index:0;
}
.padshort{
   padding:1%;
    margin:1%;
}
.userinfo {
  z-index:0;
  background-color: #fff;
  border:1px solid #eeeeee;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color:#000;
}
.content {
   z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
}
.header{
    width:100%;
    border-bottom:1px solid black;
    text-transform: uppercase;
    font-weight: 600;
}

.thetiers{
margin-top:10px;
 width:100%;
 
}
.thelist{
   
    list-style: none;
    width:100%;
    padding:2%;
    border-radius:10px;
    margin: auto;
    }
.firstline{
    font-weight:bold;
   
   
}
.priceline{
    margin-left:10px;
    font-weight: bold;
}
.thebutton{
    margin-left:20px;
   
}
.mobilelist{
    list-style: none;
    margin:none;
    padding:2%;
    border:1px solid black;
    border-radius:10px;
    width:100%;
}
.mobilelist li{
    margin-left:4px;
    line-height: 0.8em;
}