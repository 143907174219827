.pad {
  padding: 1%;
  z-index: 0;
  margin-bottom:3px;
  scroll-snap-align: start;
}
.nosnappad{
    padding: 1%;
  z-index: 0;
  margin-bottom:3px;

}

.padshort {
  padding: 1%;
  margin: 1%;
}
.loginButton{
  width:100%;
  display:flex;
  justify-content: center;
}
.userinfo {
  z-index: 0;
  background-color: #fff;
  border: 1px solid #eeeeee;
  width: 100%;
  border-radius: 10px;
  
  color: #000;
  transition: 0.8s;
  position: relative;
}

.userinfo a {
  text-decoration: none;
}

.fullwidth {
  width: 100%;
  margin: -5px -10px;
  --bs-gutter-x: 0px;
}

.itemsright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.itemsrightmobi {
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-top: 5px;
}

.userinfodm {
  z-index: 0;
  background-color: rgb(255, 255, 255, 0);
  border: 1px solid rgb(255, 255, 255, 0.5);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #040222;
  color: aliceblue;
}

.hoverme:hover {
  background-color: var(--primaryBright);

  box-shadow: 3px 3px 5px 2px rgb(237, 237, 237, 0.6);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2.8s;
}

.userinfodm a {
  text-decoration: none;
}

.userinfoheader img {
  width: 100%;
  min-height: 50%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: top left;
}

.userinfoheadermobile img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.flexit {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.userinfoheadershort img {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  object-position: center -25px;
}

.userinfobodymobile {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 10px;
}

.userinfobodymobile a {
  color: var(--primary);
  transition: 0.5s;
}

.userinfobodymobile a:hover {
  color: #000;
}

.userinfobodymobile h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary);
}

.userinfobodymobile h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.userinfobodymobile p {
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}
.userinfobodymobile .linkelement {
  padding: 0 0 10px 0;
}

.user {
  display: flex;
  margin-top: auto;
  padding: 20px;
  position: absolute;
}

.wrapthings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
}

.grayscale {
  filter: grayscale(1);
}
.makehigher {
  margin-top: -10px;

}

.desctextshort {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  /* number of lines to show */
  line-height: X;
  /* fallback */
  max-height: X * N;
  /* fallback */
}
.likeheart{
   display: flex;
  margin-top: auto;
  right:60px;
  top:200px;
  padding: 20px;
  width:50px;
  position: absolute;
}
.likeheart img{
   width: 50px;
}
.user img {
  border-radius: 50%;
  object-fit: cover;
  width: 160px;
  height: 160px;
  border: 4px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

}

.userinfobody {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px;
}

.userinfobody a {
  color: var(--primary);
  transition: 0.5s;
}

.userinfobody a:hover {
  color: #000;
}

.userinfobody h4 a {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary) !important;
}

.hideiftoobig {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left:10px;
  margin-top:10px;
}

.userinfobody h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.userinfobody p {
  font-size: 14px;
}

small {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.jahre {
  color: #000;
}

.jahredm {
  color: #eee !important;
}

.tag {
  margin: 0 0 10px 0;
   display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  margin-right: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.tagmobile {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.tagmobile span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.hideText{font-size:0.8em;margin:5px 0px;}
.plusmobile {
  text-decoration: none;
  color: #04003b;
  padding: 5px 7px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
  border: 2px solid #04003b;
}

.favicon {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
  filter:grayscale(1)
}
.faviconwhite {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
  filter:brightness(0) invert(1);
}
.faviconnorm {
  color: var(--primary);
  width: 27px;
  cursor: pointer;
  margin: 1%;
 
}
.online{
  inline-size: 1.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;
  position:absolute;
  background:rgb(0, 174, 0);
  border:2px solid white;
  right:18%;
  bottom:20%;
}
.online2mob{
   inline-size: 3.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;
  position:absolute;
  background:rgb(0, 174, 0);
  border:2px solid white;
margin-top:2%;
}
.favicon :hover {
  width: 27px;
  transition: 0.8s;
  filter: grayscale(0);
}

.tagcolor1 {
  background-color: var(--primary);
}

.tagcolor2 {
  background-color: #04003b;
}

.tagcolor3 {
  background-color: #5d0202;
}

.btnout {
  margin: 10px auto;
}

.btn {
  z-index: 0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--primary);
  padding: 5px 5px;
  margin: 0 auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.5s;
}

.btn span {
  position: relative;
  z-index: 1;
}

.btn {
  color: #fff;
  text-decoration: none;
}

.btn:hover {
  background-color: #04003b;
}

.setback {
  z-index: -40;
}

.mobileName {
  padding: 2px 10px;
  color: black;
  text-decoration: none;
  font-size: 1em;
}

.mobileNameLink {
  color: black;
}

.mobileNameLink small {
  color: black;
}

.mobileNameLink a {
  color: black;
  text-decoration: none;
}

.mobileNameLinkdm {
  color: var(--primary) !important;
}

.mobileNameLinkdm small {
  color: #fff !important;
}

.mobileNameLinkdm a {
  color: var(--primary) !important;
  text-decoration: none;
}

.agemobi {
  position: relative;
  top: -10px;
}
.agemobidm {
  position: relative;
  top: -10px;
  color: white !important;
}

.plus {
  display: inline;
  text-decoration: none;
  color: #04003b;
  padding: 0px 12px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 15px;
  width: 30%;
  cursor: pointer;
}

.plus:hover {
  background-color: #2e2e40;
  color: #fff !important;
}

.heart {
  z-index: 100;
  position: absolute;
  animation: heartfade 2s linear;
  opacity: 0;
}

.heart:before,
.heart:after {
  margin-top: -30px;
  content: "";
  z-index: 100;
  background-color: #ff7100;
  position: absolute;
  height: 22px;
  width: 35px;
  border-radius: 15px 0px 0px 15px;
}

.heart:before {
  transform: rotate(45deg);
}

.heart:after {
  left: 10.5px;
  transform: rotate(135deg);
}

@keyframes heartfade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(-30px);
  }

  70% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 0;
  }
}

.thankyoutext {
  color: black;
}

.thankyoudiv {
  width: 50vw;
  height: 100%;
  padding: 5%;
}

.thankyouimage {
  border-radius: 50%;
  width: 8vw;
  height: 8vw;
  border: 5px solid var(--primary);
  margin-right: 5%;
}

.thankyouheader {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.thankyouimagetext {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.linkelement {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.linkelement2 {
  font-weight: bold;
  overflow: hidden;
  display: block;
  width: 100%;
}
.smaller{

  font-size:0.7em!important;
}

.dropdownelementwrapper {
  float: right;
  width:100%;
  position:relative;
  z-index:600;
}

.dropdownmenu {
  float: right;
  font-size: 0.9rem;
  padding: 3px 15px 3px 3px;
   z-index:600;
 
}

.dropdownmenu div {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgb(20, 20, 20, 0.2);
   min-width:230px;
   padding:5px;
  
}

.dropdown {
  width:50%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 4px !important;
  margin: 1px;
  box-shadow: none !important;
}

.dropdown:hover {
  background-color: rgb(200, 50, 50, 0.3);
}

.dropdown img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 2px;
}

.infotext {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (max-width: 1068px) {
.favicon {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
  filter:grayscale(1)
}
.faviconnorm {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
 
}
.faviconwhite {
  color: var(--primary);
  width: 22px;
  cursor: pointer;
  margin: 1%;
  filter:brightness(0) invert(1);
}
.favicon :hover {
  width: 22px;
  transition: 0.8s;
  filter: grayscale(0);
}

  .fullwidth {
  width: 103%;
  margin: -5px -10px;
  --bs-gutter-x: 0px;
}
  .thankyoudiv {
    width: 75vw;
  }

  .thankyouimage {
    width: 25vw;
    height: 25vw;
  }
}

.linkelements {
  width: calc(100% + 10px);
  display: inline-flex;
  flex-flow: row;
 gap:10px;
  margin: 0px;
  justify-content: space-around;
  --bs-gutter-x: 0px;
  margin-bottom: 9px;
}
.linkelements > * {
  margin: 0px 0 0 0px;
}

.linkelement2 {
  background-color: #1a184b;
  color: white;
  padding: 5px;
  line-clamp: 2;
  border-radius: 10px;
  text-align: center;

  height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkelement2 a {
  color: white !important;
  font-size: 0.9em;
}
.firstName {
  font-weight: normal !important;
  font-size: 0.8em;
}
.firstNameright {
  font-weight: normal !important;
  font-size: 0.8em;
  margin-left:15px;
}
.secondName {
  font-weight: bold !important;
  font-size: 0.8em;
}
.firstNamebig {
  font-weight: normal !important;
  font-size: 1em;
}
.secondNamebig {
  font-weight: bold !important;
  font-size: 1em;
}
.mysideimage{
  width: 100%;
}

.distanceme{
  margin: 10px 0px;
  scroll-snap-align: end;
}
.isCurOnline{color:rgb(0, 174, 0);
font-size: 0.6em;}
.isCurOnlineMob{color:rgb(0, 174, 0);
font-size: 0.8em;}
.isCurOnlineMobBig{color:rgb(0, 174, 0);
font-size: 0.6em;}

.online2{
  inline-size: 3.5vmin;
  aspect-ratio: 1;
  border-radius: 50%;

  background:rgb(0, 174, 0);
  border:2px solid white;
 
}
